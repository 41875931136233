import {
  LS
} from "@/const"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ActionText from "@/components/action-text/action-text.vue"

import {
  getCard,
} from '@/store/api'

export default {
  name: "Xpay",
  components: {
    GetterWrapper,
    ViewDetails,
    ViewHeader,
    ActionText
  },
  props: {},
  computed: {

  },
  data () {
    return {
      apiLoad: [{
        api: getCard,
        data: {
          id: this.$route.params.id
        }
      },
      ],
      gif: '../../../assets/contactless.gif',
      items: {
        0: 'android',
        1: 'ios'
      },
      activeItem: '',
      card: {},
      currentHelp: -1,
      OS: this.getMobileOperatingSystem().toLowerCase()
    }
  },
  created: function () {
    this.activeItem = this.items ? (this.OS == 'unknown' ? '0' : (this.OS === 'android' ? '0' : '1')) : ''
    console.log(this.OS)
  },

  mounted: function () {
  },
  beforeUnmount: function () {},
  methods: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
      {
        return 'iOS';

      }
      else if( userAgent.match( /Android/i ) )
      {

        return 'Android';
      }
      
      return 'unknown';
      
    },

    onData (data) {
      this.card = data[0]
    },
    setActive (index) {
      this.activeItem = index
      this.currentHelp = -1
    },

    openXpay () {
      let target = this.OS == 'android' ? 'https://www.android.com/payapp/' : 'shoebox://';
      window.open(target, '_blank')
    },
    openHelp (index) {
      this.currentHelp = (this.currentHelp === index) ? -1 : index
    },
  }
}
