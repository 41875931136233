import router from "@/router"
import { logout } from '@/store/api'

import ViewHeader from "@/components/view-header/view-header.vue"

export default {
  name: 'Logout',
  components: { ViewHeader },
  props: {},
  computed: {},
  data () {
    return {}
  },
  created: function () {
    logout().finally(() => router.push({ name: 'login' }))
  },
  mounted: function () { /* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {}
}
