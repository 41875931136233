export default {
  name: 'Offline',
  components: { },
  props: {},
  computed: {},
  data () {
    return {

    }
  },
  created: function () { /* possible init API call */ },
  mounted: function () { /* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {
    reload: (e) => {
      e.stopPropagation()
      e.preventDefault()
      window.location.reload()
    }
  }
}
