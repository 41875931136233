<template>
  <FormWrapper
    forceId="smssecure"
    :api="api.bind(this, this.form)"
    :btnText="`${$t('form.validate')}`"
    :customErrors="errors"
    :validation="v$.secureForm"
    @submitted="onSubmitted"
    @error="onError"
  >
    <p
      class="columns small-12 text-center"
      v-html="$t('forms.smsSecure.title') + ' ' + this.form.mobile"
    ></p>

    <div class="row align-center">
      <InputWrapper class="columns small-8 text-center" :validation-path="'secureForm.codeSMS'">
        <CodeSmsInput
          :checkInvalid="invalidCodeSMS"
          v-model:codeSms.lazy="v$.secureForm.codeSMS.$model"
          @submitCode="submitCode"
        ></CodeSmsInput>
      </InputWrapper>
    </div>

    <div class="row align-center">
      <a
        class="button clear"
        :disabled="countDown!==0"
        @click="onSendSms"
      >{{ $t("forms.smsSecure.timer") }} {{ formatedCountdown }}</a>
    </div>
  </FormWrapper>
</template>

<script src="./form-sms-secure.js"></script>
