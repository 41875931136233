<template>
  <FormWrapper
    forceId="passwordSecure"
    :api="api.bind(this, this.form)"
    :btnText="`${$t('form.validate')}`"
    :customErrors="errors"
    :validation="v$.secureForm"
    @submitted="onSubmitted"
    @error="onError"
    :validation-path="'secureForm.password'"
  >
    <InputWrapper :label="`${$t('form.password')}`">
      <InputPassword
        name="password"
        type="password"
        v-model:password.trim.lazy="v$.secureForm.password.$model"
        :disableStrength="true"
        required
      ></InputPassword>
    </InputWrapper>
  </FormWrapper>
</template>

<script src="./form-password-secure.js"></script>
