<template>
  <div class="view-blocs">
    <div v-for="(bloc, index) in blocs" :key="section + '-bloc-' + index">
      <h2 class="title">{{ bloc.title }}</h2>
      <p v-html="bloc.text"></p>
    </div>
  </div>
</template>

<script src="./view-blocs.js"></script>
<style lang="scss" src="./view-blocs.scss"></style>
