import { loginTrustToken, setStoreToken, getUserApi, clearStoreToken } from '@/store/api'
import store from '@/store'
import router from '@/router'
import { loadLanguageAsync } from '@/helpers/i18n'
import Spinner from '@/components/spinner/spinner.vue'
export default {
  name: 'trust',
  components: {
    Spinner
  },
  props: {},
  data () {
    return {
      token: this.$route.params.token
    }
  },
  computed: {

  },
  created: async function () {
    try {
      await clearStoreToken();
      const dataToLogin = await loginTrustToken(this.token)
      console.log('AFTER GET LOGIN TRUST')
      await this.onSubmitted(dataToLogin.data)
    } catch (err) {
      console.error('TRUST PAGE error  , go to login', err)
      router.push({
        name: 'login'
      })
    }

  },

  methods: {
    async onSubmitted (data) {
      // Store the received token
      await setStoreToken(data.access_token, data.refresh_token)

      // Get user infos
      try {
        const res = await getUserApi(null, {params: {trust: true}})
        store.commit('user', res.data)
        console.debug('user logged', res.data)
        await loadLanguageAsync(res.data.locale)

      } catch (error) {
        console.error('[ERROR]', error)
      } finally {
        console.log('TRUST PAGE finally push home')
        router.push({
          name: 'home',
          params: {trust: true}
        })
      }
    },
  }
}
