<template>
  <div>
    <Popup v-if="showConditions" hasClose @close="toggleConditions()">
      <h1>{{ $t('cgu.title') }}</h1>
      <ViewBlocs :blocs="$tm('cgu.blocs')" section="cgu"/>
    </Popup>

    <Popup v-if="showPolitics" hasClose @close="togglePolitics()">
      <h1>{{ $t('politics.title') }}</h1>
      <ViewBlocs :blocs="$tm('politics.blocs')" section="politics"/>
    </Popup>

    <FormWrapper
      :api="putFormSignup.bind(this, this.form)"
      :btn-text="`${$t('signup.submitTxt')}`"
      :validation="v$.form"
      @submitted="onSubmitted"
      @error="onError"
    >
      <fieldset>
        <legend>{{ $t('form.civility') }}</legend>
        <div class="row align-center">
          <InputWrapper isRequired class="columns small-12 medium-7 input-checkbox" :validation-path="'form.gender'">
            <input
              id="genderFemale"
              name="sex"
              type="radio"
              value="female"
              v-model.lazy="v$.form.gender.$model"
            />
            <label for="genderFemale">{{ `${$t('form.genderFemale')}` }}</label>
            <input
              id="genderMale"
              name="sex"
              type="radio"
              value="male"
              v-model.lazy="v$.form.gender.$model"
            />
            <label for="genderMale">{{ `${$t('form.genderMale')}` }}</label>
          </InputWrapper>

          <InputWrapper
            :label="`${$t('form.firstname')}`"
            class="columns small-12 medium-7"
            :validation-path="'form.firstname'"
            :isInvalid="v$.form.firstname.$invalid"
            isRequired
          >
            <input
              name="firstname"
              type="text"
              :placeholder="$t('form.firstname')"
              v-model.trim.lazy="v$.form.firstname.$model"
              required
              @input="forceUpper($event, form, 'firstname')"
              @blur="v$.form.firstname.$touch"
            />
          </InputWrapper>

          <InputWrapper
            :label="`${$t('form.lastname')}`"
            class="columns small-12 medium-7"
            :isInvalid="v$.form.lastname.$invalid"
            :validation-path="'form.lastname'">
            <input
              name="lastname"
              type="text"
              :placeholder="$t('form.lastname')"
              v-model.trim.lazy="v$.form.lastname.$model"
              required
              @input="forceUpper($event, form, 'lastname')"
            />
          </InputWrapper>


          <InputWrapper
            :label="`${$t('form.birthdate')}`"
            class="columns small-12 medium-7"
            isRequired
            :minAgeRequiredMessage="`${$t('form.error.minAgeRequired.signup')}`"
            :dateNoExistMessage="`${$t('form.error.dateNoExist')}`"
            :validation-path="'form.birthdate'"
            :isInvalid="v$.form.birthdate.$invalid"
          >
            <InputBirthdate name="birthdate" v-model:birthdate.lazy="v$.form.birthdate.$model" required/>
          </InputWrapper>

          <InputWrapper
            :label="`${$t('form.country')}`"
            class="columns small-12 medium-7"
            :validation-path="'form.country'"
            :isInvalid="v$.form.country.$invalid"
          >
            <select name="country" v-model="v$.form.country.$model" required>
              <option
                v-for="country in countries"
                :key="country.code"
                :value="country.code"
              >{{ uppercase(country.label) }}
              </option>
            </select>
          </InputWrapper>
        </div>
      </fieldset>

      <fieldset>
        <legend>{{ $t('signup.contactDetails') }}</legend>
        <div class="row align-center">
          <InputWrapper
            isRequired
            class="columns small-12 medium-7"
            :label="`${$t('form.mobile') + ' ' + $t('form.mobileIndications.mobileSms')}`"
            :validation-path="'form.mobile'"
            :isInvalid="v$.form.mobile.$invalid"
          >
            <MobileInput
              name="tel"
              :mobileInit="v$.form.mobile.$model"
              required
              @blur="v$.form.mobile.$touch"
              @updateMobile="updateMobile"
            />
          </InputWrapper>

          <InputWrapper
            class="columns small-12 medium-7"
            :label="`${$t('form.email')}` + ' ' + `${$t('signup.emailIndication')}`"
            :validation-path="'form.email'"
            :isInvalid="v$.form.email.$invalid"
          >
            <input
              name="email"
              type="email"
              :placeholder="$t('form.email')"
              v-model="v$.form.email.$model"
              disabled
              required
            />
          </InputWrapper>
        </div>
      </fieldset>

      <fieldset>
        <legend>{{ $t('form.password') }}</legend>
        <div class="row align-center">
          <InputWrapper
            class="columns small-12 medium-7"
            :label="`${$t('form.password')}` + ' ' + `${$t('form.passwordRecommandation')}`"
            :validation-path="'form.password'"
            :isInvalid="v$.form.password.$invalid"
            isRequired
          >
            <InputPassword
              name="password"
              type="password"
              v-model:password.trim.lazy="v$.form.password.$model"
              :disableStrength="true"
              @updatePassword="updatePassword"
              required
            ></InputPassword>
          </InputWrapper>

          <InputWrapper class="columns small-12 medium-7"
                        :isInvalid="v$.form.passwordConfirm.$invalid"
                        :label="`${$t('form.passwordRepeat')}`"
                        :validation-path="'form.passwordConfirm'">
            <InputPassword
              name="passwordConfirm"
              type="password"
              v-model:password.trim.lazy="v$.form.passwordConfirm.$model"
              :disableStrength="true"
              :strength="2"
              @updatePassword="updatePasswordConfirm"
              required
            ></InputPassword>
          </InputWrapper>
        </div>
      </fieldset>

      <fieldset>
        <legend>&nbsp;</legend>
        <div class="row align-center">
          <label class="columns small-12 text-justify legal-link legal-margin">
            {{ $t('signup.formIndications') + ' ' + $t('form.politics.begin') }}
            <span
              @click="togglePolitics()"
            >{{ $t('form.politics.click') }}</span>
            {{ $t('form.politics.end') }}
          </label>
          <InputWrapper class="columns small-12 input-checkbox" :validation-path="'form.newsletter'">
            <input
              id="signup-suggests-input"
              name="newsletter"
              type="checkbox"
              v-model="v$.form.newsletter.$model"
            />
            <label for="signup-suggests-input">{{ $t('signup.suggestsMessage') }}</label>
          </InputWrapper>
          <InputWrapper class="columns small-12 input-checkbox"
                        :validation-path="'form.terms'"
                        :isInvalid="v$.form.terms.$invalid"
                        isRequired>
            <input
              id="signup-terms-input"
              name="terms"
              type="checkbox"
              v-model="v$.form.terms.$model"
              required
            />
            <label for="signup-terms-input" class="legal-link">
              {{ $t('signup.conditionsMessage.begin') }}
              <span
                @click="toggleConditions()"
              >{{ $t('signup.conditionsMessage.click') }}</span>
              {{ $t('signup.conditionsMessage.end') }}
              <span class="required">*</span>
            </label>
          </InputWrapper>
        </div>
      </fieldset>
    </FormWrapper>
  </div>
</template>

<script src="./form-user.js"></script>
