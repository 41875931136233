import ActionText from "@/components/action-text/action-text.vue"
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import MobileInput from '@/components/mobile-input/mobile-input.vue'
import Popup from "@/components/popup/popup.vue"
import {validateMobileNumber} from '@/helpers/vuelidate'
import {useVuelidate} from '@vuelidate/core'
import {saveUserMobile, validCodeMobile} from '@/store/api'

import FormPasswordSecure from './form-password-secure.vue'
import FormSmsSecure from './form-sms-secure.vue'

export default {
  name: 'FormMobile',
  components: {ActionText, FormPasswordSecure, FormSmsSecure, FormWrapper, InputWrapper, MobileInput, Popup},
  props: {},
  data() {
    return {
      v$: useVuelidate(),
      saveUserMobile,
      validCodeMobile,
      form: {
        mobile: this.$store.state.user?.mobile || '+33000000000'
      },
      isModified: false,
      showForm: false,
      showPopup: false,
      showPopupValid: false,
      showPopupOk: false,
      mobileKey: 0
    }
  },
  validations: {
    form: {
      mobile: {
        validateMobileNumber: validateMobileNumber(),
        required: true,
        $lazy: true
      },
    },
  },
  computed: {},
  methods: {
    closeForm() {
      this.showForm = false
      this.isModified = false
      this.form = {
        mobile: this.$store.state.user?.mobile,
      }
    },
    toggleForm() {
      this.showForm = !this.showForm
      if (!this.showForm) {
        this.isModified = false
        console.log('form mobile ', this.isModified)
        this.form = {
          mobile: this.$store.state.user?.mobile,
        }
        this.mobileKey++;
      }
    },
    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
      this.closeForm()
    },
    openPopupValid() {
      this.showPopup = false
      this.showPopupValid = true
    },
    closePopupValid() {
      this.showPopupValid = false
      this.closeForm()
    },
    openPopupOk() {
      this.showPopupValid = false
      this.showPopupOk = true
    },
    closePopupOk() {
      this.showPopupOk = false
      this.closeForm()
    },
    update() {
      console.log('update')
      this.isModified = true
    },
    updateMobile(data) {
      if (data.mobile !== this.form.mobile) this.isModified = true
      this.form.mobile = data.mobile
    },
    async onSubmitted(data) {
      this.form = Object.assign({}, data)
      this.openPopupValid()
    },
    async onSubmittedCodeSms() {
      this.openPopupOk()
    },
    onError(error) {
      console.error('[ERROR]', error)
    },
  }
}
