import { required } from '@vuelidate/validators'

import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputPassword from '@/components/input-password/input-password.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import { useVuelidate } from '@vuelidate/core'
export default {
  name: 'FormPasswordSecure',
  components: { FormWrapper, InputPassword, InputWrapper },
  props: {
    api: { type: Function, default: null },
    tempForm: { type: Object, default: {} },
  },
  data () {
    return {
      v$: useVuelidate(),
      errors: [
        {
          response: {
            status: 409,
            data: {
              message: this.$t('errors.emailExist'),
            }
          }
        },
      ],
      secureForm: {
        password: '',
      },
    }
  },
  validations: {
    secureForm: {
      password: {
        required,
      },
    },
  },
  emits: ['submit'],
  computed: {
    form () {
      return { ...this.tempForm, ...this.secureForm }
    }
  },
  methods: {
    async onSubmitted (data) {
      this.$emit('submit', this.form)
    },
    onError (error) {
      if (error.response.status && (error.response.status === 400 || error.response.status === 406)) error = "errors.passwordIncorrect"
      console.error('[ERROR]', error)
    },
  }
}
