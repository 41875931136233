import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import ViewHeader from "@/components/view-header/view-header.vue"

export default {
  name: 'Mentions',
  components: { ViewBlocs, ViewHeader },
  props: {},
  computed: {},
  data () {
    return {}
  },
  created: function () { },
  mounted: function () { console.log('mentions with ', this.$i18n, this.$i18n.messages[this.$i18n.locale].mentions.blocs, this.$tm('mentions.blocs'))/* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {}
}
