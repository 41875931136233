import Vue from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { LS } from "@/const"
import CodeSmsInput from '@/components/code-sms-input/code-sms-input.vue'
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import { formatSeconds } from "@/helpers/date.js"

import { forgotPasswordEmailSms, forgotPasswordSmsValidate } from '@/store/api'

export default {
  name: 'FormSmsSecure',
  components: {
    CodeSmsInput,
    FormWrapper,
    InputWrapper
  },
  props: {
    email: {
      type: String,
      default: '',
      required: true
    },
  },
  data () {
    return {
      v$: useVuelidate(),
      errors: [
        {
          response: {
            status: 406,
            data: {
              message: this.$t('errors.codeSmsInvalid'),
            }
          }
        },
      ],
      forgotPasswordSmsValidate,
      countDown: LS.RESEND_SMS_SECS,
      form: {
        email: this.email,
        codeSms: null
      },
      invalidCodeSMS: false,
      timer: "",
    }
  },
  emits: ['submitted', 'notreceived'],
  validations: {
    form: {
      codeSms: { required }
    },
  },
  computed: {
    formatedCountdown () {
      return formatSeconds(this.countDown)
    },
  },
  mounted: function () {
    this.countDownTimer()
  },
  methods: {
    countDownTimer () {
      if (this.countDown > 0) {
        this.timer = setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    submitCode () {
      Vue.nextTick(function () {
        const btnForm = document.getElementById('save-forgotpasswordsmsvalidate-btn-submit')
        btnForm.click(function (e) {
          // Prevent form submission which refreshes page
          e.preventDefault()
          return false
        })
      })
    },
    async onSendSms () {
      if (this.countDown === 0) {
        try {
          await forgotPasswordEmailSms(this.form)
        } catch (error) {
          this.onError(error)
        } finally {
          clearTimeout(this.timer)
          this.countDown = LS.RESEND_SMS_SECS
          this.countDownTimer()
        }
      }
    },
    onSubmitted () {
      this.$emit('submitted')
    },
    onError (error) {
      console.error('[ERROR]', error)
      this.invalidCodeSMS = true
    },
    onNotReceived () {
      this.$emit('notreceived')
    }
  }
}
