

import { createApp } from 'vue';
import store from '@/store'
import { i18n } from '@/helpers/i18n'

import '@/styles/_app.scss'
import '@/assets/icons/sofont.font.js'
import logger from '@/logger'

import App from './App.vue'

// workbox pwa
import wb from './helpers/sw.js'

// plugin TranslateWithVariable
//import CWV from './plugins/TranslateWithVariable'





// Disable console.*
// Can be reactivated with ?debug=1 as GET parameters
const hostname = window.location.hostname
const searchParams = new URLSearchParams(window.location.search)
if (process.env.NODE_ENV === 'production' &&
  hostname.indexOf('dev.') !== 0 &&
  hostname.indexOf('carte2.') !== 0 &&
  !searchParams.has('debug')) {
  logger.disableLogger()
}

// Setup the application to start
/*const app = new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App)
})*/
//import { configureCompat } from 'vue'
import router from './router';
import './registerServiceWorker'

// default everything to Vue 3 behavior, and only enable compat
// for certain features
/*configureCompat({
  MODE: 3
})*/
// Start the app
const app = createApp(App)
//app.use(CWV)
app.use(i18n)
app.use(store)
app.use(router)


app.config.globalProperties.$workbox = wb

/*configureCompat({
  // default everything to Vue 2 behavior
  MODE: 3,
  //COMPONENT_V_MODEL: false
});*/

app.mount('#app')

export default app;
