<template>
  <header v-if="(forceState === '' && logged) || (forceState === 'LOGGED')">
    <div class="top-bar is-logged" :class="{'is-forced': (forceState === 'LOGGED'), 'long' : (routes.length > 4)}">
      <div class="top-bar-left">
        <router-link to="/">
          <div class="brand-image"></div>
        </router-link>
      </div>
      <div class="top-bar-right">
        <ul class="menu">
          <li v-for="(route, i) in routes" :key="i">
            <router-link
              :class="[{'nav-active': isActiveRoute(route.text)}, 'menu-item']"
              :to="destination(route.route)" v-if="route.route.startsWith('/')"
            >
              <i :class="'sofont sofont-' +route.icon + ' icon-menu'"></i>
              <span class="menu-name">{{
                 capitalize(route.route == '/' ? $tc('titles.' + route.text, (cards.length == 0 ? 1 : cards.length)) : $t('titles.' + route.text))
                }}</span>
            </router-link>
            <a v-if="route.route.startsWith('http')" :href="route.route+ '?jwt=' + token"
               class="menu-item">
              <i :class="'sofont sofont-' +route.icon + ' icon-menu'"> </i>
              <span class="menu-name">{{
                  capitalize(route.text)
                }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <header v-else>
    <div class="top-bar not-logged">
      <router-link to="/">
        <div class="brand-image"></div>
      </router-link>
      <span>{{ $t('login.header') }}</span>
    </div>
  </header>
</template>

<script src="./app-header.js"></script>
