<template>
  <div>
    <Popup v-if="showPopup" hasClose @close="closePopup()">
      <p class="text-center">
        <b>{{ $t('forms.mobile.confirmPassword') }}</b>
      </p>
      <FormPasswordSecure :api="saveUserMobile" :tempForm="form" @submit="onSubmitted" />
    </Popup>

    <Popup v-if="showPopupValid" hasClose @close="closePopupValid()">
      <p class="text-center">
        <b>{{ $t('forms.mobile.validWaiting') }}</b>
      </p>
      <FormSmsSecure
        :api="validCodeMobile"
        :apiResend="saveUserMobile"
        :tempForm="form"
        @submit="onSubmittedCodeSms"
      />
    </Popup>

    <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
      <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
      <p class="popup-valid-text text-center">{{ $t('forms.mobile.sendOk') }}</p>
    </Popup>

    <FormWrapper
      :api="null"
      :btnClass="showForm ? '' : 'is-hidden'"
      :btnDisabled="!isModified || invalidMobile"
      :btnText="`${$t('form.save')}`"
      :invalid="invalidMobile"
      :validation="v$.form"
      @cancel="this.toggleForm"
      @submitted="openPopup()"
      @error="onError"
    >
      <fieldset>
        <legend>
          <div>{{ $t('forms.mobile.title') }}</div>
          <ActionText
            :class="showForm ? 'btn-cancel' : 'btn-edit'"
            isOnLegend
            :icon="showForm ? 'sofont-cross-close' : 'sofont-edit'"
            :text="showForm ? $t('form.cancel') : $t('form.edit')"
            @action="toggleForm()"
          />
        </legend>
        <div class="row align-center">
          <InputWrapper
            isRequired
            class="columns small-12 medium-7"
            :label="`${$t('form.mobile')}`"
            :validation-path="'form.mobile'"
            :isInvalid="v$.form.mobile.$invalid"
          >
            <MobileInput
              :key="mobileKey"
              name="tel"
              :isDisabled="!showForm"
              :mobileInit="v$.form.mobile.$model"
              required
              @updateMobile="updateMobile"
            />
          </InputWrapper>
        </div>
      </fieldset>
    </FormWrapper>
  </div>
</template>

<script src="./form-mobile.js"></script>
