<template>
  <!-- Request cards data -->
  <GetterWrapper id="buy" :api-load="apiLoad" @data="onData">
    <div class="buy row align-center">

      <ViewHeader
        :hasBack="true"
        urlBack="card-item"
        :title="text"
        :icon="icon"
        :backgroundIcon="background"
      />
      <div class="columns small-12 medium-10 large-8">
        <ViewDetails
          :content="$t('card.buy.subintro')"
        />


        <!-- Tabs -->
        <div class="row align-center card-tabs">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="columns shop-tab"
            :class="{ active: activeItem === index }"
            @click.prevent="setActive(index)"
          >
            {{ $t(item) }}
          </div>
        </div>

        <p v-if="activeItem === '0' && $route.name =='card-item-store'" class="text-center purchase-alert">
          <strong>{{ $t("card.buy.purchaseAlert") }}</strong></p>

        <div v-if="activeItem === '0' && $route.name =='card-item-donate'"
             class="row text-center align-center margin-1">
          <p v-html="$t('card.buy.donate.intro')"></p>
        </div>

        <!-- Shop items -->

<!--          <div @click="test" id="test" @loadstart="test"/>-->

<!--          <div-->
<!--            v-for="(shop, index) in shops"-->
<!--            :key="index"-->
<!--            class="column shop-item"-->
<!--          >-->
<!--            <div class="shop-image">-->
<!--              <a :href="shop.url" target="_blank">-->
<!--                <div-->
<!--                  class="shop-icon"-->
<!--                  :style="{ 'background-image': 'url(' + shop.logoUrl + ')' }"-->
<!--                ></div>-->
<!--                <img :src="shop.bannerUrl"/>-->

<!--              </a>-->
<!--            </div>-->
<!--            <div class="shop-links">-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
        <div style="overflow: hidden"
          v-if="activeItem === '0'"
          ref="charitips"
        />
        <div  v-if="activeItem === '1'">
          <div v-if="type === 'shop' && activeItem === '1'">
            <p v-if="onlineHelps.length === 1">{{ onlineHelps[0] }}</p>
            <div
              class="stepper"
              v-else
              v-for="(help, index) in onlineHelps"
              :key="index"
            >
              <div class="stepper-circle">{{ index + 1 }}</div>
              <div class="stepper-content">{{ help }}</div>
            </div>
            <div class="row align-center">
              <div class="columns small-12 medium-7 stepper final">
                <div class="stepper-circle">
                  <i class="sofont sofont-check"/>
                </div>
                <b>
                  <div class="stepper-content" v-html="onlineHelpsFinal"></div>
                </b>
                <div class="stepper-box" v-if="card.amount > 0 && card.program && card.program.stripeKey">
                  <div>
                    <b>{{ $t("card.buy.finalBox.title") }}</b>
                  </div>
                  <div>{{ $t("card.buy.finalBox.content", {value: $n(card.program.maxTopup, 'currency')}) }}</div>
                  <router-link
                    class="button"
                    :to="{ name: 'card-item-balance', params: { id: card.id } }"
                  >{{ $t("card.buy.btnSolde") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p v-if="helps.length === 1">{{ helps[0] }}</p>
            <div class="stepper" v-else v-for="(help, index) in helps" :key="index">
              <div class="stepper-circle">{{ index + 1 }}</div>
              <div class="stepper-content">{{ help }}</div>
            </div>
            <div class="row align-center">
              <div class="columns small-12 medium-7 stepper final">
                <div class="stepper-circle">
                  <i class="sofont sofont-check"/>
                </div>
                <b>
                  <div class="stepper-content" v-html="helpsFinal"></div>
                </b>
                <div class="stepper-box" v-if="card.amount > 0 && card.program && card.program.stripeKey">
                  <div>
                    <b>{{ $t("card.buy.finalBox.title") }}</b>
                  </div>
                  <div>{{ $t("card.buy.finalBox.content", {value: $n(card.program.maxTopup, 'currency')}) }}</div>
                  <router-link
                    class="button"
                    :to="{ name: 'card-item-balance', params: { id: card.id } }">
                    {{ $t("card.buy.btnSolde") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </GetterWrapper>

</template>

<script src="./buy.js">
</script>
<style lang="scss" src="./buy.scss">
</style>
