import { LS } from '@/const'
import ActionText from "@/components/action-text/action-text.vue"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ItemTransaction from "@/components/item-transaction/item-transaction.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"

import { getCard, getTransactions, getStatementPDF } from '@/store/api'

export default {
  name: 'Statement',
  components: {
    ActionText,
    GetterWrapper,
    ItemTransaction,
    ViewDetails,
    ViewHeader
  },
  props: {},
  computed: {},
  data () {
    return {
      apiLoad: [
        {
          api: getCard,
          data: { id: this.$route.params.id }
        },
        {
          api: getTransactions,
          data: { id: this.$route.params.id, offset: 0, size: LS.CARD_TRANSACTION_SIZE }
        },
      ],
      card: {},
      currentOffset: 0,
      hasMoreTransactions: true,
      indexCurrent: -1,
      indexPassed: -1,
      transactions: { current: [], passed: [] },
    }
  },
  created: function () { },
  mounted: function () { /* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {
    onData (data) {
      // Data first request
      this.card = data[0]
      // Data second request
      if (data[1].length < LS.CARD_TRANSACTION_SIZE) this.hasMoreTransactions = false
      this.transformTransaction(data[1])
    },
    async addTransactions () {
      try {
        const res = await getTransactions({ id: this.$route.params.id, offset: this.currentOffset + LS.CARD_TRANSACTION_SIZE, size: LS.CARD_TRANSACTION_SIZE })
        if (res.data.length < LS.CARD_TRANSACTION_SIZE) this.hasMoreTransactions = false
        this.transformTransaction(res.data)
        this.currentOffset += LS.CARD_TRANSACTION_SIZE
      } catch (error) {
        console.error('[ERROR]', error)
      } finally {
        this.loadLoading = false
      }
    },
    transformTransaction (data) {
      console.log('transform' , data)
      data.forEach(trans => {
        console.log(trans)
        trans.date = new Date(trans.date)
        if (trans.pending) {
          this.transactions.current.push(trans)
        } else {
          this.transactions.passed.push(trans)
        }
      })
    },
    async downloadStatement () {
      try {
        const res = await getStatementPDF({ id: this.$route.params.id })
        const headers = res.headers
        const url = window.URL.createObjectURL(new Blob([res.data], { type: headers['content-type'] }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.$t('card.statement.fileName') + '_' + this.card.program?.name + '.pdf')
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.error('[ERROR]', error)
      }
    },
    openCurrent (index) {
      this.indexCurrent = (this.indexCurrent === index) ? -1 : index
    },
    openPassed (index) {
      this.indexPassed = (this.indexPassed === index) ? -1 : index
    },
  }
}
