const logger = function () {
  const pub = {}
  const levels = ["log", "debug", "warn", "info"]
  const oldConsoles = []

  pub.enableLogger = function enableLogger () {
    levels.forEach((level) => {
      if (level in oldConsoles) {
        window.console[level] = oldConsoles[level]
      }
    })
  }

  pub.disableLogger = function disableLogger () {
    levels.forEach((level) => {
      oldConsoles[level] = window.console[level]
      window.console[level] = function () {}
    })
  }

  return pub
}

export default logger()
