
import { required } from '@vuelidate/validators'
import Toggle from '@vueform/toggle'
import ActionText from "@/components/action-text/action-text.vue"
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import LocalesInput from "@/components/locales-input/locales-input.vue"
import Popup from "@/components/popup/popup.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import { loadLanguageAsync } from '@/helpers/i18n'
import parameters from '@/helpers/parameters'

import { saveUserPreferences } from '@/store/api'
import { useVuelidate } from '@vuelidate/core'

import '@vueform/toggle/themes/default.css';
export default {
  name: 'Preferences',
  components: {
    ActionText,
    FormWrapper,
    InputWrapper,
    LocalesInput,
    Popup,
    Toggle,
    ViewDetails,
    ViewHeader
  },
  props: {},
  computed: {},
  data () {
    return {
      v$: useVuelidate(),
      saveUserPreferences,
      form: {
        newsletter: this.$store.state.user?.newsletter,
        zoneId: this.$store.state.user?.zoneId,
        locale: this.$store.state.user?.locale,
      },
      isModified: false,
      showPopupOk: false,
      zonesId: parameters.zonesID
    }
  },
  validations: {
    form: {
      newsletter: { required },
      zoneId: { required },
      locale: { required },
    },
  },
  created: function () { },
  mounted: function () { },
  beforeUnmount: function () { },
  methods: {
    resetForm () {
      this.isModified = false
      this.form = {
        newsletter: this.$store.state.user?.newsletter,
        zoneId: this.$store.state.user?.zoneId,
        locale: this.$store.state.user?.locale,
      }
    },
    openPopupOk () {
      this.showPopupOk = true
    },
    closePopupOk () {
      this.showPopupOk = false
    },
    update () {
      this.isModified = true
    },
    updateLocale (data) {
      this.form.locale = data
      this.update()
    },
    async onSubmitted () {
      this.resetForm()
      this.openPopupOk()
      // Set the new language for the user
      await loadLanguageAsync(this.$store.state.user?.locale)
    },
    onError (error) {
      console.error('[ERROR]', error)
    },
  }
}
