<template>
  <div class="row expanded footer" :class="{ 'is-logged': !disconnected }">
      <div class="row expanded footer-desktop">
         <div>{{ $t('app.name') }} {{ currentYear }}</div>
          <div class="footer-separator">|</div>
          <div>
            <router-link :to="{ name: 'mentions' }">{{ $t('titles.mentions') }}</router-link>
          </div>
          <div v-if="disconnected" class="footer-separator">|</div>
          <div v-if="disconnected">
            <router-link :to="{ name: 'contact' }">{{ $t('titles.contact') }}</router-link>
          </div>
      </div>
      <div class="row footer-mobile">
        <div class="row">
          <router-link :to="{ name: 'mentions' }">{{ $t('titles.mentions') }}</router-link>
          <div v-if="disconnected" class="footer-separator">|</div>
          <div v-if="disconnected">
            <router-link :to="{ name: 'contact' }">{{ $t('titles.contact') }}</router-link>
          </div>
        </div>
        <div class="row">
           <div>{{ $t('app.name') }} {{ currentYear }}</div>
        </div>
      </div>

  </div>
</template>

<script src="./app-footer.js"></script>
<style scoped lang="scss" src="./app-footer.scss"></style>
