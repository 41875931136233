export default {
  name: 'ListErrors',
  components: {},
  props: { errors: [Error, Object] },
  data () {
    return {
      err: {
        message: this.$t('form.error.unknown'),
        details: []
      }
    }
  },
  watch: {
    errors: function () {
      this.err = this.sanitizedErrors(this.errors)
    }
  },
  mounted: function () {
    this.err = this.sanitizedErrors(this.errors)
    console.log('LIST ERRORS MOUNTED ', this.err, this.errors)
  },
  methods: {
    sanitizedErrors (_errors) {
      console.log('_errors', _errors)
      // Prepare error message
      const errors = { message: this.$t('form.error.unknown'), details: [] }

      // API is not reachable
      if (_errors && _errors.message) {
        errors.message = _errors.message
      }

      // Manage API errors
      if (_errors && _errors.response && _errors.response.data) {

        // Get the main error message
        if (_errors.response.data.message) {
          errors.message = _errors.response.data.message
        }

        // Add some details if available
        if (_errors.response.data.errors && _errors.response.data.errors.length > 0) {
          for (const err of _errors.response.data.errors) {
            if (err.message !== errors.message) {
              errors.details.push(err)
            }
          }
        }
      }

      // Return sanited errors
      return errors
    }
  }
}
