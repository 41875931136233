<template>
  <div class="help row align-center" :class="{'fl-type' : isFLType ? true : false}">
    <Popup v-if="showPolitics" hasClose @close="togglePolitics()">
      <ViewBlocs :blocs="$tm('politics.blocs')" section="politics"/>
    </Popup>

    <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
      <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
      <p class="popup-valid-text text-center">{{ $t('help.moreHelp.submitOk') }}</p>
    </Popup>

    <ViewHeader :hasBack="logged ? false : true" :title="$t('help.title')" :subtitle="$t('help.subtitle')"/>
    <div class="columns small-12 medium-10 large-8">
      <div v-for="(bloc, index) in blocs" :key="'help-bloc-' + index" class="help-bloc">
        <div v-if="bloc.title!==''">
          <ActionText
            class="help-subject"
            :icon="'sofont-arrow-down' + (currentHelp === index ? ' is-up' : '')"
            :isTitle="true"
            :text="bloc.title"
            v-on:action="openHelp(index)"
          />
          <p
            v-if="(!isFLType && currentHelp === index) || (isFLType && currentHelp === index && (currentHelp !== 0 && currentHelp !== 5))"
            v-html="bloc.text"></p>
          <p v-if="currentHelp === index && isFLType && index == 0">Votre carte cadeau est exclusivement valable dans
            tous les magasins du centre commercial Buld'air Shopping Centre de Vedène.</p>
          <p v-if="currentHelp === index && isFLType && index == 5">Oui, vous pouvez compléter le solde de votre carte
            cadeau en utilisant une autre moyen de paiement accepté en caisse. Adressez-vous à un conseiller du magasin
            concerné au préalable.</p>
        </div>
      </div>

      <!-- HELP MORE -->
      <div class="help-more">
        <p>{{ $t("help.moreHelp.text") }}</p>
        <ActionText
          :icon="'sofont-arrow-down' + (showMoreHelp ? ' is-up' : '')"
          :text="$t('help.moreHelp.button')"
          v-on:action="toggleMoreHelp()"
        />
        <p v-if="showMoreHelp" v-html="$t('help.moreHelp.content')"></p>

        <!-- HELP FORM -->
        <FormWrapper
          v-if="showMoreHelp"
          :api="sendFormHelp.bind(this, this.form)"
          :btn-text="`${$t('help.moreHelp.submitTxt')}`"
          :validation="v$.form"
          @submitted="onSubmitted"
          @error="onError"
        >
          <fieldset>
            <div class="row align-center">
              <InputWrapper :label="`${$t('form.subject')}`" class="columns small-12 medium-7"
                            :validation-path="'form.subject'">
                <select
                  name="subject"
                  :placeholder="$t('form.subject')"
                  v-model.lazy="v$.form.subject.$model"
                  required
                >
                  <option v-for="(sub, index) in subjects" :key="index" :value="sub">{{ sub }}</option>
                </select>
              </InputWrapper>

              <InputWrapper :label="`${$t('form.message')}`" class="columns small-12 medium-7"
                            :validation-path="'form.message'">
                <textarea
                  name="message"
                  :placeholder="$t('form.message')"
                  v-model="v$.form.message.$model"
                  required
                />
              </InputWrapper>

              <label class="columns small-12 medium-7 text-justify legal-link legal-margin">
                {{ $t('help.moreHelp.formIndications') + ' ' + $t('form.politics.begin') }}
                <span
                  @click="togglePolitics()"
                >{{ $t('form.politics.click') }}</span>
                {{ $t('form.politics.end') }}
              </label>
              <label
                class="columns small-12 medium-7 text-justify legal-link legal-margin"
                v-html="$t('help.moreHelp.formIndicationsMore')"
              ></label>
            </div>
          </fieldset>
        </FormWrapper>
      </div>
    </div>
  </div>
</template>

<script src="./help.js"></script>
