<template>
  <div class="btn-url-action-wrapper" :class="{ disabled: disabled }">
    <router-link
      :to="{ name: infos.route.name, params: infos.route.params }"
      custom
      v-slot="{ navigate }"
    >
      <button
        @click="navigate"
        :disabled="disabled"
        class="btn-url-action row align-middle"
      >
        <!-- URL icon -->
        <RoundIcon
          :background="infos.background"
          :icon="infos.icon"
          :disabled="disabled"
        />

        <!-- Display notifications -->
        <div class="columns">
          <span class="btn-url-action-text" v-html="infos.text"></span>
          <div v-if="infos.notif" class="btn-url-action-notif">
            <span v-html="infos.notif"></span>
            <span class="notif-point"></span>
          </div>
        </div>

        <!-- Display sub content -->
        <div v-if="infos.subText">
          <span v-html="infos.subText"></span>
        </div>
      </button>
    </router-link>
  </div>
</template>

<script src="./btn-url-action.js"></script>
<style lang="scss" src="./btn-url-action.scss"></style>
