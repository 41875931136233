<template>
  <div class="message row align-center">
    <ViewHeader :title="$t('message.title')" />
    <div class="columns small-12 medium-10 large-8">
      <p class="text-center">{{ $t('message.empty') }}</p>
      <!--div
        class="message-item"
        v-for="(message, index) in messages"
        :class="{'is-hidden': index >= currentMessages}"
        :key="'message-' + index"
      >
        <img class="message-profile" :src="message.sender.image" />
        <div class="message-top">
          <img class="message-profile-mobile" :src="message.sender.image" />
          <div class="message-header">
            <h2>{{ message.sender.name }}</h2>
            <p :class="{'is-read': message.isRead}">
              {{ message.date }}
              <span v-if="!message.isRead" class="message-notif"></span>
            </p>
          </div>
          <p class="message-object">{{ message.object }}</p>
          <p class="message-content" v-html="message.content"></p>
        </div>
        <div class="message-bottom">
          <p class="message-object">{{ message.object }}</p>
          <p class="message-content" v-html="message.content"></p>
        </div>
      </div>
      <div
        v-if="currentMessages < messages.length"
        class="message-more"
        @click="showMoreMessages()"
      >
        <i class="button-icon sofont sofont-cross-add"></i>
        <p>{{ $t("message.more") }}</p>
      </div-->
    </div>
  </div>
</template>

<script src="./message.js"></script>
