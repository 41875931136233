import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import parameters from "@/helpers/parameters";

export default {
  name: "LocalesInput",
  components: { vSelect },
  props: {
    locale: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      locLabel: this.locale,
    };
  },
  emits: ["update"],
  computed: {
    locales() {
      const arr = [];
      const staticLocales = parameters.locales;
      this.$store.state.platform.theme.i18n.forEach((lang) => {
        const staticLoc = staticLocales.find((item) =>
          item.key.startsWith(lang)
        );
        if (staticLoc) {
          arr.push(staticLoc);
        }
      });
      return arr;
    },
    localeLabel() {
      if (this.locLabel) {
        const result = this.locales.find((loc) => this.locLabel === loc.key);
        return result ? result.label : "";
      }
    },
  },
};
