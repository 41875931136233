/**
 * BIRTHDATE INPUT, send date date to format 'YYYY-MM-DD' (string)
 * use as :
 * <InputBirthdate name="birthdate" v-model:birthdate.lazy="v$.form.birthdate2.$model" required disabled/>
 */
import {
  dateIsValid,
  formatDateInputToISOString,
  formatDate,
  formatDateInput,
  parseDate,
  getFormattedDate
} from '@/helpers/date'
import {useVuelidate} from '@vuelidate/core'

export default {
  name: 'InputBirthdate',
  components: {},

  props: {
    birthdate: {
      type: [String, Date],
      default: null
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        displayedValue: {
          d: this.birthdate ? getFormattedDate(this.birthdate).d.toString() : "",
          m: this.birthdate ? getFormattedDate(this.birthdate).m.toString() : "",
          y: this.birthdate ? getFormattedDate(this.birthdate).y.toString() : ""
        },
        disabled: this.disabled,
      },
      monthsNum: 12,
      daysNum: 31,
      minYear: 1930,
      maxYear: new Date().getFullYear(),
      defaultYear: 1975,

    }
  },
  computed: {
    // dynamic num of days in function of selected month
    /* daysNum() {
      let daysNum;

      switch (+this.form.displayedValue.m) {
        case 2:
          daysNum = 29;
          break;

        case 4:
        case 6:
        case 8:
        case 11:
          daysNum = 30;
          break;

        default:
          daysNum = 31;
      }
      return daysNum;
    }, */
    isValidDay() {
      return this.form.displayedValue.d > 0
    },
    isValidMonth() {
      return this.form.displayedValue.m > 0
    },
    isValidYear() {
      return this.form.displayedValue.y > 0
    },
    selectedDateToString() {
      let date = null

      if ((+this.form.displayedValue.y > 0) && (+this.form.displayedValue.m > 0) && (+this.form.displayedValue.d > 0)) {
        date = this.form.displayedValue.y.toString() + '-' + this.formatDisplayedNumber(this.form.displayedValue.m).toString() + '-' + this.formatDisplayedNumber(this.form.displayedValue.d).toString()
      }
      return date
    },

    listeners() {
      return Object.assign({},
        this.$attrs, {
          input: this.onInputChange
        }
      )
    },

    model: {
      get() {
        console.log('birthdate GET VALUE', this.birthdate)
        return this.birthdate
      },
      set(value) {
        console.log('britdhbirthdateate SET VALUE', value, this.birthdate)
        this.$emit("update:birthdate", value)
      }
    },

  },
  emits: ['input', 'update:birthdate'],
  mounted: function () {
    console.log('mounted with value ', this.birthdate, getFormattedDate(this.birthdate), this.form.displayedValue)
  },
  methods: {
    /**
     * format number with a 0 if number < 10
     * @param {number} number
     * @returns
     */
    formatDisplayedNumber(number) {
      return number < 10 ? '0' + number : number
    },

    /**
     * set range of years to display
     * @param {number} start year
     * @param {number} end year
     * @returns array of stringed years range
     */
    displayedYearsRange(start, end) {
      return Array.from({
        length: (end - (start - 1))
      }, (v, k) => (k + start).toString())
    },
    // Update input value on each input change
    onInputChange: function (evt) {
      setTimeout(() => {
        if (this.selectedDateToString) {
          if (dateIsValid(this.selectedDateToString)) {
            console.log('date is valid in input birthdate, value is ', formatDateInputToISOString(this.selectedDateToString))
            this.model = formatDateInputToISOString(this.selectedDateToString)
          } else {
            console.log('date is not valid in birthdate input, send it raw', this.selectedDateToString)
            this.model = this.selectedDateToString
          }

        } else {
          this.model = null
        }
      }, 0)
    },
  }
}
