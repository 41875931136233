<template>
  <div class="stripe-sca">
    <FormWrapper
      :api="null"
      :btnText="`${$t('stripe.btnText')}` + ' ' + $n(amount, 'currency')"
      :btnDisabled="!validation || loading"
      @submitted="onSubmitted"
      @error="onError"
    >
      <fieldset v-show="viewCardElt">
        <div id="card-number">
        </div>
          <p v-if="numberError.length" role="alert" class="invalid-feedback">{{numberError}}</p>
        <div id="card-expiry">
        </div>
          <p v-if="expiryError.length" role="alert" class="invalid-feedback">{{expiryError}}</p>
        <div id="card-cvc">
        </div>
          <p v-if="cvcError.length" role="alert" class="invalid-feedback">{{cvcError}}</p>
      </fieldset>
      <!-- We'll put the error messages in this element -->
      <ul v-if="displayError.length" role="alert" class="invalid-feedback">
        <li>{{ displayError }}</li>
      </ul>

      <div v-if="loading">
        <Spinner :alt="$t('form.loading')" />
      </div>
    </FormWrapper>
  </div>
</template>

<script src="./stripe-sca.js"></script>
<style scoped lang="scss" src="./stripe-sca.scss"></style>
