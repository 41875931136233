<template>
  <div class="external">
    <div class="responsive-embed">
      <iframe
        id="iframeContent"
        :src="urlDecoded"
        class="content-iframe"
        frameborder="0"
        width="100%"
        :height="height"></iframe>
    </div>
  </div>
</template>

<script src="./external.js"></script>
