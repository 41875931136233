
export default {
  name: 'ActionText',
  components: {},
  props: {
    action: {
      type: Function,
      default: () => { }
    },
    icon: {
      type: String,
      default: "",
      required: false
    },
    isOnLegend: {
      type: Boolean,
      default: false
    },
    isTitle: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "Action",
      required: true
    },
  },
  data () {
    return {}
  },
  emits: ['action'],
  computed: {},
  methods: {
  }
}
