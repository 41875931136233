import {email, required} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import ActionText from "@/components/action-text/action-text.vue"
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import Popup from "@/components/popup/popup.vue"

import {logout, saveUserEmail} from '@/store/api'

import FormPasswordSecure from './form-password-secure.vue'

export default {
  name: 'FormEmail',
  components: {ActionText, FormPasswordSecure, FormWrapper, InputWrapper, Popup},
  props: {},
  data() {
    return {
      v$: useVuelidate(),
      saveUserEmail,
      activationMail: this.$store.state.user?.activationMail,
      form: {
        email: this.$store.state.user?.email,
        mobile: this.$store.state.user?.mobile, // Why ? I dont know
      },
      isModified: false,
      showForm: false,
      showPopup: false,
      showPopupOk: false,
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
        $lazy: true
      },
    },
  },
  computed: {},
  methods: {
    toggleForm() {
      this.showForm = !this.showForm
      if (!this.showForm) {
        this.isModified = false
        this.form = {
          email: this.$store.state.user?.email,
        }
      }
    },
    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
      this.toggleForm()
    },
    openPopupOk() {
      this.showPopup = false
      this.showPopupOk = true
    },
    async closePopupOk() {
      this.showPopupOk = false
      this.toggleForm()
      // try {
      //   await logout()
      // } catch (error) {
      //   console.error('[ERROR]', error)
      // } finally {
      //   this.$router.push({ name: 'login' })
      // }
    },
    update() {
      this.isModified = true
    },
    async onSubmitted() {
      this.openPopupOk()
    },
    onError(error) {
      console.error('[ERROR]', error)
    },
  }
}
