import { Workbox } from "workbox-window"
let wb
if (process.env.NODE_ENV === 'production' && "serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`)
  // console.log('workbox registered ', wb)
  wb.addEventListener("controlling", () => {
    window.location.reload()
  })
  wb.register()
} else {
  wb = null
}
export default wb
