<template>
  <div class="alert alert-danger list-errors" role="alert">
    <p class="mb-0" v-html="err.message"></p>
    <ul v-if="err.details.length > 0" class="mt-1 mb-0">
      <li v-for="(detail, index) of err.details" :key="index" v-html="detail.message"></li>
    </ul>
  </div>
</template>

<script src="./list-errors.js"></script>
<style scoped lang="scss" src="./list-errors.scss"></style>
