import BtnMultiStates from '@/components/btn-multi-states/btn-multi-states.vue'
import ListErrors from '@/components/list-errors/list-errors.vue'
//import { kebabCase } from '@/helpers/lodash'
import { EventBus } from '@/event-bus.js'

export const FORM_WRAPPER_EVENTS = {
  SUBMIT: 'form-wrapper::submit',
  UPDATE: 'form-wrapper::update'
}

export default {
  name: 'FormWrapper',
  components: { BtnMultiStates, ListErrors },
  props: {
    api: { type: Function },
    btnClass: { type: String, default: 'button' },
    btnDisabled: { type: Boolean, default: false },
    bypassError: { type: Boolean, default: false }, // allow to bypass error displaying, send submitted even if error in request api
    btnText: { type: String, default: '' },
    cancel: { type: Function, default: null },
    customErrors: { type: Array, default: () => null },
    forceId: { type: String },
    invalid: { type: Boolean, default: false },
    validation: { type: Object, default: () => null },
    /* istanbul ignore next */
    onUploadProgress: { type: Function, default: () => { } },
  },
  data () {
    return {
      /* save data */
      saveError: null,
      saveLoading: false,
      btnState: 'idle'
    }
  },

  computed: {
    id () {
      if (this.forceId) {
        return this.forceId
      }
      /* istanbul ignore next */ // unit test parent haven't $vnode :(
      if (this.$parent.$vnode === undefined) {
        console.warn('[DEBUG] Form-wrapper ❗ $parent.$vnode is undefined')
        return 'unknown-tag'
      }

      if(this.$parent.$vnode.props?.id){
        return this.$parent.$vnode.props.id
      }
      /* istanbul ignore next */
      //const tag = this.$parent.$vnode.tag.split('-').pop()
      /* istanbul ignore next */
      // return kebabCase(tag)
    },
    isInvalid () {
      console.log('form wrapper is invalid ', this.validation)

      if (this.validation) {
        this.validation.$touch()
        if (this.validation.$pending || this.validation.$error || this.validation.$invalid) return true
      }
      console.log('form wrapper is invalid ', this.invalid)
      return this.invalid
    }
  },
  created: function () {
    EventBus.on(FORM_WRAPPER_EVENTS.SUBMIT, this.forceSubmit)
  },
  beforeUnmount: function () {
    EventBus.off(FORM_WRAPPER_EVENTS.SUBMIT, this.forceSubmit)
  },
  emits: ['error', 'loading', 'submitted', 'cancel'],
  methods: {
    forceSubmit (e) { if (e.id === this.id) { this.save(e.options) } },
    async save (options = { bypassValidation: true, params: {} }) {
      console.log('form save ', options, this.isInvalid)
      // Check that form validation is ok
      if (!options.bypassValidation) {
        if (this.isInvalid) {
          const error = {
            response: {
              status: 412,
              data: {
                message: this.$t('errors.invalid'),
              }
            }
          }
          this.btnState = 'error'
          this.saveError = error
          this.$emit('error', error)
          EventBus.emit(FORM_WRAPPER_EVENTS.UPDATE, { id: this.id, state: 'error', data: error })
          return
        }
      }

      // Start loading env
      this.btnState = 'loading'
      this.saveLoading = true
      this.saveError = null
      this.$emit('loading')
      EventBus.emit(FORM_WRAPPER_EVENTS.UPDATE, { id: this.id, state: 'loading' })

      // Ask for the request and manage errors
      if (this.api !== null) {
        try {
          const res = await this.api()
          this.btnState = 'success'
          if ((res && res.data) || (res && res.status >= 200 && res.status < 400)) {
            this.$emit('submitted', res.data)
          }
        } catch (error) {
          // DO NOT DISPLAY ERROR WHEN REQUEST RETURN ERROR
          if (this.bypassError) {
            this.btnState = 'success'
            this.$emit('submitted', {})
          } else {
            this.btnState = 'error'
            this.saveError = error
            if (this.customErrors) {
              console.log('customEroos', this.customErrors)
              this.customErrors.forEach(err => {
                if (err.response.status === error.response?.status) {
                  this.saveError = err
                }
              })
            }
            this.$emit('error', error)
            EventBus.emit(FORM_WRAPPER_EVENTS.UPDATE, { id: this.id, state: 'error', data: error })

          }

        } finally {
          this.saveLoading = false
        }

      } else {
        this.saveLoading = false
        this.btnState = 'success'
        this.$emit('submitted', {})
      }
    }
  }
}
