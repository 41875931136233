<template>
  <div class="small-12 medium-7 highlighted-bloc text-center">
    <p><strong v-html="texts.title"></strong></p>
    <p v-html="texts.text"></p>
    <button class="button" @click="onClick">
      {{ texts.button }}
    </button>
  </div>
</template>

<script src="./highlighted-bloc.js"></script>
<style  scoped lang="scss" src="./highlighted-bloc.scss"></style>
