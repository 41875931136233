<template>
  <div v-if="loadLoading">
    <Spinner />
  </div>
  <SignUpLinkExpired v-else-if="isLinkExpired" />
  <SignUpModification v-else-if="isModification" />
  <SignUpCreation v-else />
</template>

<script src="./signup.js"></script>
