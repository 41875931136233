import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import Popup from '@/components/popup/popup.vue'
import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"

import store from '@/store'
import { getUserApi } from '@/store/api'

import FormEmail from './forms/form-email.vue'
import FormMobile from './forms/form-mobile.vue'
import FormPassword from './forms/form-password.vue'

export default {
  name: 'Security',
  components: {
    FormEmail,
    FormMobile,
    FormPassword,
    GetterWrapper,
    Popup,
    ViewBlocs,
    ViewDetails,
    ViewHeader
  },
  props: {},
  computed: {},
  data () {
    return {
      apiLoad: [
        {
          api: getUserApi
        }
      ],
      showPolitics: false,
    }
  },
  validations: {
  },
  created: function () { },
  mounted: function () { },
  beforeUnmount: function () { },
  methods: {
    onData ([data]) {
      store.commit('user', data)
    },
    togglePolitics () {
      this.showPolitics = !this.showPolitics
    },
  }
}
