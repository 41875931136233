import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import Popup from "@/components/popup/popup.vue"
import ViewHeader from "@/components/view-header/view-header.vue"

import { putFormSignup } from '@/store/api'

export default {
  name: 'SignUpModification',
  components: {
    FormWrapper,
    InputWrapper,
    Popup,
    ViewHeader,
  },
  props: {},
  data () {
    return {
      v$: useVuelidate(),
      putFormSignup,
      formSignup: {
        ...this.$store.state.user,
        activationMail: null,
        code: this.$route.params.code,
        email: this.$store.state.user.activationMail,
      },
      oldMail: this.$store.state.user.email,
      showPopupOk: false,
      store: this.$store.state,
    }
  },
  validations: {
    formSignup: {
      email: { required }
    },
  },
  computed: {},
  created: function () { },
  methods: {
    openPopupOk () {
      this.showPopupOk = true
    },
    closePopupOk () {
      this.showPopupOk = false
      this.$router.push({ name: 'login' })
    },
    async onSubmitted () {
      this.openPopupOk()
    },
    async onError (error) {
      console.error('[ERROR]', error)
    },
  }
}
