import { formatDateInput, getDiff, dateIsValid, isIsoDate } from '@/helpers/date'
import { helpers } from '@vuelidate/validators'
import {parsePhoneNumber} from "libphonenumber-js";

// Validate a name
export function validName (param) {
  return helpers.withParams(
    { type: 'validName' },
    (value) => !helpers.req(value) || /^[^±()"!@£$%^&*_+§¡€#¢§¶•ªº«\\<>?:;|=.,]{1,}$/.test(value)
  )
}

// Password strength checking
export function goodPassword (param) {
  return helpers.withParams(
    { type: 'weakPassword' },
    (value) => !helpers.req(value) || (value.length >= 8 && /[a-z]/.test(value) && /[A-Z]/.test(value)) // && /[0-9]/.test(value))
  )
}

// Validate a digit
export function digitOnly (param) {
  return helpers.withParams(
    { type: 'digitOnly' },
    (value) => !helpers.req(value) || /^[0-9,.]+$/.test(value)
  )
}

// Required Accept Terms checkbox
export function acceptTerms () {
  return helpers.withParams(
    { type: 'acceptTerms' },
    (value) => !helpers.req(value) || value === true
  )
}

// Min Age required by birthdate
export function minAgeRequired (minAge) {
  return helpers.withParams(
    { type: 'minAgeRequired' },
    (value) => {
      // Check only the age, not the requirement
      if (!value) return true

      // Parse input
      const _date = formatDateInput(value)
      const _diff = getDiff(_date)

      // Returns validation
      return !helpers.req(value) || (minAge <= _diff.year && _diff.year < 110)

    }
  )
}

// Mobile number
export function validateMobileNumber () {
  return helpers.withParams(
    { type: 'mobileNumberInvalid' },
    (value) => {
      const phoneNumber = parsePhoneNumber(value)
      // Parse input
      return !helpers.req(value) || phoneNumber.isValid()

    }
  )
}

// date exist
export function dateNoExist () {
  return helpers.withParams(
    { type: 'dateNoExist' },
    (value) => {
      // if no value, no alert
      if (!value) return true

      // if date is ISO fromatted, it has been previously validated, so return true
      if (isIsoDate(value)) return true
      console.log('date no exist helper ', value)
      // Returns validation
      return !helpers.req(value) || dateIsValid(value)

    }
  )
}

//try to get tin PAttern
export  function validateTin (value) {
  console.log('validateTIn, ' , value, new RegExp(this.tinPattern))
  console.log(this.tinPattern.test(value))
  return helpers.regex(value, this.tinPattern);
  //return !helpers.req(value) || this.tinPattern.test(value)




}
