<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="card-logo-visa" :width="this.width" :height="this.height" viewBox="0 0 52.799 16.523">
    <g transform="translate(0 -100.23)">
      <path class="a"
            d="M43.91,100.23l-2.65,16.49H45.5l2.648-16.49Zm12.776,6.717c-1.48-.731-2.387-1.224-2.387-1.972.018-.68.767-1.376,2.44-1.376a7.37,7.37,0,0,1,3.155.611l.384.172.576-3.45a10.69,10.69,0,0,0-3.8-.68c-4.183,0-7.128,2.175-7.146,5.285-.035,2.3,2.109,3.569,3.712,4.333,1.638.784,2.2,1.292,2.2,1.988-.018,1.069-1.325,1.562-2.544,1.562a8.614,8.614,0,0,1-3.975-.85l-.558-.254-.592,3.585a13.227,13.227,0,0,0,4.724.851c4.445,0,7.337-2.142,7.374-5.456C60.258,109.478,59.128,108.086,56.686,106.947Zm15.026-6.666H68.435a2.1,2.1,0,0,0-2.214,1.327L59.93,116.72h4.445l1.224-3.272h4.973l.635,3.285h3.92Zm-4.881,9.865c.086.008,1.706-5.341,1.706-5.341l1.29,5.341Zm-29.11-9.916-4.15,11.2-.452-2.209a12.311,12.311,0,0,0-5.857-6.7l3.8,14.175h4.481L42.2,100.232H37.721Z"
            transform="translate(-22.328)"/>
      <path class="b" d="M9.395,102.371a2.767,2.767,0,0,0-2.755-2.123H.068L0,100.553c5.127,1.264,9.43,5.153,10.826,8.811Z" transform="translate(0 -0.015)"/>
    </g>
  </svg>
</template>

<script src="./logo-visa.js"></script>
<style scoped lang="scss" src="./logo-visa.scss"></style>
