import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import localeData from "dayjs/plugin/localeData"
import utc from "dayjs/plugin/utc"
import toObject from "dayjs/plugin/toObject"
import * as localeFR from "dayjs/locale/fr"
import * as localeENGB from "dayjs/locale/en-gb"
import * as localeIT from "dayjs/locale/it"
import * as localeES from "dayjs/locale/es"

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.extend(localeData)
dayjs.extend(toObject)
dayjs.extend(utc)

const localesFormats = {
  "fr-fr": "JJ/MM/AAAA",
  "en-gb": "DD/MM/YYYY",
  "it-it": "DD/MM/YYYY",
  "es-es": "DD/MM/YYYY",
  en: "MM/DD/YYYY", // fallback for us
  "en-us": "MM/DD/YYYY"
}

export function formatSeconds (secs) {
  function pad (n) {
    return n < 10 ? "0" + n : n
  }

  var h = Math.floor(secs / 3600)
  var m = Math.floor(secs / 60) - h * 60
  var s = Math.floor(secs - h * 3600 - m * 60)

  return pad(m) + ":" + pad(s)
}

export function getDiff (_date) {
  return {
    day: dayjs().diff(_date, "day", true),
    month: dayjs().diff(_date, "month", true),
    year: dayjs().diff(_date, "year", true)
  }
}

export function getOldDate (_yearsBefore) {
  return dayjs()
    .subtract(_yearsBefore, "year")
    .format("YYYY-MM-DD")
}

export function formatDateInput (_date) {
  return dayjs(_date).format("YYYY-MM-DD")
}

export function formatDateInputWithLocale (_date) {
  return dayjs(_date).format("L")
}

export function changeGlobalDateLocale (_locale) {
  let _lang
  switch (_locale) {
  case "fr-FR":
    _lang = localeFR
    break

  case "en-GB":
    _lang = localeENGB
    break

  case "es-ES":
    _lang = localeES
    break

  case "it-IT":
    _lang = localeIT
    break

  default:
    _lang = "en"
    break
  }
  dayjs.locale(_lang)
  //console.log('DATE JS  : change global languae to locale ', _locale, _lang, dayjs.locale())
}

export function getDateLocale () {
  return dayjs.locale()
}

export function getLocaleDays () {
  return dayjs.weekdays()
}

export function getLocaleMonths () {
  return dayjs.months()
}

export function dateIsValid (_date) {
  const _stringDate = dayjs(_date).format("YYYY-MM-DD")
  // console.log('[HELPERS DATE] dateIsValid, isvalid ? ',  _stringDate, dayjs(_date, "YYYY-MM-DD", true).isValid())
  return dayjs(_date, "YYYY-MM-DD", true).isValid()
}

export function isIsoDate (str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
  var d = new Date(str)
  return d.toISOString() === str
}

export function formatDateInputToISOString (_date) {
  return dayjs.utc(_date, "YYYY-MM-DD").toISOString()
}

export function formatDate (_date, _lang = null) {
  if (_date && typeof _date === "string") {
    _date = dayjs.utc(_date)
  }
  if (_lang) {
    return new Intl.DateTimeFormat(_lang).format(_date)
  }
  return new Intl.DateTimeFormat({}).format(_date)
}

export function formatTime (_date, _lang = "default") {
  if (_date && typeof _date === "string") {
    _date = dayjs.utc(_date)
  }
  const options = { hour: "numeric", minute: "2-digit", hour12: false }
  return new Intl.DateTimeFormat(_lang, options).format(_date)
}

export function daysFromNow (_date) {
  console.log(
    "days from now ",
    dayjs(dayjs(_date)).diff(dayjs(Date.now()), "days")
  )
  return dayjs(dayjs(_date)).diff(dayjs(Date.now()), "days")
}

export function parseDate (_date, _format) {
  return dayjs(_date, _format)
}

/**
 * return a formatted date object
 * @param {*} _date
 * @returns
 */
export function getFormattedDate (_date) {
  const date = dayjs.utc(_date)
  //console.log("date ", date)
  return {
    d: +date.get("date"),
    m: +date.get("month") + 1,
    y: date.get("year")
  }
}

/**
 * format date to expiry MM/YY on card
 * @param {object} _date
 * @returns {string} formatted date
 */
export function getDateToExpirationFormat (_date) {
  return dayjs.utc(_date).format("MM/YY")
}
