import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import ViewHeader from "@/components/view-header/view-header.vue"

export default {
  name: 'CGU',
  components: {
    ViewBlocs,
    ViewHeader
  },
  props: {
    // Retrieve specific card's CGU, can be null if general GCU called
    cards: { type: Array, defaut: () => null }
  },
  computed: {},
  data () {
    return {}
  },
  created: function () { },
  mounted: function () { /* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {}
}
