<template>
  <!-- Request cards data -->
  <GetterWrapper id="banners" :api-load="apiLoad" @data="onData">
    <div class="banners row align-center">
      <ViewHeader
        :hasBack="true"
        urlBack="card-item-store"
        :title="$t('card.actions.store')"
        icon="sofont-bag"
        backgroundIcon="orange"
      />
      <div class="columns small-12 medium-10 large-8">
        <ViewDetails
          :content="$t('card.banners.intro')"
        />

        <!-- Shop items -->
        <div
          class="row small-up-1 medium-up-3 large-up-3 shop-items"
        >
          <div
            v-for="(shop, index) in shops"
            :key="index"
            class="column shop-item"
          >
            <div class="shop-image">
              <a :href="shop.url" target="_blank">
                <div
                  class="shop-icon"
                  :style="{ 'background-image': 'url(' + shop.logoUrl + ')' }"
                ></div>
                <img :src="shop.bannerUrl" />

              </a>
            </div>
            <div class="shop-links">

            </div>
          </div>
        </div>
        <!--div v-else>
          <div v-if="type === 'shop' && activeItem === '1'">
            <p v-if="onlineHelps.length === 1">{{ onlineHelps[0] }}</p>
            <div
              class="stepper"
              v-else
              v-for="(help, index) in onlineHelps"
              :key="index"
            >
              <div class="stepper-circle">{{ index + 1 }}</div>
              <div class="stepper-content">{{ help }}</div>
            </div>
            <div class="row align-center">
              <div class="columns small-12 medium-7 stepper final">
                <div class="stepper-circle">
                  <i class="sofont sofont-check" />
                </div>
                <b>
                  <div class="stepper-content" v-html="onlineHelpsFinal"></div>
                </b>
                <div class="stepper-box" v-if="card.amount > 0 && card.program && card.program.stripeKey">
                  <div>
                    <b>{{ $t("card.buy.finalBox.title") }}</b>
                  </div>
                  <div>{{ $t("card.buy.finalBox.content",{value: $n(50, 'currency')}) }}</div>
                  <router-link
                    class="button"
                    :to="{ name: 'card-item-balance', params: { id: card.id } }"
                    >{{ $t("card.buy.btnSolde") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p v-if="helps.length === 1">{{ helps[0] }}</p>
            <div class="stepper" v-else v-for="(help, index) in helps" :key="index">
              <div class="stepper-circle">{{ index + 1 }}</div>
              <div class="stepper-content">{{ help }}</div>
            </div>
            <div class="row align-center">
              <div class="columns small-12 medium-7 stepper final">
                <div class="stepper-circle">
                  <i class="sofont sofont-check" />
                </div>
                <b>
                  <div class="stepper-content" v-html="helpsFinal"></div>
                </b>
                <div class="stepper-box" v-if="card.amount > 0 && card.program && card.program.stripeKey">
                  <div>
                    <b>{{ $t("card.buy.finalBox.title") }}</b>
                  </div>
                  <div>{{ $t("card.buy.finalBox.content",{value: $n(50, 'currency')}) }}</div>
                  <router-link
                    class="button"
                    :to="{ name: 'card-item-balance', params: { id: card.id } }">
                    {{ $t("card.buy.btnSolde") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </!--div-->
      </div>
    </div>
  </GetterWrapper>
</template>

<script src="./banners.js"></script>

<style lang="scss" src="./banners.scss">
</style>
