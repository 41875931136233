import BtnUrlAction from "@/components/btn-url-action/btn-url-action.vue"
import ViewHeader from "@/components/view-header/view-header.vue"

export default {
  name: 'Account',
  components: { BtnUrlAction, ViewHeader },
  props: {},
  computed: {},
  data () {
    return {

    }
  },
  created: function () { },
  mounted: function () { /* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {

  }
}
