import { getRapydIframeDatas } from "@/store/api"
import Spinner from '@/components/spinner/spinner.vue'

export default {
  name: "CardRapydShow",
  components: { Spinner },
  props: {
    card: { type: Object, defaut: () => { } },
  },
  data () {
    return {
      rapydScriptId: 'rapydScript',
      loading: true,
    }
  },
  methods: {
    loadScript (scriptUrl) {
      const script = document.createElement('script')
      script.id = this.rapydScriptId
      script.src = scriptUrl
      document.head.appendChild(script)

      return new Promise(res => script.onload = res)
    }
  },
  async mounted () {
    const response = await getRapydIframeDatas({ id: this.card.id })
    console.log(response.data)

    const rapydUrl = response.data.mode === 'production' ? 'https://checkouttoolkit.rapyd.net' : 'https://sandboxcheckouttoolkit.rapyd.net'

    this.loadScript(rapydUrl).then(() => {
      // this.loading = false
      const checkout = new RapydCheckoutToolkit(response.data)
      checkout.displayCheckout()

      const rapydIframe = document.getElementById("rapyd-checkout-frame")
      if (rapydIframe) {
        console.log("trouvé!!")
        rapydIframe.onload = () => {
          this.loading = false

        }
      }
    })
  },
  beforeUnmount () {
    const elem = document.getElementById(this.rapydScriptId)
    elem.remove()
  }
}
