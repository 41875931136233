import {
  LS
} from "@/const"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import {
  getCard,
  getCardBanners
} from '@/store/api'

export default {
  name: "Banners",
  components: {
    GetterWrapper,
    ViewDetails,
    ViewHeader,
  },
  props: {},
  computed: {


    helps () {
      let helps = []
      switch (this.$route.name) {
      case 'card-item-donate':
        helps = Array.isArray(this.$tm('card.buy.donate.blocs')) ? this.$tm('card.buy.donate.blocs') : []
        break
      /*case 'card-item-online':
        helps = Array.isArray(this.$tm('card.buy.online.blocs')) ? this.$tm('card.buy.online.blocs') : []
        break*/
      case 'card-item-store':
        helps = Array.isArray(this.$tm('card.buy.store.blocs')) ? this.$tm('card.buy.store.blocs') : []
        break
      }
      return helps
    },
    helpsFinal () {
      let helpsFinal = ''
      switch (this.$route.name) {
      case 'card-item-donate':
        helpsFinal = this.$tm('card.buy.donate.blocsFinal')
        break
      /*case 'card-item-online':
        helpsFinal = this.$tm('card.buy.online.blocsFinal')
        break*/
      case 'card-item-store':
        helpsFinal = this.$tm('card.buy.store.blocsFinal')
        break
      }
      return helpsFinal
    },

  },
  data () {
    return {
      apiLoad: [{
        api: getCard,
        data: {
          id: this.$route.params.id
        }
      },

      ],

      card: null,
      shops: [],

    }
  },
  created: function () {

    this.getBanners()

  },

  mounted: function () {
  },
  beforeUnmount: function () {},
  methods: {
    onData (data) {
      this.card = data[0]
      this.card.masked = data[1]
      console.log('on data in banners card is ', this.card)
    },
    async getBanners () {
      try {
        const res = await getCardBanners({
          id: this.$route.params.id,
          offset: 0,
          size: 0, // unlimited amount of banners displayed
          type: this.$route.params.type
        })
        this.shops = res.data
        console.log('[get banners]', this.shops)
      } catch (error) {
        console.error('[ERROR in get banners]', error)
      }
    },
    setActive (index) {
      this.activeItem = index
    },
    showCardDetails () {
      console.log('show card details')
      clearTimeout(this.timer)
      clearTimeout(this.timerHide)
      if (this.cardShown) {
        this.hideCard()
      } else {
        this.countDown = LS.CARD_SHOW_TIMOUT_SECS
        this.cardShown = true
        this.countDownTimer()
      }
    },
    countDownTimer () {
      if (this.countDown > 0) {
        this.timer = setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else if (this.countDown <= 0) {
        this.hideCard()
      }
    },
    hideCard () {
      clearTimeout(this.timer)
      clearTimeout(this.timerHide)
      this.cardShown = false
      this.timerHide = setTimeout(() => {}, 1000)
    },
    itemCSEvisible (index) {
      if (index == 2 || index == 3) {
        console.log(this.card, this.card.program.CSE)
        return this.card && this.card.program.CSE
      }
      return true

    }
  }
}
