import { get } from "@/helpers/lodash";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "InputWrapper",
  components: {},
  props: {
    label: { type: String, default: () => null },
    for: { type: String, default: () => null },
    icon: { type: String, default: () => null },
    help: { type: String, default: () => null },
    context: { type: Object, default: () => null },
    minAgeRequiredMessage: { type: String, default: "" },
    dateNoExistMessage: { type: String, default: "" },
    vref: { type: Object, default: () => null },
    isInvalid: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    clearBtn: { type: Boolean, default: false },
    validationPath: { type: String, required: false, default: null }, // new for vue3 vulidate management
    errors: {
      type: Array,
      default: () => [], // [{ 'type': 'required', message: $t('form.error.required') }, ...]
    },
  },
  data() {
    return {
      vnode: null,
      displayHelp: false,
      /*refValue: null,*/ uuid: uuidv4(),
    };
  },

  computed: {
    defaultErrors: function () {
      return [
        { type: "required", message: this.$t("form.error.required") },
        { type: "minLength", message: this.$t("form.error.minLength") },
        { type: "email", message: this.$t("form.error.emailIncorrect") },
        { type: "sameAs", message: this.$t("form.error.sameAs") },
        {
          type: "containsUppercase",
          message: this.$t("form.error.containsUppercase"),
        },
        {
          type: "checkedCondition",
          message: this.$t("form.error.checkedCondition"),
        },
        { type: "validName", message: this.$t("form.error.validName") },
        { type: "weakPassword", message: this.$t("form.error.weakPassword") },
        { type: "acceptTerms", message: this.$t("form.error.acceptTerms") },
        { type: "minAgeRequired", message: this.minAgeRequiredMessage },
        { type: "dateNoExist", message: this.dateNoExistMessage },
        { type: "digitOnly", message: this.$t("form.error.digitOnly") },
      ];
    },
    id: function () {
      if (this.vnode === null || !this.vnode.props) return "";
      if (this.for) return this.for;
      if (this.vnode.props && this.vnode.props.id) return this.vnode.props.id;
      /* istanbul ignore next */
      if (this.vnode.elm && this.vnode.elm.querySelector("[id]")) {
        return this.vnode.elm.querySelector("[id]").getAttribute("id");
      }
      return "";
    },
    required: function () {
      if (this.isRequired) {
        return true;
      }
      if (this.vnode === null) {
        return false;
      }

      if (
        this.vnode.props &&
        this.vnode.props.required !== undefined &&
        this.vnode.props.required !== false
      ) {
        return true;
      }
      if (this.vnode.componentOptions) {
        return this.vnode.componentOptions.propsData.required !== undefined;
      }
      return false;
    },
    disabled: function () {
      if (this.vnode === null) {
        return false;
      }

      if (!this.vnode.props) {
        return false;
      }
      if (
        this.vnode.props.disabled !== undefined &&
        this.vnode.props.disabled !== false
      ) {
        return true;
      }
      if (this.vnode.componentOptions) {
        return this.vnode.componentOptions.propsData.disabled;
      }
      return false;
    },
    activeErrors: function () {
      const activeErrors = [];
      let parentValidation = null;
      if (this.vnode === null) {
        return activeErrors;
      }
      /*
      if (this.refValue === null) {
        const expression = this.getVModelExpression()
        if (expression === null) {
          return []
        } // pas de v-model ?!
        this.refValue = this.getReferenceValueFromExpressionNode(expression)
      }
      if (this.refValue === null) {
        return []
      }

      const activeErrors = []
      if (this.refValue.$error) {
        Object.keys(this.refValue.$params).forEach(key => {
          if (this.refValue[key] === false) {
            const err = {
              ...this.refValue.$params[key],
              ...this.defaultErrors.filter(
                e => e.type === this.refValue.$params[key].type
              )[0],
              ...this.errors.filter(
                e => e.type === this.refValue.$params[key].type
              )[0]
            }
            if (!err.message) err.message = err.type
            activeErrors.push(err)
          }
        })
      }
      */

      //we suppose that parent component use v$ : useVulidate() for validation
      if (this.vnode.context && this.vnode.context.v$) {
        parentValidation = this.vnode.context.v$;
      }


      if (!parentValidation) {
        return activeErrors;
      }

      if (parentValidation.$errors.length) {
        parentValidation.$errors.forEach((err) => {
          if (
            this.validationPath &&
            err.$propertyPath === this.validationPath
          ) {
            const error = {
              ...err.$params,
              ...this.defaultErrors.filter(
                (e) => e.type === err.$params.type
              )[0],
              ...this.errors.filter((e) => e.type === err.$params.type)[0],
            };
            if (!error.message) error.message = error.type;
            activeErrors.push(error);
          }
        });
      }
      // console.log("INPUT WRAPPER active errors", activeErrors);
      return activeErrors;
    },
  },
  emits: ["input"],
  mounted: function () {
    this.vnode = this.$slots.default()[0];
    if (this.vnode === null) {
      console.warn("[DEBUG] Input-wrapper ❗ vnode is undefined ");
    }
  },
  methods: {
    clearSelection() {
      if (this.vnode === null) {
        return;
      }
      this.vnode.componentInstance.$emit("input", null);
    },
    /*getVModelExpression() {
      console.log("get modal expression ");
      if (this.vnode.data?.directives) {
        // input classic
        for (const d of this.vnode.data.directives) {
          if (d.name === "model") {
            return d.expression;
          }
        }
      } else if (this.vnode.data?.model) {
        // component
        return this.vnode.data.model.expression;
      } else {
        return null;
      }
    },*/
    /*getReferenceValueFromExpressionNode(expression) {
      /* istanbul ignore next
      if (this.vref) {
        return this.vref;
      }
      if (!this.context && !this.vnode.context.v$) {
        console.warn("[DEBUG] v-node context required.");
        return null;
      }
      const _context = this.context || this.vnode.context.v$;
      const pathObj = expression.replace(/([^.])*\.(.*)\.\$model/, "$2");
      const value = get(_context, pathObj, null);
      if (!value) {
        console.warn(
          `[DEBUG] v-model "${expression}" does not exist in v-node context.`
        );
        return null;
      }
      if (
        !["$params", "$error", "$model"].every((v) => {
          return Object.prototype.hasOwnProperty.call(value, v);
        })
      ) {
        console.warn(
          `[DEBUG] v-model "${expression}" is not a valid Vuelidate model.`
        );
        return null;
      }
      return value;
    },*/
  },
};
