import ListErrors from '@/components/list-errors/list-errors.vue'
import Spinner from '@/components/spinner/spinner.vue'
import { EventBus } from '@/event-bus.js'

export const GETTER_WRAPPER_EVENTS = { LOAD: 'getter-wrapper::load' }

export default {
  name: 'GetterWrapper',
  components: { ListErrors, Spinner },
  props: {
    id: {
      type: String,
      required: true
    },
    apiLoad: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      loadError: null,
      loadLoading: true
    }
  },
  computed: {},
  created: function () {
    if (this.apiLoad.length) {
      this.load()
    } else {
      this.loadLoading = false
    }
    EventBus.on(GETTER_WRAPPER_EVENTS.LOAD, this.forceLoad)
  },
  mounted: function () { /* DOM accessible via this.$el */ },
  emits: ['data', 'error'],
  beforeUnmount: function () {
    EventBus.off(GETTER_WRAPPER_EVENTS.LOAD, this.forceLoad)
  },
  methods: {
    forceLoad (e) { if (e.id === this.id) { this.load() } },
    async load () {
      this.loadError = null
      this.loadLoading = true
      try {
        const res = (await Promise.all(this.apiLoad.map(({ api, data, options }) => api(data, options)))).map((res) => res.data)
        this.loadLoading = false
        this.$emit('data', res)
      } catch (error) {
        this.loadError = error
        this.$emit('error', error)
      } finally {
        this.loadLoading = false
      }
    }
  }
}
