<template>
  <div class="item-reward"  >
    <div class="row">
      <div class="column small-8 expiration-header">
        <i class="sofont sofont-arrow-down is-not-visible" ></i>
        <span class="expiration-text expiration-title">
            {{ $d(new Date(expiration.metas.DATE_EXPIR), 'date') }}  {{ $t('entries.expirationReward', {value : ''+expiration.label+''}) }}
        </span>
      </div>
      <div class="column small-4">
        <div class="expiration-text text-right">
          <b> {{ $n((expiration.metas.ENTRY_AM_EXP ? expiration.amount - expiration.metas.ENTRY_AM_EXP : expiration.amount), 'currency') }}</b>
        </div>
      </div>
      </div>

  </div>
</template>

<script src="./item-reward.js"></script>
