<template>
  <div
    :class="isOnLegend ? 'action-text on-legend' : 'column small-12 action-text'"
    @click="$emit('action')"
  >
    <div class="action-icon" v-if="icon">
      <i :class="'sofont ' + icon"></i>
    </div>
    <h2 v-if="isTitle" v-html="text"></h2>
    <p v-else v-html="text"></p>
  </div>
</template>

<script src="./action-text.js"></script>
<style scoped lang="scss" src="./action-text.scss"></style>
