<template>
  <div class="view-steps columns text-center">
    <div class="row align-center">
      <div
        class="stepper columns text-center shrink"
        v-for="(step, index) of _steps"
        :key="index"
        :class="{disabled: step.disabled, canClick : canGoBack && index < activeStep} "
        @click="onStepClick($event, index)"
      >
        <div class="row expanded align-center align-middle">
          <div class="metas columns shrink">
            <div class="stepper-circle">{{ index + 1 }}</div>
            <div class="stepper-text">{{ step.label }}</div>
          </div>
          <div v-if="index + 1 < _steps.length" class="spacer columns shrink"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./view-steps.js"></script>
<style scoped lang="scss" src="./view-steps.scss"></style>
