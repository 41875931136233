import store from "@/store"
export default {
  name: 'HighlightedBloc',
  components: {},
  props: {

    texts: {
      type: Object,
      default: {
        title: '',
        text: '',
        button: ''
      },
      required: true
    },

    onClick: {
      type: Function,
      default: () => {},
      required: true
    }
  },
  data () {
    return {}
  },
}
