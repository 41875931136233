<template>
  <div class="contact row align-center">
    <Popup v-if="showPolitics" hasClose @close="togglePolitics()">
      <h2><strong>{{$t('politics.title')}}</strong></h2>
      <ViewBlocs :blocs="$tm('politics.blocs')" section="politics"  class="text-left"/>
    </Popup>

    <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
      <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
      <p class="popup-valid-text text-center">{{ $t('contact.form.submitOk') }}</p>
    </Popup>

    <ViewHeader :hasBack="true" :title="$t('contact.title')"  :subtitle="$t('contact.subtitle')"/>
    <div class="columns small-12 medium-10 large-8">
      <p class="text-center" v-html="$t('contact.info')"></p>

      <FormWrapper
        :api="sendFormContact.bind(this, this.form)"
        :btn-text="`${$t('contact.form.submitTxt')}`"
        :validation="v$.form"
        @submitted="onSubmitted"
        @error="onError"
      >
        <fieldset>
          <div class="row align-center">
            <InputWrapper :label="`${$t('form.firstname')}`" class="columns small-12 medium-7" :validation-path="'form.firstname'">
              <input
                name="firstname"
                type="text"
                :placeholder="$t('form.firstname')"
                v-model.trim.lazy="v$.form.firstname.$model"
                required
                @input="forceUpper($event, form, 'firstname')"
              />
            </InputWrapper>

            <InputWrapper :label="`${$t('form.lastname')}`" class="columns small-12 medium-7" :validation-path="'form.lastname'">
              <input
                name="lastname"
                type="text"
                :placeholder="$t('form.lastname')"
                v-model.trim.lazy="v$.form.lastname.$model"
                required
                @input="forceUpper($event, form, 'lastname')"
              />
            </InputWrapper>

            <InputWrapper :label="`${$t('form.email')}`" class="columns small-12 medium-7" :validation-path="'form.email'">
              <input
                name="email"
                type="email"
                :placeholder="$t('form.email')"
                v-model="v$.form.email.$model"
                required
              />
            </InputWrapper>

            <InputWrapper :label="`${$t('form.subject')}`" class="columns small-12 medium-7" :validation-path="'form.subject'">
              <select
                name="subject"
                :placeholder="$t('form.subject')"
                v-model.lazy="v$.form.subject.$model"
                required
              >
                <option v-for="(sub, index) in subjects" :key="index" :value="sub">{{ sub }}</option>
              </select>
            </InputWrapper>

            <InputWrapper :label="`${$t('form.message')}`" class="columns small-12 medium-7" :validation-path="'form.message'">
              <textarea
                name="message"
                :placeholder="$t('form.message')"
                v-model="v$.form.message.$model"
                required
              />
            </InputWrapper>

            <label class="columns small-12 medium-7 text-justify legal-link legal-margin">
              {{ $t('contact.form.formIndications') + ' ' + $t('form.politics.begin')}}
              <span
                @click="togglePolitics()"
              >{{ $t('form.politics.click')}}</span>
              {{ $t('form.politics.end')}}
            </label>
            <label
              class="columns small-12 medium-7 text-justify legal-link legal-margin"
              v-html="$t('contact.form.formIndicationsMore')"
            ></label>
          </div>
        </fieldset>
      </FormWrapper>
    </div>
  </div>
</template>

<script src="./contact.js"></script>
