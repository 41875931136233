<template>
  <div class="InputPassword">
    <div class="InputPassword__Container">
      <slot
        name="password-input"
        :strength="this.strength"
        :type="type"
        :updatePassword="updatePassword"
        :value="password"
        v-bind="$attrs"
      >
        <input
         v-bind="$attrs"
          ref="input"
          :class="[
            classes,
            'InputPassword__Input',
            { 'InputPassword__Input--has-toggle': !disableToggle }
          ]"
          :title="strengthMessage"
          :value="password"
          v-on="listeners"
        />
      </slot>
      <slot v-if="!disableToggle" name="password-toggle" :toggle="togglePassword">
        <div
          class="InputPassword__Toggle"
          :title="toggleMessage"
          type="button"
          @click="togglePassword"
        >
          <slot name="password-hide" v-if="type === 'text'">
            <svg
              class="InputPassword__Toggle-Icon InputPassword__Toggle-Icon--hide"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
              />
            </svg>
          </slot>
          <slot name="password-show" v-else>
            <svg
              class="InputPassword__Toggle-Icon InputPassword__Toggle-Icon--show"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
              />
            </svg>
          </slot>
        </div>
      </slot>
    </div>

    <slot
      v-if="!disableStrength"
      name="strength-meter"
      :strength="this.strength"
      :strength-class="strengthClass"
      :strength-classes="strengthClasses"
      :strength-message="strengthMessage"
      :strength-messages="strengthMessages"
    >
      <div class="InputPassword__Meter" :title="strengthMessage">
        <svg
          v-for="i in 4"
          :key="i"
          :class="i <= strength ? strengthClass : 'none'"
          preserveAspectRatio="none"
          :data-strength="i"
          viewBox="0 0 2 1"
        >
          <rect width="100%" height="100%" />
        </svg>
      </div>
    </slot>
  </div>
</template>

<script src="./input-password.js"></script>
<style lang="scss" src="./input-password.scss"></style>
