import Spinner from '@/components/spinner/spinner.vue'

export default {
  name: 'BtnMultiStates',
  components: { Spinner },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    idleClass: {
      type: String,
      default: '' // btn-success | btn-error
    },
    state: {
      type: String,
      default: 'idle', // idle | loading | error | success
      required: true
    },
    text: {
      type: String,
      default: '', // 'Se connecter' | etc
      required: true
    },
    type: {
      type: String,
      default: 'button' // submit | button
    }
  },
  watch: {
    state: function (newState, oldState) {
      this.update(newState)
    }
  },
  data () {
    return { currentState: 'idle', timerAutoIdle: null, TIME_AUTO: 2000, currentClass: this.idleClass }
  },
  emits: ['click'],
  computed: {},
  methods: {
    update: function (newState) {
      clearTimeout(this.timerAutoIdle)
      this.currentState = newState
      switch (newState) {
      case 'success': {
        this.currentClass = 'btn-success'
        this.timerAutoIdle = setTimeout(() => { this.currentState = 'idle'; this.currentClass = this.idleClass }, this.TIME_AUTO)
        break
      }
      case 'error': {
        this.currentClass = 'btn-error'
        this.timerAutoIdle = setTimeout(() => { this.currentState = 'idle'; this.currentClass = this.idleClass }, this.TIME_AUTO)
        break
      }
      }
    }
  }
}
