import ViewHeader from "@/components/view-header/view-header.vue"

export default {
  name: 'SignUpLinkExpired',
  components: {
    ViewHeader,
  },
  props: {},
  data () {
    return {}
  },
  computed: {},
  created: function () { },
  methods: {}
}
