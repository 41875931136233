//import VuePhoneNumberInput from 'vue-phone-number-input'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

//import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'MobileInput',
  components: { MazPhoneNumberInput },
  props: {
    errors: {
      type: Array,
      default: () => [] // [{ 'type': 'required', message: $t('form.error.required') }, ...]
    },
    label: { type: String, default: () => null },
    isDisabled: { type: Boolean, default: () => false },
    mobileInit: { type: String, default: () => null },
    name: { type: String, default: () => null },
    required: { type: Boolean, default: () => false },
  },
  data () {
    return {
      mobile: this.mobileInit,
      vnode: null,
      translations: {
        countrySelector: {
          //placeholder: this.$t('form.mobileIndications.countrySelectorLabel'),
          placeholder: '',
          searchPlaceholder: '',
          error: this.$t('form.mobileIndications.countrySelectorError'),
        },
        phoneInput: {
          phoneNumberLabel: '', // this.$t('form.mobileIndications.mobileNumberLabel'),
          example: '', // this.$t('form.mobileIndications.example')
        }




      },
      // nationalNumber: null
    }
  },
  computed: {
    disabled: function () {
      if (this.isDisabled) { return true }
      if (this.vnode === null) { return false }
      if (this.vnode.data.attrs.disabled !== undefined && this.vnode.data.attrs.disabled !== false) { return true }
      if (this.vnode.componentOptions) { return this.vnode.componentOptions.propsData.disabled }
      return false
    }
  },
  watch: {
    mobileInit: function (newVal, oldVal) {
      this.mobile = newVal
    }
  },
  mounted(){
    console.log('mounted with attr', this.$attrs)
  },
  emits: [ 'focus', 'updateMobile'],
  methods: {
    /*focused: function () {
      this.$emit('focusMobile')
    },*/
    updated: function (data) {
      //console.log('updated data', data, this.$attrs)
      /* if (this.$attrs) {
        if (this.$attrs.focus) {
          this.$emit('focus')
        }
        if (this.$attrs.updateMobile) {
          console.log('update mobile ', data.isValid)
          this.$emit('updateMobile', { mobile: data.e164, invalid: !data.isValid })
        }
      }*/
      this.$emit('focus')
      //this.mobile = data.e164
      this.$emit('updateMobile', { mobile: data.e164, invalid: !data.isValid })
      // this.nationalNumber = data.nationalNumber
    }
  }
}
