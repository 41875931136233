<template>
  <FormWrapper
    forceId="signupcreation"
    :api="postFormSignupSms.bind(this, this.form)"
    :btnDisabled="codeSMSExpired"
    :btnText="`${$t('signup.smsCode.btnValue')}`"
    :customErrors="errors"
    :validation="v$.form"
    @submitted="onSubmitted"
    @error="onError"
  >
    <fieldset>
      <div class="row align-center">
        <InputWrapper class="columns small-12 medium-7 text-center" :validation-path="'form.codeSms'">
          <CodeSmsInput
            :checkInvalid="invalidCodeSMS"
            v-model:codeSms.lazy="v$.form.codeSms.$model"
            @submitCode="submitCode"
          ></CodeSmsInput>
        </InputWrapper>
      </div>
      <div v-if="codeSMSExpired" class="row align-center">
        <div class="columns small-12 medium-7 text-center bloc-code-error">
          <p v-html="$t('signup.smsCode.error')"></p>
          <p>
            {{ $t("signup.smsCode.errorContact") }}
            <router-link :to="{ name: 'contact' }">{{ $t("signup.smsCode.errorClick") }}</router-link>
          </p>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <div class="row align-center">
        <a
          class="columns small-12 medium-7 timer"
          :class="{ 'disabled' : countDown !== 0 }"
          :disabled="countDown !== 0"
          @click="onSendSms"
        >
          <span>{{ $t("forms.smsSecure.timer") }}</span>
          <br />
          <span v-if="countDown > 0">{{ formatedCountdown }}</span>
        </a>
        <a class="columns small-12 medium-7 timer" @click="onBack">
          <span>{{ $t("forms.smsSecure.change") }}</span>
        </a>
      </div>
    </fieldset>
  </FormWrapper>
</template>

<script src="./form-sms-secure.js"></script>
