<template>
  <div class="kit-ui row align-center">
    <h1>• Headers</h1>

    <div class="kitui-headers">
      <AppHeader forceState="NOT LOGGED" />
      <AppHeader forceState="LOGGED" />
    </div>

    <!---------------------------------------- HELP / MENTIONS / ETC ---------------------------------------->
    <h1>• ViewsHeaders</h1>
    <ViewHeader :hasBack="true" title="ViewHeader's title" subtitle="ViewHeader's subtitle" />

    <ViewHeader title="ViewHeader's title" subtitle="ViewHeader's subtitle"  icon="sofont-add-card"/>
    <div class="columns small-12 medium-10 large-8">
      <ViewDetails
       subtitle="ViewDetails subtitle"
        content="ViewDetails's solde"
        :disabledButton="false"
        txtButton="ViewDetails's button"
        urlName="home"
        :urlParams="{ id: 0 }"
      />
    </div>

    <!---------------------------------------- HELP / MENTIONS / ETC ---------------------------------------->
    <h1>• Content with blocs</h1>
    <div class="columns small-12 medium-10 large-8">
      <ViewBlocs :blocs="blocs" section="kit-ui" />
      <div v-for="(bloc, index) in blocs" :key="'kit-ui-bloc-' + index">
        <ActionText
          class="help-subject"
          :icon="'sofont-arrow-down' + (currentBloc === index ? ' is-up' : '')"
          :isTitle="true"
          :text="bloc.title"
          v-on:action="openBloc(index)"
        />
        <p v-if="currentBloc === index" v-html="bloc.text"></p>
      </div>
    </div>

    <!---------------------------------------- STATEMENTS / REWARDS / ETC ---------------------------------------->
    <h1>• Transactions and rewards</h1>
    <div class="columns small-12 medium-10 large-8">
      <div class="row">
        <div class="columns small-12">
          <h2>Transaction's title</h2>
          <ItemTransaction
            :isOpenned="currentIndex === 0"
            :transaction="transaction"
            @open="openIndex(0)"
          />
          <ItemTransaction
            :isOpenned="currentIndex === 1"
            :transaction="transaction"
            @open="openIndex(1)"
          />
        </div>
      </div>

      <div class="row">
        <div class="columns small-12">
          <h2>Rewards's title</h2>
          <ItemReward
            :isOpenned="currentIndex === 2"
            :expiration="expiration"
            @open="openIndex(2)"
          />
          <ItemReward
            :isOpenned="currentIndex === 3"
            :expiration="expiration"
            @open="openIndex(3)"
          />
        </div>
      </div>
    </div>

    <!---------------------------------------- LOGIN / SIGNUP / ACCOUNT / ETC ---------------------------------------->
    <h1>• Forms, inputs and popups</h1>
    <div class="columns small-12 medium-10 large-8">
      <Popup v-if="showPopup" hasClose @close="togglePopup()">
        <ViewBlocs :blocs="blocs" section="kitui" />
      </Popup>

      <Popup v-if="showPopupOk" hasClose @close="togglePopupOk()">
        <div class="popup-valid-icon">
          <i class="sofont sofont-check"></i>
        </div>
        <p class="popup-valid-text text-center">Validation's text</p>
      </Popup>

      <FormWrapper
        :api="null"
        btn-text="FormWrapper's button"
        :validation="v$.form"
        @submitted="togglePopupOk()"
        @error="() => {}"
      >
        <fieldset>
          <legend>Legend's name</legend>
          <div class="row align-center">
            <InputWrapper isRequired class="columns small-12 medium-7 input-checkbox" :validation-path="'form.gender'">
              <input
                id="genderFemale"
                name="sex"
                type="radio"
                value="female"
                v-model.lazy="v$.form.gender.$model"
              />
              <label for="genderFemale">Radio's choice</label>
              <input
                id="genderMale"
                name="sex"
                type="radio"
                value="male"
                v-model.lazy="v$.form.gender.$model"
              />
              <label for="genderMale">Radio's choice</label>
            </InputWrapper>

            <InputWrapper label="InputWrapper's label" class="columns small-12 medium-7" :validation-path="'form.name'">
              <input
                type="text"
                placeholder="Input text's placeholder"
                v-model.trim.lazy="v$.form.name.$model"
                @input="forceUpper($event, form, 'name')"
                required
              />
            </InputWrapper>

            <InputWrapper label="InputWrapper's label" class="columns small-12 medium-7">
              <input type="text" placeholder="Input text's placeholder" disabled required />
            </InputWrapper>

            <!--InputWrapper
              label="Input date with picker"
              class="columns small-12 medium-7"
              :minAgeRequiredMessage="`${$t('form.error.minAgeRequired.signup')}`"
            >
              <InputDate name="birthdate" v-model.lazy="v$.form.birthdate.$model" required />
            </!InputWrapper-->
            <!--InputWrapper
              label="Input date without picker"
              class="columns small-12 medium-7"
              :minAgeRequiredMessage="`${$t('form.error.minAgeRequired.signup')}`"
            >
              <InputDateSimple name="birthdate" v-model.lazy="v$.form.birthdate2.$model" required />
            </InputWrapper--->

            <!--img class="position-relative" src="https://idata.over-blog.com/4/19/11/95/23/mdg-4543-4551--53-.gif" v-if="$store.state.user && $store.state.user.email == 'demo@sorewards.com'" width="400" height="200"-->
            <InputWrapper
              label="Input Birthdate"
              class="columns small-12 medium-7"
              :minAgeRequiredMessage="`${$t('form.error.minAgeRequired.signup')}`"
              :dateNoExistMessage="`${$t('form.error.dateNoExist')}`"
              :validation-path="'form.birthdate'"
            >
              <InputBirthdate name="birthdate" v-model:birthdate.lazy="v$.form.birthdate2.$model" required/>
            </InputWrapper>

            <InputWrapper label="InputWrapper's label" class="columns small-12 medium-7" :validation-path="'form.country'">
              <select v-model="v$.form.country.$model" required>
                <option
                  v-for="country in countries"
                  :key="country.code"
                  :value="country.code"
                >{{ uppercase(country.label) }}</option>
              </select>
            </InputWrapper>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <div>Legend's name</div>
            <ActionText
            class="btn-edit"
              isOnLegend
              :icon="'sofont-edit'"
              text="ActionText legend's text"
              @action="() => {}"
            />
          </legend>
          <div class="row align-center">
            <InputWrapper label="InputWrapper's label" class="columns small-12 medium-7" isRequired :validation-path="'form.mobile'">
              <MobileInput
                :mobileInit="v$.form.mobile.$model"
                required
                @focusMobile="() => {}"
                @updateMobile="() => {}"
              />
            </InputWrapper>

            <InputWrapper class="columns small-8 text-center" :validation-path="'form.code'">
              <CodeSmsInput
                :checkInvalid="false"
                v-model.lazy="v$.form.code.$model"
                @submitCode="() => {}"
              ></CodeSmsInput>
            </InputWrapper>
          </div>

          <div class="row align-center">
            <a class="columns small-12 medium-7 timer" @click="() => {}">
              <span>Timer's text</span>
              <br />
              <span>02:30</span>
            </a>
          </div>

          <div class="row align-center">
            <InputWrapper class="columns small-12 medium-7" label="InputWrapper's label" :validation-path="'form.email'">
              <input
                type="email"
                placeholder="Input email's placeholder"
                v-model="v$.form.email.$model"
                required
              />
            </InputWrapper>

            <InputWrapper class="columns small-12 medium-7" label="InputWrapper's label" :validation-path="'form.password'">
              <InputPassword
                name="password"
                type="password"
                v-model:password.trim.lazy="v$.form.password.$model"
                :disableStrength="true"
                required
              ></InputPassword>
            </InputWrapper>

            <InputWrapper class="columns small-12 medium-7" label="InputWrapper's label" :validation-path="'form.passwordConfirm'">
              <InputPassword
                name="passwordConfirm"
                type="password"
                v-model:password.trim.lazy="v$.form.passwordConfirm.$model"
                :disableStrength="true"
                required
              ></InputPassword>
            </InputWrapper>
          </div>
        </fieldset>

        <fieldset>
          <legend>&nbsp;</legend>
          <div class="row align-center">
            <InputWrapper class="columns small-12 medium-7" label="InputWrapper's label" :validation-path="'form.message'">
              <textarea v-model="v$.form.message.$model" required />
            </InputWrapper>

            <InputWrapper class="columns small-12 medium-7" :validation-path="'form.newsletter'">
              <div class="toogle-input">
                <div class="toogle-item">
                  <label>Toggle's text</label>
                  <Toggle css-colors v-model="v$.form.newsletter.$model" required />
                </div>
              </div>
            </InputWrapper>

            <InputWrapper class="columns small-12 medium-7" :validation-path="'form.zoneId'">
              <select name="zoneId" v-model.lazy="v$.form.zoneId.$model" required>
                <option
                  v-for="zone in zonesId"
                  :key="zone.key"
                  :value="zone.id"
                >{{ zone.id + ' ' + zone.utc }}</option>
              </select>
            </InputWrapper>

            <InputWrapper class="columns small-12 medium-7" :validation-path="'form.locale'">
              <LocalesInput :locale="v$.form.locale.$model" @update="updateLocale" />
            </InputWrapper>

            <InputWrapper class="columns small-12 medium-7 input-checkbox" :validation-path="'form.terms'">
              <input id="terms-input" type="checkbox" v-model="v$.form.terms.$model" required />
              <label for="terms-input">Input's checkbox label</label>
            </InputWrapper>

            <label class="columns small-12 medium-7 text-justify legal-link" >
              *Phasellus aliquet urna lectus, tincidunt aliquam orci malesuada in ut rutrum orci vitae augue cursus,
              <span
                @click="togglePopup()"
              >Link's regular popup</span>
              id elementum <span @click="togglePopupOk">Link's ok popup</span> nulla consectetur…
            </label>
          </div>
        </fieldset>
      </FormWrapper>
    </div>

    <!---------------------------------------- ICONS ---------------------------------------->
    <h1>• Icons, action and url buttons</h1>
    <div class="columns small-12 large-10">
      <ViewSteps :steps="[{ label: 'Step\'s name' }, { label: 'Step\'s name' }]" :activeStep="0"></ViewSteps>
      <div class="row small-up-1 medium-up-2 large-up-2 align-center">
        <ActionText icon="sofont-cross-add" text="ActionText's name" @action="() => {}" />
        <ActionText icon="sofont-download" text="ActionText's name" @action="() => {}" />
      </div>
      <div class="row align-center">
        <RoundIcon background="orange" icon="sofont sofont-account" />
        <RoundIcon background="orange" icon="sofont sofont-add-card" />
        <RoundIcon background="orange" icon="sofont sofont-arrow-back" />
        <RoundIcon background="orange" icon="sofont sofont-arrow-down" />
        <RoundIcon background="orange" icon="sofont sofont-bag" />
        <RoundIcon background="orange" icon="sofont sofont-refund" />
        <RoundIcon background="orange" icon="sofont sofont-card" />
        <RoundIcon background="orange" icon="sofont sofont-check" />
        <RoundIcon background="orange" icon="sofont sofont-computer" />
        <RoundIcon background="orange" icon="sofont sofont-confidential" />
        <RoundIcon background="orange" icon="sofont sofont-cross-add" />
        <RoundIcon background="orange" icon="sofont sofont-cross-close" />
        <RoundIcon background="orange" icon="sofont sofont-disconnection" />
        <RoundIcon background="orange" icon="sofont sofont-documents" />
        <RoundIcon background="orange" icon="sofont sofont-download" />
        <RoundIcon background="orange" icon="sofont sofont-edit" />
        <RoundIcon background="orange" icon="sofont sofont-euros" />
        <RoundIcon background="orange" icon="sofont sofont-heart" />
        <RoundIcon background="orange" icon="sofont sofont-help" />
        <RoundIcon background="orange" icon="sofont sofont-list" />
        <RoundIcon background="orange" icon="sofont sofont-lock" />
        <RoundIcon background="orange" icon="sofont sofont-message" />
        <RoundIcon background="orange" icon="sofont sofont-options" />
        <RoundIcon icon="sofont sofont-documents" />
        <RoundIcon icon="sofont sofont-confidential" />
        <RoundIcon icon="sofont sofont-disconnection" />
      </div>
      <div class="row small-up-1 medium-up-2 large-up-2 align-center">
        <div class="columns">
          <BtnUrlAction
            :infos="{
                background: 'pink',
                icon: 'sofont sofont-heart',
                text: 'BtnUrlAction\'s text',
                route: {
                  name: '',
                },
              }"
          />
          <BtnUrlAction
            :infos="{
                background: 'orange',
                icon: 'sofont sofont-computer',
                text: 'BtnUrlAction\'s text',
                route: {
                  name: '',
                },
              }"
          />
          <BtnUrlAction
            :infos="{
                background: 'orange',
                icon: 'sofont sofont-bag',
                text: 'BtnUrlAction\'s text',
                route: {
                  name: '',
                },
              }"
          />
        </div>
        <div class="columns">
          <BtnUrlAction
            :infos="{
                icon: 'sofont sofont-list',
                text: 'BtnUrlAction\'s text',
                subText: 'Solde <b>100 €</b>',
                route: {
                  name: '',
                },
              }"
          />
          <BtnUrlAction
            :infos="{
                icon: 'sofont sofont-euros',
                text: 'BtnUrlAction\'s text',
                notif: 'BtnUrlAction\'s notification',
                route: {
                  name: '',
                },
              }"
          />
          <BtnUrlAction
            :infos="{
                icon: 'sofont sofont-add-card',
                text: 'BtnUrlAction\'s text',
                route: {
                  name: '',
                },
              }"
            :disabled="true"
          />
        </div>
      </div>
    </div>

    <!---------------------------------------- CARD BUY ---------------------------------------->
    <h1>• Login image and some page components</h1>
    <div class="columns small-12 large-10">
      <div class="image"></div>
    </div>

    <div class="columns small-12 medium-10 large-8">
      <!-- Card image -->
      <CardItem class="active text-center" :card="card" :withDetails="cardShown" />

      <!-- Enable/Disable card details -->
      <div class="text-center">
        <button
          class="button"
          @click="toggleCardDetails"
        >{{ cardShown ? "Hide card" : "Show card" }}</button>
      </div>

      <!-- Tabs -->
      <div class="row small-align-center">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="columns small-6 medium-3 shop-tab"
          :class="{ 'active': activeItem === index }"
          @click.prevent="setActive(index)"
        >{{ item }}</div>
      </div>

      <!-- Shop items -->
      <div v-if="activeItem === '0'" class="row small-up-1 medium-up-3 large-up-3 shop-items">
        <div v-for="(shop, index) in shops" :key="index" class="column shop-item">
          <div class="shop-image">
            <a :href="shop.url" target="_blank">
              <div class="shop-icon" :style="{'background-image': 'url(' + shop.logoUrl + ')'}"></div>
              <img :src="shop.bannerUrl" />
            </a>
          </div>
          <div class="shop-links">
            <a class="shop-discover" :href="shop.url" target="_blank">Discover's button</a>
            <a v-if="shop.actionUrl" class="shop-donate" :href="shop.actionUrl" target="_blank">
              <div class="shop-donate-icon">
                <i class="sofont sofont-heart" />
              </div>
              <div>Donate's button</div>
            </a>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="stepper">
          <div class="stepper-circle">1</div>
          <div class="stepper-content">Stepper's content</div>
        </div>
        <div class="row align-center">
          <div class="columns small-12 medium-7 stepper final">
            <div class="stepper-circle">
              <i class="sofont sofont-check" />
            </div>
            <b>
              <div class="stepper-content">Stepper's final content</div>
            </b>
            <div class="stepper-box">
              <div>
                <b>Stepper finalBox's title</b>
              </div>
              <div>Stepper finalBox's content</div>
              <router-link class="button" :to="{ name: '' }">Stepper finalBox's button</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./kit-ui.js"></script>
