import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import store from "@/store"
import i18n from '@/helpers/i18n'

export default {
  name: 'TCOS',
  components: {
    ViewBlocs,
    ViewHeader
  },
  props: {
    // Retrieve specific card's TCOS, can be null if general GCU called
    cards: {type: Array, defaut: () => null}
  },
  computed: {
    activeCard() {
      /*console.log( this.$route.params)
      function filterCardByCardSelectedId(card, cardId) {
        return card.id === parseInt(cardId);
      }

      let cardsFiltered = store.state.cards.filter(card => filterCardByCardSelectedId(card, this.$route.params.id));

      return cardsFiltered && cardsFiltered.length > 0 ? cardsFiltered[0] : null;*/
      return store.state.currentCard
    },
    activeCardId() {
      return this.$route.params.id
    },
    tos() {
      return this.activeCard ? JSON.parse(this.activeCard.program.tos)[this.$i18n.locale] : []
    }
  },
  data() {
    return {
      store: this.$store.state,
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */
  },
  methods: {}
}
