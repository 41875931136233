import {required} from '@vuelidate/validators'

import ActionText from "@/components/action-text/action-text.vue"
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputBirthdate from '@/components/input-birthdate/input-birthdate.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import Popup from '@/components/popup/popup.vue'
import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import parameters from '@/helpers/parameters'
import {validName, minAgeRequired, dateNoExist} from '@/helpers/vuelidate'
import {saveUserInformations} from '@/store/api'
import {helpers} from '@vuelidate/validators'
import {validateTin} from '../../../helpers/vuelidate'
import {useVuelidate} from '@vuelidate/core'

export default {
  name: 'Informations',
  components: {
    ActionText,
    FormWrapper,
    InputWrapper,
    InputBirthdate,
    Popup,
    ViewBlocs,
    ViewDetails,
    ViewHeader
  },
  props: {},
  computed: {
    user() {
      return this.$store.state.user
    },
    tinSupported() {
      return this.user.taxeIdentificationNumberSupport !== 'NOT_SUPPORTED' && this.user.taxeIdentificationNumberManagement === "MANAGER"
    }
  },
  filters: {
    uppercase: function (value) {
      if (!value) {
        return ''
      }
      return value.toString().toUpperCase()
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      saveUserInformations,
      countries: parameters.countries,
      form: {
        gender: this.$store.state.user?.gender,
        firstname: this.$store.state.user?.firstname,
        lastname: this.$store.state.user?.lastname,
        country: this.$store.state.user?.country,
        birthdate: this.$store.state.user?.birthdate,
        //taxeIdentificationNumber: this.$store.state.user?.taxeIdentificationNumber
      },
      isModified: false,
      showForm: false,
      showPolitics: false,
      showPopupOk: false,
      tinPattern: null,

    }
  },
  validations: {

    form: {
      firstname: {
        required,
        validName: validName(),
        $lazy: true
      },
      lastname: {
        required,
        validName: validName(),
        $lazy: true
      },
      birthdate: {
        required,
        minAgeRequired: minAgeRequired(15),
        dateNoExist: dateNoExist(),
        $lazy: true
      },
      country: {
        required,
        $lazy: true
      },
      gender: {
        required,
        $lazy: true
      },
      /* taxeIdentificationNumber : {
          validateTin
        }*/


    }


  },
  created: function () {
  },
  mounted: function () {
    console.log(this.user)
    console.log('TIN SUPPORTED ? ', this.tinSupported)
    //this.updateTinPattern();
  },
  beforeUnmount: function () {
  },
  methods: {
    forceUpper(e, obj, prop) {
      const start = e.target.selectionStart
      e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
      if (obj[prop])
        obj[prop] = e.target.value
      e.target.setSelectionRange(start, start)
    },
    toggleForm() {
      this.showForm = !this.showForm
      if (!this.showForm) {
        this.isModified = false
        this.form = {
          gender: this.$store.state.user?.gender,
          firstname: this.$store.state.user?.firstname,
          lastname: this.$store.state.user?.lastname,
          country: this.$store.state.user?.country,
          birthdate: this.$store.state.user?.birthdate,
        }
      }
    },
    togglePolitics() {
      this.showPolitics = !this.showPolitics
    },
    openPopupOk() {
      this.showPopupOk = true
    },
    closePopupOk() {
      this.showPopupOk = false
    },
    update() {
      this.isModified = true
    },
    async onSubmitted() {
      // this.toggleForm()
      // this.openPopupOk()
    },
    onError(error) {
      console.error('[ERROR]', error)
    },
    uppercase(str) {
      return str.toUpperCase();
    }

    //TO UPDATE WITH API SOON
    /*updateTinPattern(){
      console.log('update tin pattern', this.form.country)
      this.tinPattern = /([A-Z])\w+/;
    },*/

  }
}
