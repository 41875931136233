export default {
  name: 'ViewDetails',
  props: {
    subtitle: {
      type: String,
      default: "",
      required: false
    },
    content: {
      type: String,
      default: ""
    },
    disabledButton: {
      type: Boolean,
      default: false
    },
    txtButton: {
      type: String,
      default: ""
    },
    urlName: {
      type: String,
      default: ""
    },
    urlParams: {
      type: Object,
      default: () => { }
    },
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
