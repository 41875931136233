<template>
  <div class="code-sms-input">
    <!--masked-input
      type="text"
      class="code-input"
      :class="{'is-invalid': checkInvalid && invalidNums[0]}"
      v-model.lazy="v$.form.num_0.$model"
      mask="1"
      ref="num_0"
      @input="updateCodeSMS(0)"
    />
    <masked-input
      type="text"
      class="code-input"
      :class="{'is-invalid': checkInvalid && invalidNums[1]}"
      v-model.lazy="v$.form.num_1.$model"
      mask="1"
      ref="num_1"
      @input="updateCodeSMS(1)"
    />
    <masked-input
      type="text"
      class="code-input"
      :class="{'is-invalid': checkInvalid && invalidNums[2]}"
      v-model.lazy="v$.form.num_2.$model"
      mask="1"
      ref="num_2"
      @input="updateCodeSMS(2)"
    />
    <masked-input
      type="text"
      class="code-input"
      :class="{'is-invalid': checkInvalid && invalidNums[3]}"
      v-model.lazy="v$.form.num_3.$model"
      mask="1"
      ref="num_3"
      @input="updateCodeSMS(3)"
    /-->
     <input
      type="text"
      class="code-input"
      :class="{'is-invalid': checkInvalid || invalidNums[0]}"
      v-model.lazy="v$.form.num_0.$model"
      ref="num_0"
      maxlength="1"
      inputmode="numeric"
      pattern="[0-9]{1}"
      @input="updateCodeSMS($event, 0)"
      placeholder= ''
    />
    <input
      type="text"
      class="code-input"
      :class="{'is-invalid': checkInvalid || invalidNums[1]}"
      v-model.lazy="v$.form.num_1.$model"
      inputmode="numeric"
      pattern="[0-9]{1}"
      ref="num_1"
      maxlength="1"
      @input="updateCodeSMS($event, 1)"
    />
    <input
      type="text"
      class="code-input"
      :class="{'is-invalid': checkInvalid || invalidNums[2]}"
      v-model.lazy="v$.form.num_2.$model"
      inputmode="numeric"
      pattern="[0-9]{1}"
      ref="num_2"
      maxlength="1"
      @input="updateCodeSMS($event, 2)"
      placeholder= ''
    />
    <input
      type="text"
      class="code-input"
      :class="{'is-invalid': checkInvalid || invalidNums[3]}"
      v-model.lazy="v$.form.num_3.$model"
      inputmode="numeric"
      pattern="[0-9]{1}"
      ref="num_3"
      maxlength="1"
      @input="updateCodeSMS($event, 3)"
      placeholder= ''
    />
  </div>
</template>

<script src="./code-sms-input.js"></script>
<style scoped lang="scss" src="./code-sms-input.scss"></style>
