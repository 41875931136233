export default {
  name: 'External',
  components: {},
  props: {},
  computed: {
    urlDecoded () {
      console.log('URL DECODED')
      return decodeURIComponent(this.$route.params.urlencoded) + '?jwt=' + this.$store.state.token
    }
  },
  data () {
    return {
      height: window.innerHeight,
    }
  },
  created: function () {},
  mounted: function () {
    window.addEventListener('message', this.resizeIframe, false)
  },
  beforeUnmount: function () {
    window.removeEventListener('message', this.resizeIframe, false)
  },
  methods: {
    resizeIframe (e) {
      this.height = e.data
    },
  }
}
