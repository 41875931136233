export default {
  name: 'ItemReward',
  components: {},
  props: {
    expiration: {
      type: Object,
      default: () => null,
      required: true
    },
    isOpenned: {
      type: Boolean,
      default: false
    },
    open: {
      type: Function,
      default: () => { }
    },
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
