<template>
  <div class="mentions row align-center">
    <ViewHeader :hasBack="true" :title="$t('mentions.title')" />
    <div class="columns small-12 medium-10 large-8">
      <ViewBlocs :blocs="$tm('mentions.blocs')" section="mentions" />
    </div>
  </div>
</template>

<script src="./mentions.js"></script>
