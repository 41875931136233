<template>
  <button
    :disabled="disabled || currentState!=='idle'"
    class="button"
    :class="currentClass"
    :type="type"
    @click="$emit('click', $event)"
  >
    <div v-if="currentState==='loading'">
      <Spinner :alt="$t('form.loading')" />
    </div>
    <div v-else-if="currentState==='success'">
      <i class="sofont sofont-ok"></i>
      {{ $t('form.success') }}
    </div>
    <div v-else-if="currentState==='error'">
      <i class="sofont sofont-attention-alt"></i>
      {{ $t('form.error.main') }}
    </div>
    <div v-else>{{ text }}</div>
  </button>
</template>

<script src="./btn-multi-states.js"></script>
<style scoped lang="scss" src="./btn-multi-states.scss"></style>
