import Spinner from '@/components/spinner/spinner.vue'
import ViewHeader from "@/components/view-header/view-header.vue"
import SignUpCreation from '@/views/signup/creation/creation.vue'
import SignUpModification from '@/views/signup/modification/modification.vue'
import SignUpLinkExpired from '@/views/signup/link-expired/link-expired.vue'

import store from '@/store'
import { checkInvitationCode } from '@/store/api'

export default {
  name: 'Signup',
  components: {
    SignUpCreation,
    SignUpModification,
    SignUpLinkExpired,
    Spinner,
    ViewHeader,
  },
  props: {},
  data () {
    return {
      isModification: false,
      isLinkExpired: false,
      loadLoading: true,
      user: null,
    }
  },
  computed: {},
  created: function () {
    this.checkInvitation()
  },
  methods: {
    async checkInvitation () {
      console.log('checkinvitation')
      try {

        const res = await checkInvitationCode({ code: this.$route.params.code })
        console.log('[SIGNUP] try response ', res)
        this.isModification = (res.data.activationMail && res.data.email)
        console.log('[SIGNUP] try check invitation, isModification ', this.isModification)
        this.user = {
          activationMail: res.data.activationMail,
          firstname: res.data.firstname,
          lastname: res.data.lastname,
          gender: res.data.gender,
          mobile: res.data.mobile,
          country: res.data.country,
          birthdate: res.data.birthdate,
          email: res.data.email,
          newsletter: res.data.newsletter,
          // Data user not modified
          address: res.data.address,
          city: res.data.address,
          complement: res.data.complement,
          locale: res.data.locale || navigator.language,
          zipCode: res.data.zipCode,
          zoneId: res.data.zoneId || Intl.DateTimeFormat().resolvedOptions().timeZone,
        }

        //FOR TESTING ONLY WITHOUT CODE
        /* this.user = {
          activationMail: '',
          firstname: '',
          lastname: '',
          gender: '',
          mobile: '',
          country: 'fr',
          birthdate: undefined,
          email: '',
          newsletter: false,
          // Data user not modified
          address: '',
          city: '',
          complement: '',
          locale: navigator.language,
          zipCode: '',
          zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }*/


        store.commit('user', this.user)
      } catch (error) {
        console.error('[ERROR in signup verification]', error.response.data.message, error.response)
        if (error.response.data.status === 404 || error.response.data.status === 401) {
          this.isLinkExpired = true
          if (!this.$route.query.action) this.$router.push('/login')
        }
      } finally {
        console.log('[SIGNUP] finally catch invitation')
        this.loadLoading = false
      }
    },
  }
}
