//import MaskedInput from 'vue-masked-input'
import { useVuelidate } from '@vuelidate/core'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
export default {
  name: 'CodeSmsInput',
  components: { InputWrapper, /*MaskedInput*/ },
  props: {
    checkInvalid: {
      type: Boolean,
      default: false,
      required: true
    },

  },
  data () {
    return {
      codeSms: 'null',
      v$: useVuelidate(),
      form: {
        num_0: '',
        num_1: '',
        num_2: '',
        num_3: '',
        codeSms: ""
      },
      invalidNums: [false, false, false, false]
    }
  },
  validations: {
    form: {
      num_0: {},
      num_1: {},
      num_2: {},
      num_3: {}
    }
  },

  computed: {
    isValid(){
      console.log('is valid ? ', this.invalidNums, !this.invalidNums.includes(true))
      return  !this.invalidNums.includes(true)
    }
  },
  emits: ['update:codeSms', 'submitCode'],

  methods: {
    updateCodeSMS (evt, ref) {
      console.log('update code SMS ',parseInt(evt.target.value), ref )

      this.form['num_'+ref] = parseInt(evt.target.value)
      // Concat all inputs istring
      this.codeSms = this.form.num_0 +
        this.form.num_1 +
        this.form.num_2 +
        this.form.num_3

      this.invalidNums[0] = this.form.num_0.toString().length && isNaN(this.form.num_0) ? true : false
      this.invalidNums[1] = this.form.num_1.toString().length && isNaN(this.form.num_1) ? true : false
      this.invalidNums[2] = this.form.num_2.toString().length && isNaN(this.form.num_2) ? true : false
      this.invalidNums[3] = this.form.num_3.toString().length && isNaN(this.form.num_3) ? true : false

      console.log('code SmS', this.codeSms,  this.isValid)

      if (!isNaN(this.codeSms) && this.codeSms.toString().length === 4) {
        this.$emit('update:codeSms', this.codeSms)
        // If the last number is written, then submit
        if (ref === 3 && this.isValid) {
          console.log('submit', this.codeSms)
          this.$emit('submitCode', this.codeSms)
        }
      }

      // Focus next if this one is complete
      const currentRef = this.form['num_' + ref]
      const currentChar = currentRef === '_' ? '' : currentRef.toString()
      const nextInput = this.$refs['num_' + (ref + 1)]

      if (currentChar.length === 1 && nextInput) {
        nextInput.focus()
      }
    },
    changeFocus (ref) {
      this.$refs[ref][0].focus()
    }
  }
}
