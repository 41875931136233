import RoundIcon from "@/components/round-icon/round-icon.vue"

export default {
  name: 'BtnUrlAction',
  components: { RoundIcon },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    infos: {
      type: Object,
      default: {}, // 'Faire un don' etc
      required: true
    },
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
