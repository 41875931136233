export default {
  name: 'AppFooter',
  props: {},
  data () {
    return {
    }
  },
  created: function () { },
  mounted: function () { /* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {},
  computed: {
    currentYear () {
      return (new Date()).getFullYear()
    },
    disconnected () {
      if (this.$store.state.token) {
        return !(this.$store.state.user ? this.$store.state.user.email !== undefined : false)
      }
      return true
    },
  }
}
