<template>
  <div>
    <Popup v-if="showPopup" hasClose @close="closePopup()">
      <p class="text-center">
        {{ $t('forms.email.confirmInfo') }}
        <br/>
        <br/>
        <b>{{ $t('forms.email.confirmPassword') }}</b>
      </p>
      <FormPasswordSecure :api="saveUserEmail" :tempForm="form" @submit="onSubmitted"/>
    </Popup>

    <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
      <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
      <p class="popup-valid-text text-center">
        {{ $t('forms.email.sendOk') }}
        <br/>
        {{ $t('forms.email.sendCheck') }}
      </p>
    </Popup>

    <FormWrapper
      forceId="email"
      :api="null"
      :btnClass="showForm ? '' : 'is-hidden'"
      :btnDisabled="!isModified"
      :btnText="`${$t('form.save')}`"
      :validation="v$.form"
      @cancel="this.toggleForm"
      @submitted="openPopup()"
      @error="onError"
    >
      <fieldset>
        <legend>
          <div>{{ $t('forms.email.title') }}</div>
          <ActionText
            :class="showForm ? 'btn-cancel' : 'btn-edit'"
            isOnLegend
            :icon="showForm ? 'sofont-cross-close' : 'sofont-edit'"
            :text="showForm ? $t('form.cancel') : $t('form.edit')"
            @action="toggleForm()"
          />
        </legend>
        <div class="row align-center">
          <p
            v-if="activationMail"
            class="columns small-12 medium-7 text-center"
          >{{ $t('forms.email.activation', {value: activationMail}) }}</p>
          <InputWrapper class="columns small-12 medium-7"
                        :label="`${$t('form.email')}`"
                        :validation-path="'form.email'"
                        :isInvalid="v$.form.email.$invalid"
          >
            <input
              name="email"
              type="email"
              :placeholder="$t('form.email')"
              v-model="v$.form.email.$model"
              :disabled="!showForm"
              required
              @focus="update()"
            />
          </InputWrapper>
        </div>
      </fieldset>
    </FormWrapper>
  </div>
</template>

<script src="./form-email.js"></script>
