<template>
  <FormWrapper
    forceId="forgotpasswordsmsvalidate"
    :api="forgotPasswordSmsValidate.bind(this, this.form)"
    :btn-text="`${$t('forgottenPassword.stepSms.btnText')}`"
    :customErrors="errors"
    :validation="v$.form"
    @submitted="onSubmitted"
    @error="onError"
  >
    <fieldset>
      <div class="row align-center">
        <InputWrapper class="columns small-12 medium-7 text-center" :validation-path="'form.codeSms'">
          <CodeSmsInput
            :checkInvalid="invalidCodeSMS"
            v-model:codeSms.lazy="v$.form.codeSms.$model"
            @submitCode="submitCode"
          ></CodeSmsInput>
        </InputWrapper>
      </div>
    </fieldset>
    <fieldset>
      <div class="row align-center">
        <a
          class="columns small-12 medium-7 timer"
          :class="{ 'disabled' : countDown !== 0 }"
          :disabled="countDown !== 0"
          @click="onSendSms"
        >
          <span>{{ $t("forms.smsSecure.timer") }}</span>
          <br />
          <span v-if="countDown > 0">{{ formatedCountdown }}</span>
        </a>
          <a class="columns small-12 medium-7 timer" @click="onNotReceived">
          <span>{{ $t("forgottenPassword.stepSms.notReceived") }}</span>
        </a>
      </div>
    </fieldset>
  </FormWrapper>
</template>

<script src="./form-sms-secure.js"></script>
