import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import Popup from "@/components/popup/popup.vue"
import FormWrapper from "@/components/form-wrapper/form-wrapper.vue"
import InputWrapper from "@/components/input-wrapper/input-wrapper.vue"
import { required } from "@vuelidate/validators"
import { digitOnly, acceptTerms } from "@/helpers/vuelidate"
import { addBankInfo } from "@/store/api"
import { useVuelidate } from '@vuelidate/core'
export default {
  name: "RefundIban",
  components: { GetterWrapper, ViewHeader, FormWrapper, InputWrapper, Popup },
  data () {
    return {
      v$: useVuelidate(),
      card: null,
      showPopupOk: false,
      addBankInfo,
      submitted: false,
      formRib: {
        cardId: this.$route.params.id,
        iban: '',
        bic: '',
        address: '',
        cp: '',
        city: '',
        honorCert: '',
      },
    }
  },
  validations: {
    formRib: {
      cardId: { required },
      iban: { required },
      bic: { required },
      address: { required },
      cp: { required, digitOnly: digitOnly() },
      city: { required },
      honorCert: { required, acceptTerms: acceptTerms() },
    },
  },
  created: function () { },
  mounted: function () { },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {
    onSubmitted (card) {
      this.showPopupOk = true
    },
    onError (error) {
      console.error('[ERROR]', error)
    },
    closePopupOk () {
      this.showPopupOk = false
      this.$router.push({ name: 'card-item', params: { id: this.$route.params.id } })
    },
  },
}
