<template>
  <svg class="spinner" :alt="$t('form.loading')" viewBox="0 0 50 50">
    <circle class="spinner__path-background" cx="25" cy="25" r="20" fill="none" stroke-width="5" />
    <g class="spinner__path-container">
      <circle class="spinner__path" cx="25" cy="25" r="20" fill="none" stroke-width="5" />
    </g>
  </svg>
</template>

<!-- <script src="./spinner.js"></script> -->
<style scoped lang="scss" src="./spinner.scss"></style>
