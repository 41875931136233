import {required, sameAs} from '@vuelidate/validators'

import ActionText from "@/components/action-text/action-text.vue"
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputPassword from '@/components/input-password/input-password.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import Popup from "@/components/popup/popup.vue"
import {goodPassword} from '@/helpers/vuelidate'
import {useVuelidate} from '@vuelidate/core'
import {logout, saveUserPassword} from '@/store/api'

import FormPasswordSecure from './form-password-secure.vue'

export default {
  name: 'FormPassword',
  components: {ActionText, FormPasswordSecure, FormWrapper, InputPassword, InputWrapper, Popup},
  props: {},
  data() {
    return {
      v$: useVuelidate(),
      errors: [
        {
          response: {
            status: 404,
            data: {
              message: this.$t('errors.passwordIncorrect'),
            }
          }
        },
      ],
      saveUserPassword,
      form: {
        newPassword: '',
        confirmedPassword: '',
      },
      isModified: false,
      showForm: false,
      showPopup: false,
      showPopupOk: false
    }
  },
  validations() {
    return {
      form: {
        newPassword: {
          required,
          goodPassword: goodPassword(),
          $lazy: true
        },
        confirmedPassword: {
          required,
          sameAsPassword: sameAs(this.form.newPassword),
          $lazy: true
        },
      }
    }

  },
  mounted() {
    console.log('$v', this.v$)
  },
  updated() {
    console.log('this.$v', this.v$.$errors)
  },
  computed: {},
  methods: {
    toggleForm() {
      this.showForm = !this.showForm
      if (!this.showForm) {
        this.isModified = false
        this.form = {
          newPassword: '',
          confirmedPassword: '',
        }
      }
    },
    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
      this.toggleForm()
    },
    openPopupOk() {
      this.showPopup = false
      this.showPopupOk = true
    },
    async closePopupOk() {
      this.showPopupOk = false
      this.toggleForm()
      try {
        await logout()
      } catch (error) {
        console.error('[ERROR]', error)
      } finally {
        this.$router.push({name: 'login'})
      }
    },
    update(value) {
      console.log('update ', value)
      this.isModified = true
    },
    async onSubmitted() {
      this.openPopupOk()
    },
    onError(error) {
      console.error('[ERROR]', error)
    },
  }
}
