import router from "@/router"
import RoundIcon from "@/components/round-icon/round-icon.vue"
export default {
  name: 'ViewHeader',
  components: { RoundIcon },
  props: {
    backgroundIcon: {
      type: String,
      default: "",
      required: false
    },
    icon: {
      type: String,
      default: null,
      required: false
    },
    hasBack: {
      type: Boolean,
      default: false
    },
    urlBack: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "",
      required: true
    },
    subtitle: {
      type: String,
      default: ""
    },
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    back () {
      if (this.urlBack) {
        router.push({ name: this.urlBack })
      } else {
        this.$router.go(-1)
      }
    },
  }
}
