<template>
  <div class="error-banner">
    <div class="banner-message">
      <b>[{{ $t('errors.bannerStatus') }}]</b>
      {{ $t('errors.bannerMessage') }}
    </div>
    <div class="banner-close-icon" @click="close">
      <i class="sofont sofont-cross-close"></i>
    </div>
  </div>
</template>

<script src="./error-banner.js"></script>
<style lang="scss" src="./error-banner.scss"></style>
