<template>
  <Popup class="simulate-pop" v-if="show" hasClose @close="$emit('close')">
    <ViewHeader
      class="large-12"
      :title="$t('card.actions.simulatePayment')"
    />
    <FormWrapper
        :api="pay.bind(this, this.form)"
        :btn-text="$t('forms.simulatePay.pay')"
        :validation="v$.form"
        @submitted="pay"
        @error="onError"
      >
        <fieldset>
          <div class="row align-left">

            <InputWrapper
              class="columns small-12 medium-6"
              :label="$t('forms.simulatePay.amount')"
              :validation-path="'form.amount'"
            >
              <input name="amount" type="number" v-model="v$.form.amount.$model"  />
            </InputWrapper>

            <InputWrapper
              class="columns small-12 medium-6"
              :label="$t('forms.simulatePay.currencies')"
              :validation-path="'form.currency'"
            >
              <select name="currency" id="currencies" >
                <option v-for="curr, key, index in currencies" :key="'currency-'+index" :selected="key === form.currency" :value="key" >{{ curr.symbol }}&nbsp;&nbsp;({{ curr.name }})</option>
              </select>
            </InputWrapper>
            <InputWrapper
              class="columns small-12 medium-6"
              :label="$t('forms.simulatePay.merchantLabel')"
            >
              <input name="merchant-label" type="text" v-model="form.merchantLabel"  />
            </InputWrapper>
            <InputWrapper
              class="columns small-12 medium-6"
              :label="$t('forms.simulatePay.merchantId')"
            >
              <input name="merchant-id" type="text" v-model="form.merchantId"  />
            </InputWrapper>
            <InputWrapper
              class="columns small-12 medium-6"
              :label="$t('forms.simulatePay.mcc')"
            >
              <input name="mcc" type="text" v-model="form.mcc"  />
            </InputWrapper>


          </div>
        </fieldset>
      </FormWrapper>


        <!--button @click="pay">Payer</!button-->
      </Popup>
</template>

<script>

import ViewHeader from "@/components/view-header/view-header.vue"
import FormWrapper from "@/components/form-wrapper/form-wrapper.vue"
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import Popup from '../popup/popup.vue'
import { simulatePayment } from '../../store/api'
import currencies from '@/helpers/currencies.json'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'simulatePayPopup',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    Popup,
    ViewHeader,
    FormWrapper,
    InputWrapper
  },
  data(){
    return {
      v$: useVuelidate(),
      currencies,
      form: {
        mcc: "",
        merchantId: "",
        amount: null,
        merchantLabel: "" ,
        currency: "EUR"
      },
      /*errors: [
        {response : {status : 400, data: {
           error: "autre", message : "un truc"}}, message : 'rien' }
    ]*/
    }
  },
  emits: ['close'],
  validations: {
    form: {
      amount: { required },
      currency: {required},
    },
  },
  computed: {
  },
  mounted(){
    console.log('SIMULATE pop mounted', this.currencies, this.form.currency)
  },
  methods: {
    onError (error) {
      console.error('[ERROR]', error)
    },
    async pay(){
      // console.log('pay')
      let response = await simulatePayment({}, {mock: true})
      console.log('response', response)

      /*if(response.status == 200){
        if(response.data.status === 'accepted'){

        }
      } else {
        errors.push(response)
      }*/

      return response
    }
  }
}
</script>

<style lang="scss" scoped>

.simulate-pop{
  width: 100%;
  height: 100%;
  max-width: 100%;
}
</style>
