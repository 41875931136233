<template>
  <div class="text-center position-relative" id="card-rapyd-container">
    <Spinner v-if="loading" />
    <div style="overflow: hidden" id="rapyd-checkout"></div>
    <div id="rapyd-mask"></div>
  </div>
</template>

<script src="./card-rapyd-show.js"></script>
<style lang="scss" scoped>

#card-rapyd-container{
    //margin-right: -45px;
   //margin-left: -30px;
   margin-left: -10%;
  // margin-right: -10%;
   width: 120%;

}
.spinner{
  position: absolute;
  top: 40%;
  left: 50%;
  transform:translate(-50%, -50%);
}

#rapyd-mask{
  display: none;
  position: absolute;
  z-index: 2;
  background: #FFF;
  width: 20px;
  height: 100%;
  right: 0;
  top: 0;
}

#rapyd-checkout{
  position: relative;
  height: 390px;
  margin-top: -100px;
}

@media screen and (min-width: 360px){

  #rapyd-checkout{
    height: 360px;
    margin-top: -60px;
  }
}

@media screen and (min-width: 414px){
  #card-rapyd-container{
    margin-right: -45px;
    margin-left: -20px;
  }
}

@media screen and (min-width: 600px){
  #rapyd-checkout{
    height: 350px;
    margin-top: -80px;
  }
   #card-rapyd-container{
    margin-right: auto;
    margin-left: auto;
    width: auto;
  }

}

@media screen and (min-width: 640px){
  #rapyd-checkout{
    height: 350px;
    margin-top: -80px;
  }

}

@media (orientation: landscape){
  #rapyd-mask{
    display: block;
  }
  #card-rapyd-container{
    margin-right: auto;
    margin-left: auto;
    width: auto;
  }

}
</style>
