<template>
  <div class="locales-input">
    <v-select
      label="label"
      name="locale"
      required
      v-model="locLabel"
      :clearable="false"
      :options="locales"
      :reduce="(option) => option.key"
      @option:selected="$emit('update', locLabel)"
    >
      <template #selected-option="{ key, flag }">
        <img :src="flag" width="40" />
        <label>{{ key }}</label>
      </template>
      <template #option="{ key, flag }">
        <img :src="flag" width="40" />
        <label>{{ key }}</label>
      </template>
    </v-select>
    <label>{{ localeLabel }}</label>
  </div>
</template>

<script src="./locales-input.js"></script>
<style scoped lang="scss" src="./locales-input.scss"></style>
