<template>
  <!-- Request cards data -->
  <GetterWrapper id="statement" :api-load="apiLoad" @data="onData">
    <div class="statement row align-center">
      <ViewHeader
        :hasBack="true"
        urlBack="card-item"
        :title="$t('card.actions.statement')"
         icon="sofont-list"
      />
      <div class="columns small-12 medium-10 large-8">
        <ViewDetails
        :subtitle="card.program ? $t('card.card',  {type:$t('card.type-'+card.program.type),value : card.program.cardName}) : ''"
          :content="$t('card.amount') + '&nbsp;<span>' + $n(card.amount, 'currency') + '</span>'"
          :disabledButton="card.program ? (!card.program.stripeKey || card.amount <= 0) : true"
          :txtButton="$t('card.statement.action')"
          urlName="card-item-balance"
          :urlParams="{ id: card.id }"
        />
        <!-- No transactions -->
        <div
          v-if="(!transactions.current || transactions.current.length === 0) && (!transactions.passed || transactions.passed.length === 0)"
          class="row"
        >
          <div class="columns small-12 text-center">{{ $t("card.statement.empty") }}</div>
        </div>

        <!-- Current transactions -->
        <div v-if="transactions.current && transactions.current.length > 0" class="row">
          <div class="columns small-12">
            <h2>{{ $t("card.statement.current") }}</h2>
            <ItemTransaction
              v-for="(transaction, index) in transactions.current"
              :key="index"
              :isOpenned="indexCurrent === index"
              :transaction="transaction"
              @open="openCurrent(index)"
            />
          </div>
        </div>

        <!-- Passed transactions -->
        <div v-if="transactions.passed && transactions.passed.length > 0" class="row">
          <div class="columns small-12">
            <h2>{{ $t("card.statement.passed") }}</h2>
            <ItemTransaction
              v-for="(transaction, index) in transactions.passed"
              :key="index"
              :isOpenned="indexPassed === index"
              :transaction="transaction"
              @open="openPassed(index)"
            />
          </div>
        </div>

        <!-- Actions texts -->
        <div
          v-if="(transactions.current && transactions.current.length > 0) || (transactions.passed && transactions.passed.length > 0)"
          class="row small-up-1 medium-up-2 large-up-2 align-center"
        >
          <ActionText
            v-if="hasMoreTransactions"
            icon="sofont-cross-add"
            :text="$t('card.statement.more')"
            @action="addTransactions()"
          />
          <!--
          <ActionText
            icon="sofont-download"
            :text="$t('card.statement.download')"
            @action="downloadStatement()"
          />
          -->
        </div>
      </div>
    </div>
  </GetterWrapper>
</template>

<script src="./statement.js"></script>
