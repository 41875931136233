<template>
  <!-- Request cards data -->
  <GetterWrapper id="rewards" :api-load="apiLoad" @data="onData">
    <div class="rewards row align-center">
      <ViewHeader
        :hasBack="true"
        urlBack="card-item"
        :title="$t('card.actions.rewards')"
         icon="sofont-euros"
      />
      <div class="columns small-12 medium-10 large-8">
        <ViewDetails
        :subtitle="card.program ? $t('card.card',  {type:$t('card.type-'+card.program.type),value : card.program.cardName}) : ''"
          :content="$t('card.amount') + '&nbsp;<span>' + $n(card.amount, 'currency') + '</span>'"
          :disabledButton="card.program ? (!card.program.stripeKey || card.amount <= 0) : true"
          :txtButton="$t('card.rewards.action')"
          urlName="card-item-balance"
          :urlParams="{ id: card.id }"
        />
        <!-- No expirations -->
        <div
          v-if="(!expirations || expirations.length === 0)"
          class="row"
        >
          <div class="columns small-12 text-center">{{ $t("card.rewards.empty") }}</div>
        </div>

        <!-- Current expirations -->
        <div v-if="expirations && expirations.length > 0" class="row">
          <div class="columns small-12">
            <h2>{{ $t("card.rewards.current") }}</h2>
            <ItemReward
              v-for="(expiration, index) in expirations"
              :expiration="expiration"
              :key="index"
            />
          </div>
        </div>

        <!-- Passed expirations -->
        <!--div v-if="expirations.passed && expirations.passed.length > 0" class="row">
          <div class="columns small-12">
            <h2>{{ $t("card.rewards.passed") }}</h2>
            <ItemReward
              v-for="(expiration, index) in expirations.passed"
              :expiration="expiration"
              :key="index"
              :isOpenned="indexPassed === index"
              @open="openPassed(index)"
            />
          </div>
        </div-->

        <div v-if="transactions && transactions.length > 0" class="row">
          <div class="columns small-12">
            <h2>{{ $t("card.rewards.transactions") }}</h2>
            <ItemTransaction
              v-for="(transaction, index) in transactions"
              :key="index"
              :isOpenned="indexCurrent === index"
              :transaction="transaction"
              :isRewardPage="true"
              @open="openCurrent(index)"
            />
          </div>
        </div>

        <!-- Actions texts -->
        <div
          v-if="(expirations.current && expirations.current.length > 0) || (expirations.passed && expirations.passed.length > 0)"
          class="row small-up-1 medium-up-2 large-up-2 align-center"
        >
          <ActionText
            v-if="hasMoreExpirations"
            icon="sofont-cross-add"
            :text="$t('card.rewards.more')"
            @action="addExpirations()"
          />
        </div>
      </div>
    </div>
  </GetterWrapper>
</template>

<script src="./rewards.js"></script>
