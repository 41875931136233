import { required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import FormWrapper from "@/components/form-wrapper/form-wrapper.vue";
import InputPassword from "@/components/input-password/input-password.vue";
import InputWrapper from "@/components/input-wrapper/input-wrapper.vue";
import { goodPassword } from "@/helpers/vuelidate";

import { forgotPasswordNewPwdValidate } from "@/store/api";

export default {
  name: "FormPassword",
  components: { FormWrapper, InputPassword, InputWrapper },
  props: {
    email: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      errors: [
        {
          response: {
            status: 404,
            data: {
              message: this.$t("errors.passwordIncorrect"),
            },
          },
        },
      ],
      forgotPasswordNewPwdValidate,
      form: {
        email: this.email,
        password: null,
        passwordConfirm: null,
      },
    };
  },
  emits: ["submitted"],
  validations() {
    return {
      form: {
        password: {
          required,
          goodPassword: goodPassword(),
        },
        passwordConfirm: {
          required,
          sameAsPassword: sameAs(this.form.password),
        },
      },
    };
  },
  computed: {},
  methods: {
    onSubmitted() {
      this.$emit("submitted");
    },
    onError(error) {
      console.error("[ERROR]", error);
    },
  },
};
