import { isProduction, getTheme } from '@/store/api'

export async function injectCSS (themeUrl) {
  // Skip if not in production
  if (!isProduction()) {
    return
  }

  // Reject if no theme
  if (!themeUrl) {
    return Promise.reject(new Error(`Theme is not available : can not inject CSS`))
  }

  // Add link to styesheet in the head (async preload)
  return new Promise((resolve, reject) => {
    const oldLink = document.getElementById('theme-css')
    const link = document.createElement("link")
    link.id = 'theme-css'
    link.type = "text/css"
    link.rel = "stylesheet"
    // link.as ="style"
    link.onload = function () {
      setTimeout(() => {

        // Continue loading
        resolve()

        // Remove last css file if exists
        if (oldLink) {
          oldLink.parentNode.removeChild(oldLink)
        }

      }, 0)

    }
    link.onerror = function (event) {
      var request = (event && event.target && event.target.src) || themeUrl
      var err = new Error("Loading CSS Theme failed.\n(" + request + ")")
      err.code = "CSS_THEME_LOAD_FAILED"
      err.request = request
      reject(err)
    }
    link.href = themeUrl
    document.getElementsByTagName("head")[0].appendChild(link)
  })

}

export async function loadTheme (platform, id) {
  try {
    const platformId = platform.id
    console.log(`[DEBUG] Load theme '${id}' for platform '${platformId}'`)
    const res = await getTheme({ platformId, id })
    if (res && res.data) {
      const theme = res.data
      await injectCSS(theme?.cssUrl)
    }
  } catch (error) {
    console.error(`[ERROR] Loading theme: ${id}`)
    throw error
  }
}

export function parseQuery (queryString) {
  var query = {}
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}
