import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'

import { forgotPasswordEmailSms } from '@/store/api'

export default {
  name: 'FormEmail',
  components: { FormWrapper, InputWrapper },
  props: {
  },
  data () {
    return {
      v$: useVuelidate(),
      errors: [
        {
          response: {
            status: 404,
            data: {
              message: this.$t('errors.emailNotFoundMessage'),
            }
          }
        },
      ],
      forgotPasswordEmailSms,
      form: {
        email: this.$route.params.email
      },
    }
  },
  emits: ['submitted'],
  validations: {
    form: {
      email: { required, email }
    },
  },
  computed: {},
  methods: {
    onSubmitted (data) {
      this.$emit('submitted', { email: this.form.email, mobile: data })
    },
    onError (error) {
      console.error('[ERROR]', error)
    },
  }
}
