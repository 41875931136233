<template>
  <div class="input-wrapper" :class="{ required: required, disabled: disabled }">
    <!-- Display label -->
    <label v-if="label" :for="id">
      <span>{{ label }}</span>
      <span v-if="required" class="required" :title="$t('form.error.required')">*</span>
      <button
        v-if="help"
        type="button"
        class="button button-help"
        :title="displayHelp ? $t('form.help.hide'):$t('form.help.show')"
        :aria-expanded="displayHelp"
        :aria-controls="`help_${uuid}`"
        @click="displayHelp = !displayHelp"
      >?</button>
    </label>

    <!-- Display help -->
    <label v-show="help && displayHelp" :id="`help_${uuid}`" role="alert" v-html="help" />

    <!-- Build container for inputs -->
    <div class="input-container" :class="{'is-invalid': activeErrors.length || isInvalid }">
      <slot />
      <button
        v-if="clearBtn"
        :title="$t('form.clearSelection')"
        type="button"
        class="button button-clear"
        @click="clearSelection"
      >
        <i class="sofont sofont-clear" aria-hidden="true">X</i>
      </button>
    </div>

    <!-- Display errors -->
    <ul v-if="activeErrors.length" role="alert" class="invalid-feedback">
      <li v-for="error of activeErrors" :key="error.type">{{ error.message }}</li>
    </ul>
  </div>
</template>

<script src="./input-wrapper.js"></script>
<style lang="scss" src="./input-wrapper.scss"></style>
