<template>
  <div class="view-details">
    <!--RoundIcon class="detail-icon" :background="backgroundIcon" :icon="icon" /-->
    <h2 v-if="subtitle" class="view-subtitle" v-html="subtitle"></h2>
    <h2 v-if="content" class="detail-content" v-html="content"></h2>

    <router-link v-if="urlName && !disabledButton" :to="{ name: urlName, params: urlParams }">
      <button class="button detail-button">{{ txtButton }}</button>
    </router-link>
  </div>
</template>

<script src="./view-details.js"></script>
<style  lang="scss" src="./view-details.scss"></style>
