<template>
  <FormWrapper
    :api="forgotPasswordEmailSms.bind(this, this.form)"
    :btn-text="`${$t('forgottenPassword.stepEmail.btnText')}`"
    :customErrors="errors"
    :validation="v$.form"
    @submitted="onSubmitted"
    @error="onError"
    :bypassError="true"
  >
    <fieldset>
      <div class="row align-center">
        <InputWrapper class="columns small-12 medium-7" :label="`${$t('form.email')}`" :validation-path="'form.email'">
          <input name="email" type="email" v-model="v$.form.email.$model" required />
        </InputWrapper>
      </div>
    </fieldset>
  </FormWrapper>
</template>

<script src="./form-email.js"></script>
