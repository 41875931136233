<template>
  <div class="item-transaction" :class="{'is-openned' : isOpenned }" @click="$emit('open')">
    <div class="row">
      <div class="column small-12 large-9 transaction-header">
        <i class="sofont sofont-arrow-down is-active" :class="{'is-up' : isOpenned }"></i>
        <span class="transaction-text transaction-title">
          {{ $d(transaction.date, 'date') }}  {{ label }}

        </span>
      </div>
      <div class="column small-12 large-3">
        <div v-if="transaction.sens === 'CREDIT'" class="transaction-text positive">
          <b>+{{ $n(transaction.amount, 'currency') }}</b>
        </div>
        <div v-if="transaction.sens === 'DEBIT'" class="transaction-text negative">
          <b>{{ $n((transaction.amount * -1), 'currency') }}</b>
        </div>
      </div>
    </div>
    <div v-if="isOpenned" class="transaction-addons">
      <i class="sofont sofont-arrow-down is-not-visible"></i>
      <span class="transaction-text">{{ $t("card.statement.name") }} : {{ label }}</span>
      <br />
      <i class="sofont sofont-arrow-down is-not-visible"></i>
      <span class="transaction-text">{{ $t("card.statement.ref") }} : {{ transaction.id.split('-')[0] }}</span>
      <br />
      <div v-if="transaction.sens === 'CREDIT' && (transaction.amount - (transaction.metas.ENTRY_AM_EXP ? transaction.metas.ENTRY_AM_EXP : 0)) > 0 && !isRewardPage" class="row">
        <div class="column small-12 large-9">
        <i class="sofont sofont-arrow-down is-not-visible"></i>
        <span class="transaction-text">{{ $t("card.rewards.expiration") }} <span class="expiration-text">{{ formatDate(transaction.metas.DATE_EXPIR) }}</span></span>
        </div>
        <div class="column small-12 large-3">
          <div class="transaction-text text-right">
          <b>{{ $n((transaction.metas.ENTRY_AM_EXP ? transaction.amount - transaction.metas.ENTRY_AM_EXP : transaction.amount), 'currency') }}</b>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script src="./item-transaction.js"></script>
