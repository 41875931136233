<template>
  <FormWrapper
    forceId="forgotpasswordnewpassword"
    :api="forgotPasswordNewPwdValidate.bind(this, this.form)"
    :btn-text="`${$t('forgottenPassword.stepNewPassword.btnText')}`"
    :customErrors="errors"
    :validation="v$.form"
    @submitted="onSubmitted"
    @error="onError"
  >
    <fieldset>
      <div class="row align-center">
        <InputWrapper
          class="columns small-12 medium-7"
          :label="`${$t('form.password')}` + ' ' + `${$t('form.passwordRecommandation')}`"
          :validation-path="'form.password'"
        >
          <InputPassword
            name="password"
            type="password"
            v-model:password.trim.lazy="v$.form.password.$model"
            :disableStrength="true"
            required
          ></InputPassword>
        </InputWrapper>

        <InputWrapper class="columns small-12 medium-7" :label="`${$t('form.passwordRepeat')}`" :validation-path="'form.passwordConfirm'">
          <InputPassword
            name="passwordConfirm"
            type="password"
            v-model:password.trim.lazy="v$.form.passwordConfirm.$model"
            :disableStrength="true"
            required
          ></InputPassword>
        </InputWrapper>
      </div>
    </fieldset>
  </FormWrapper>
</template>

<script src="./form-password.js"></script>
