export default {
  name: "LogoVisa",
  components: {},
  props: {
    width: {
      type: [Number, String],
      default: 52.799
    },
    height: {
      type: [Number, String],
      default: 16.523
    },
  },
  data () { return {} },
  created () {},
  computed: {},
  methods: {}
}
