<template>
  <!-- Request cards data -->
  <GetterWrapper id="card-detail" :api-load="apiLoad" @data="onData">
    <div
      :class="'card-detail row align-center programType-' + card.program.type"
      v-if="card"
    >
      <ViewHeader
        :title="
          $t('card.hello', { value: store.user ? store.user.firstname : '' })
        "
      />

      <div class="columns small-12 medium-10 large-8">
        <ViewDetails
          :subtitle="$t('card.yours', {type:$t('card.type-'+card.program.type), value: card.program.cardName })"
          :content="
            $t('card.amount') +
            '&nbsp;<span>' +
            $n(card.amount, 'currency') +
            '</span>'
          "
        />
        <!-- Card image -->

        <CardItem
          class="active text-center"
          :card="card"
          :with-details="cardShown"
          v-if="!cardShown"
        />
        <CardRapydShow
          v-else-if="
            card.program.monetician == 'RAPYD' &&
            card.program.cardEnabled == true
          "
          :card="card"
        />
        <CardItemWallester
          v-else-if="
            card.program.monetician == 'WALLESTER' &&
            card.program.cardEnabled == true
          "
          class="active text-center"
          @error="cardShown = false"
          :card="card"
        />

        <div class="text-center">
          <button
            class="button"
            @click="showCardDetails"
            v-if="!cardShown"
            :disabled="
              !isMaintenanceMode &&
              (card.amount === 0 || card.program.cardEnabled === false)
            "
          >
            {{ $t("card.show") }}
          </button>
          <p v-if="card.program.cardEnabled == false">
            {{ $t("card.message_card_disabled") }}
          </p>
        </div>

        <!-- Timer -->
        <div v-if="cardShown" class="card-timer">
          <span>{{ $t("card.timer") }}</span>
          : {{ formatedCountdown }}
        </div>

        <!-- Card actions -->
        <div
          class="card-buttons row small-up-1 medium-up-2 large-up-2 align-start"
        >
          <BtnUrlAction
            id="btn-donate"
            class="columns small-12 medium-6"
            v-if="!card.program.type.startsWith('FL') && !card.program.type.startsWith('CC') && !card.program.type.startsWith('CV')&& !card.program.type.startsWith('NAIS')&& !card.program.type.startsWith('SCO')"
            :infos="{
              background: 'pink',
              icon: 'sofont-heart',
              text: $t('card.actions.donate'),
              route: {
                name: 'card-item-donate',
                params: { id: card.id },
              },
            }"
            :disabled="
              isMaintenanceMode ||
              card.amount == 0 ||
              card.program.cardEnabled == false
            "
          />
          <!--BtnUrlAction
            id="btn-online"
            :infos="{
              background: 'orange',
              icon: 'sofont-computer',
              text: $t('card.actions.online'),
              route: {
                name: 'card-item-online',
                params: { id: card.id }
              },
            }"
          /-->
          <BtnUrlAction
            id="btn-store"
            class="columns small-12 medium-6"
            :infos="{
              background: 'orange',
              icon: 'sofont-bag',
              text: $t('card.actions.store'),
              route: {
                name: 'card-item-store',
                params: { id: card.id },
              },
            }"
            :disabled="
              isMaintenanceMode ||
              card.amount == 0 ||
              card.program.cardEnabled == false
            "
          />
          <BtnUrlAction
            id="btn-balance"
            v-if="
              card.program.stripeKey != null &&
              card.program.stripeKey.length &&
              !card.program.type.startsWith('FL')
            "
            class="columns small-12 medium-6"
            :infos="{
              icon: 'sofont-add-card',
              text: $t('card.actions.balance'),
              route: {
                name: 'card-item-balance',
                params: { id: card.id },
              },
            }"
            :disabled="
              isMaintenanceMode ||
              card.amount == 0 ||
              card.program.cardEnabled == false
            "
          />

          <BtnUrlAction
            id="btn-statement"
            class="columns small-12 medium-6"
            :disabled="isMaintenanceMode"
            :infos="{
              icon: 'sofont-list',
              text: $t('card.actions.statement'),
              //subText: 'Solde <b>' + $n(card.amount, 'currency') + '</b>',
              route: {
                name: 'card-item-statement',
                params: { id: card.id },
              },
            }"
          />

          <BtnUrlAction
            id="btn-rewards"
            class="columns small-12 medium-6"
            :disabled="isMaintenanceMode"
            :infos="{
              icon: 'sofont-euros',
              text: $t('card.actions.rewards'),
              notif: card.notif,
              route: {
                name: 'card-item-rewards',
                params: { id: card.id },
              },
            }"
          />

          <BtnUrlAction
            id="btn-refund"
            v-if="
              card.program.ibanEnabled == true ||
              card.program.cardEnabled == false
            "
            :disabled="isMaintenanceMode || card.amount == 0"
            class="columns small-12 medium-6"
            :infos="{
              icon: 'sofont-refund',
              text: $t('card.actions.refund'),
              notif: card.notif,
              route: {
                name: 'card-item-refund-iban',
                params: { id: card.id },
              },
            }"
          />
          <BtnUrlAction
            id="btn-xpay"
            class="columns small-12 medium-6"
            v-if="cardHasXpay"
            :infos="{
              background: 'white',
              icon: 'sofont-xpay',
              text: $t('card.actions.xpay'),
              route: {
                name: 'card-item-xpay',
                params: { id: card.id },
              },
            }"
            :disabled="
              isMaintenanceMode ||
              card.amount == 0 ||
              card.program.cardEnabled == false
            "
          />

          <div
            v-if="canSimulatePayment"
            class="btn-url-action-wrapper columns small-12 medium-6"
            id="btn-simulate"
          >
            <button
              class="btn-url-action row align-middle"
              @click="showSimulatePop = true"
            >
              <RoundIcon background="white" icon="sofont-bag"/>
              <div class="columns">
                <span class="btn-url-action-text">
                  {{ $t("card.actions.simulatePayment") }}
                </span>
              </div>
            </button>
          </div>
        </div>

        <!-- Card terms -->
        <div class="text-center">
          <router-link
            class="tcos-link"
            :to="{
              name: 'card-item-tcos',
              params: { id: card.id, card: card },
            }"
          >{{ $t("cgu.card.title") }}
          </router-link>
        </div>
      </div>
    </div>
    <Popup
      class="maintenance-pop"
      v-if="showMaintenancePop"
      hasClose
      @close="showMaintenancePop = false"
    >
      <p class="popup-valid-text text-center">{{ $t("card.maintenance") }}</p>
    </Popup>
    <SimulatePop
      :show="showSimulatePop"
      @close="showSimulatePop = false"
    ></SimulatePop>
  </GetterWrapper>
</template>

<script src="./card-detail.js"></script>

<style lang="scss">
@import "~@/styles/theme";

.card-detail .view-header {
  background: #fff;
  z-index: 2;
}

.maintenance-pop {
  max-width: none;
  width: 100%;
}

#btn-donate {
  .round-icon i {
    color: $donate-round-icon-color;
  }
}

#btn-simulate {
  button {
    background: $grey; // grey
    color: #fff;
  }

  .round-icon {
    color: #fff;
  }
}
</style>
