<template>
  <div class="view-header columns small-12 large-10">

      <div  :class="'view-header-row ' + (subtitle ? 'with-subtitle' : '')" >
         <i v-if="hasBack" class="back-icon sofont sofont-arrow-back" @click="back"></i>
        <div  class="view-row " >
        <RoundIcon v-if="icon" class="detail-icon" :background="backgroundIcon" :icon="icon" />
        <h1  class="view-title" :class="subtitle ? 'with-subtitle' : ''" v-html="title"></h1>
        </div>
      </div>

        <!--div  v-else :class="'view-row ' + (subtitle ? 'with-subtitle' : '')">
          <i class="sofont sofont-arrow-back" @click="back"></i>
          <div :class="'view-row ' + (subtitle ? 'with-subtitle' : '')" >
             <RoundIcon v-if="icon" class="detail-icon" :background="backgroundIcon" :icon="icon" />
            <h1 class="view-title" :class="subtitle ? 'with-subtitle' : ''">{{ title }}</h1>
          </div>

        </div-->

    <h2 v-if="subtitle" class="view-subtitle" v-html="subtitle"></h2>
  </div>
</template>

<script src="./view-header.js"></script>
<style scoped lang="scss" src="./view-header.scss"></style>
