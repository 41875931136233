<template>
  <div>
    <Popup v-if="showPopup" hasClose @close="closePopup()">
      <p class="text-center">
        <b>{{ $t('forms.password.confirmPassword') }}</b>
      </p>
      <FormPasswordSecure :api="saveUserPassword" :tempForm="form" @submit="onSubmitted"/>
    </Popup>

    <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
      <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
      <p class="popup-valid-text text-center">{{ $t('forms.password.sendOk') }}</p>
    </Popup>

    <FormWrapper
      forceId="password"
      :api="null"
      :btnClass="showForm ? '' : 'is-hidden'"
      :btnDisabled="!isModified"
      :btnText="`${$t('form.save')}`"
      :validation="v$.form"
      @cancel="this.toggleForm"
      @submitted="openPopup()"
      @error="onError"
    >
      <fieldset>
        <legend>
          <div>{{ $t('forms.password.title') }}</div>
          <ActionText
            :class="showForm ? 'btn-cancel' : 'btn-edit'"
            isOnLegend
            :icon="showForm ? 'sofont-cross-close' : 'sofont-edit'"
            :text="showForm ? $t('form.cancel') : $t('form.edit')"
            @action="toggleForm()"
          />
        </legend>
        <div class="row align-center">
          <InputWrapper
            class="columns small-12 medium-7"
            :label="`${$t('form.password')}` + ' ' + `${$t('form.passwordRecommandation')}`"
            :validation-path="'form.newPassword'"
            :isInvalid="v$.form.newPassword.$invalid"
          >
            <InputPassword
              name="password"
              type="password"
              v-model:password.trim.lazy="v$.form.newPassword.$model"
              :disabled="!showForm"
              :disableStrength="true"
              required
              @focus="update()"
            ></InputPassword>
          </InputWrapper>

          <InputWrapper
            class="columns small-12 medium-7"
            :label="`${$t('form.passwordRepeat')}`"
            :isInvalid="v$.form.confirmedPassword.$invalid"
            :validation-path="'form.confirmedPassword'">
            <InputPassword
              name="passwordConfirm"
              type="password"
              v-model:password.trim.lazy="v$.form.confirmedPassword.$model"
              :disabled="!showForm"
              :disableStrength="true"
              required
            ></InputPassword>
          </InputWrapper>
        </div>
      </fieldset>
    </FormWrapper>
  </div>
</template>

<script src="./form-password.js"></script>
