export default {
  name: 'ViewSteps',
  props: {
    // allow to go back in steps with steps clickables
    canGoBack: { type: Boolean, default: false },
    activeStep: { type: Number, default: 0 },
    steps: { type: Array, default: [] }
  },
  data () {
    return {}
  },
  emits: ['clickstep'],
  computed: {
    _steps () {
      let _i = 0
      return this.steps.map(step => {
        const __step = Object.assign({}, step, { disabled: (this.activeStep) !== _i })
        _i++
        return __step
      })
    },
  },
  methods: {
    onStepClick (evt, index) {
      console.log('click ', index, this.activeStep)
      if (this.canGoBack && index !== this.activeStep && index < this.activeStep) {
        this.$emit('clickstep', index)
      }

    }
  }
}
