<template>
  <div class="signup row align-center">
    <ViewHeader
      :title="$t('signup.modification.title')"
      :subtitle="$t('signup.modification.subtitle')"
    />

    <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
      <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
      <p class="popup-valid-text text-center">{{ $t('signup.modification.sendOk') }}</p>
    </Popup>

    <div class="columns small-12 medium-10 large-8">
      <FormWrapper
        :api="putFormSignup.bind(this, this.formSignup)"
        :btn-text="`${$t('form.validate')}`"
        :validation="v$.formSignup"
        @submitted="onSubmitted"
        @error="onError"
      >
        <fieldset>
          <div class="row align-center">
            <p
              class="columns small-12 medium-7 text-center"
            >{{$t('form.oldEmail') + ' : ' + oldMail }}</p>
            <InputWrapper
              class="columns small-12 medium-7"
              :label="`${$t('form.newEmail')}` + ' ' + `${$t('signup.emailIndication')}`"
              :validation-path="'form.email'"
            >
              <input name="email" type="email" v-model="v$.formSignup.email.$model" disabled />
            </InputWrapper>
          </div>
        </fieldset>
      </FormWrapper>
    </div>
  </div>
</template>

<script src="./modification.js"></script>
