<template>
  <div class="login row align-center">
    <div class="columns small-12 large-10">
      <div class="image"></div>
      <h2 class="text-center">{{ $t('login.title') }}</h2>
      <p class="text-center font-bold text-danger" v-if="$route.params.expired">{{ $t('login.expiredMessage') }}</p>
    </div>
    <div class="columns small-12 medium-10 large-8">
      <FormWrapper
        :api="postFormKeycloak.bind(this, this.form)"
        :btnDisabled="tentatives > 1 && (hasCaptcha && !verifiedCaptcha)"
        :btnText="`${$t('login.btnText')}`"
        :customErrors="errors"
        :validation="v$.form"
        @submitted="onSubmitted"
        @error="onError"
      >
        <fieldset>
          <div class="row align-center">
            <InputWrapper  :validation-path="'form.email'" class="columns small-12 medium-7" :label="`${$t('form.email')}`">
              <input name="email" type="email"  v-model.trim.lazy="v$.form.email.$model" required />
            </InputWrapper>

            <InputWrapper   :validation-path="'form.password'"  class="columns small-12 medium-7" :label="`${$t('form.password')}`">
              <InputPassword
                v-model:password.trim.lazy="v$.form.password.$model"
                :disableStrength="true"
                required
              ></InputPassword>
            </InputWrapper>

            <FormRecaptcha
              v-if="tentatives > 1"
              class="columns small-12 medium-7"
              :recaptchaKey="platform.recaptchaKey"
              @render="onRenderCaptcha"
              @verify="onVerifyCaptcha"
            ></FormRecaptcha>
          </div>
        </fieldset>
      </FormWrapper>
      <div class="text-center">
        <router-link
          class="signup-link"
          :to="{ name: 'forgotten-password', params: { /*REMOVED FOR VUE 3 email: form.email*/ } }"
        >{{ $t("login.passwordForgotten") }}</router-link>
      </div>
    </div>
  </div>
</template>

<script src="./login.js"></script>
