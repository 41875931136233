import Vue from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { LS } from "@/const"
import CodeSmsInput from '@/components/code-sms-input/code-sms-input.vue'
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import { formatSeconds } from "@/helpers/date.js"

import { getFormSignupSms, postFormSignupSms } from '@/store/api'

export default {
  name: 'FormSmsSecure',
  components: {
    CodeSmsInput,
    FormWrapper,
    InputWrapper
  },
  props: {},
  data () {
    return {
      v$: useVuelidate(),
      errors: [
        {
          response: {
            status: 406,
            data: {
              message: this.$t('errors.codeSmsInvalid'),
            }
          }
        },
        {
          response: {
            status: 409,
            data: {
              message: this.$t('errors.codeSmsInvalid'),
            }
          }
        },
      ],
      postFormSignupSms,
      codeSMSExpired: false,
      countDown: -1,
      form: {
        code: this.$route.params.code,
        codeSms: null,
      },
      invalidCodeSMS: false,
      timer: "",
    }
  },
  validations: {
    form: {
      codeSms: { required }
    },
  },
  emits: ['submitted', 'back'],
  computed: {
    formatedCountdown () {
      return formatSeconds(this.countDown)
    },
  },
  mounted: function () {
    this.onSendSms()
  },
  methods: {
    countDownTimer () {
      if (this.countDown > 0) {
        this.timer = setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    submitCode () {
      Vue.nextTick(function () {
        const btnForm = document.getElementById('save-signupcreation-btn-submit')
        btnForm.click(function (e) {
          // Prevent form submission which refreshes page
          e.preventDefault()
          return false
        })
      })
    },
    async onSendSms () {
      if (this.countDown <= 0) {
        try {
          await getFormSignupSms({ code: this.$route.params.code })
        } catch (error) {
          this.onError(error)
        } finally {
          clearTimeout(this.timer)
          this.countDown = LS.RESEND_SMS_SECS
          this.countDownTimer()
        }
      }
    },
    onBack () {
      this.$emit('back')
    },
    onSubmitted () {
      this.$emit('submitted')
    },
    onError (error) {
      console.error('[ERROR]', error)
      if (error.response.data.status === 409) {
        this.codeSMSExpired = true
      } else {
        this.invalidCodeSMS = true
      }
    }
  }
}
