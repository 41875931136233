<template>
  <div class="signup row align-center">
    <ViewHeader :title="$t('signup.linkExpired.title')" />
    <div class="columns small-12 medium-10 large-8 text-center">
      <p v-html="$t('signup.linkExpired.security') "></p>
      <p v-html="$t('signup.linkExpired.mail')"></p>
      <p>
        {{ $t('signup.linkExpired.redirect') }}
        <router-link :to="{ name: 'contact' }">{{ $t('signup.linkExpired.click') }}</router-link>.
      </p>
    </div>
  </div>
</template>

<script src="./link-expired.js"></script>
