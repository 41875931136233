import { LS } from "@/const"
import BtnUrlAction from "@/components/btn-url-action/btn-url-action.vue"
import CardItem from "@/components/card-item/card-item.vue"
import CardItemWallester from "@/components/card-item-wallester/card-item-wallester.vue"
import CardRapydShow from "@/components/card-rapyd-show/card-rapyd-show.vue"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import SimulatePop from '@/components/simulate-pay-popup/simulate-pay-popup.vue';
import RoundIcon from "@/components/round-icon/round-icon.vue"
import Popup from '@/components/popup/popup.vue'
import { formatSeconds } from "@/helpers/date.js"
import { changeNumberFormats } from "@/helpers/i18n"
import { getCard, getCardGraphics } from "@/store/api"
import store from "@/store"
import { isProduction } from '@/store/api'
import {EventBus} from '@/event-bus.js'

export default {
  name: "CardDetail",
  components: {
    BtnUrlAction,
    CardItem,
    CardItemWallester,
    GetterWrapper,
    ViewHeader,
    ViewDetails,
    CardRapydShow,
    Popup,
    SimulatePop,
    RoundIcon
  },
  props: {},
  data () {
    return {
      apiLoad: [
        {
          api: getCard,
          data: { id: this.$route.params.id }
        },
        {
          api: getCardGraphics,
          data: { id: this.$route.params.id }
        }
      ],
      card: null,
      cardShown: false,
      countDown: LS.CARD_SHOW_TIMOUT_SECS,
      store: this.$store.state,
      timer: "",
      loading: false,
      showMaintenancePop: false,
      showSimulatePop: false,
    }
  },
  computed: {
    formatedCountdown () {
      return formatSeconds(this.countDown)
    },
    cardHasXpay () {
      console.log('card has xpay ? ', this.card.program.xp)
      return this.card.program.xp
      //return true
    },
    isMaintenanceMode(){
      console.log('card is maintenance mode ? ', this.card.program.underMaintenance)
      return this.card.program.underMaintenance
    },
    canSimulatePayment(){
      console.log('can simulate payment ', process.env.VUE_APP_SIMULATE_PAYMENT)
      return process.env.VUE_APP_SIMULATE_PAYMENT;
    }
  },

  // beforeRouteLeave(to, from, next){
  //   console.log('before route leave ', to.name, !to.params.FLType,  this.card.program.type)
  //   if(to.name == 'help' && !to.params.FLType && this.card.program.type.startsWith('FL')){
  //     next({
  //       name: to.name,
  //       params: {
  //         FLType: this.card.program.type.startsWith('FL')
  //       },
  //       replace: true
  //     })
  //   } else {
  //     next();
  //   }
  // },

  methods: {
    onData (data) {
      this.card = data[0]
      store.commit("setCurrentCard", this.card)
      store.commit("setProgId", this.card.program.id)
      EventBus.emit("cardSelected", this.card)
      const numberFormat = this.$i18n.numberFormats[this.$i18n.locale]
      numberFormat.currency.currency = this.card.program.currency
      changeNumberFormats(this.$i18n.locale, numberFormat)
      this.card.url = data[2]
      if (this.card.program.monetician === "WALLESTER") {
        this.card.graphics = data[1]
      }

      // console.debug(this.$i18n.numberFormats)
      console.debug("card loaded,", data)
    },
    countDownTimer () {
      this.timer = setTimeout(() => {
        this.countDown -= 1
        if (this.countDown > 0) {
          this.countDownTimer()
        } else if (this.countDown <= 0) {
          this.hideCard()
        }
      }, 1000)
    },
    hideCard () {
      clearTimeout(this.timer)
      this.cardShown = false
      this.countDown = LS.CARD_SHOW_TIMOUT_SECS
    },
    async showCardDetails () {
      console.log(this.card.program)
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.cardShown = true
      // Désactivé temporairement
      if (this.card.program.monetician !== "IBAN") {
        this.countDownTimer()
      }
    }
  },
  mounted () {}
}
