<template>
  <MazPhoneNumberInput

    class="mobile-input"
    :disabled="disabled"
    :name="name"
    :valid-color="'#a3a3a3'"
    :no-example="true"
    :show-code-on-list="true"
    :translations="translations"
    :fetch-country="false"
    :no-use-browser-locale="true"
    v-model="mobile"
    :required="required"
    @update="updated"
  />
</template>

<script src="./mobile-input.js"></script>
<style  lang="scss" src="./mobile-input.scss"></style>
