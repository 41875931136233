import {email, required, sameAs} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import CodeSmsInput from '@/components/code-sms-input/code-sms-input.vue'
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputBirthdate from '@/components/input-birthdate/input-birthdate.vue'
import InputPassword from '@/components/input-password/input-password.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import MobileInput from '@/components/mobile-input/mobile-input.vue'
import Popup from '@/components/popup/popup.vue'
import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import parameters from '@/helpers/parameters'
import {
  goodPassword,
  validName,
  acceptTerms,
  minAgeRequired,
  validateMobileNumber,
  dateNoExist
} from '@/helpers/vuelidate'

import {putFormSignup} from '@/store/api'

export default {
  name: 'FormUser',
  components: {
    CodeSmsInput,
    FormWrapper,
    InputPassword,
    InputBirthdate,
    InputWrapper,
    MobileInput,
    Popup,
    ViewBlocs,
  },
  props: {},
  data() {
    return {
      v$: useVuelidate(),
      putFormSignup,
      countries: parameters.countries,
      form: {
        ...this.$store.state.user,
        activationMail: null,
        email: this.$store.state.user.activationMail ? this.$store.state.user.activationMail : this.$store.state.user.email,
        code: this.$route.params.code,
        password: '',
        passwordConfirm: '',
        newsletter: false,
        terms: false,
        country: this.$store.state.user.country || 'FR'
      },
      showConditions: false,
      showPolitics: false,
    }
  },
  validations() {
    return {
      form: {
        firstname: {
          required,
          validName: validName(),
          $lazy: true
        },
        lastname: {
          required,
          validName: validName(),
          $lazy: true
        },
        birthdate: {
          required,
          minAgeRequired: minAgeRequired(15),
          dateNoExist: dateNoExist(),
          $lazy: true
        },
        country: {
          required,
          $lazy: true
        },
        email: {
          required,
          email,
          $lazy: true
        },
        gender: {
          required,
          $lazy: true
        },
        mobile: {
          validateMobileNumber: validateMobileNumber(),
          required,
          $lazy: true
        },
        password: {
          required,
          goodPassword: goodPassword(),
          $lazy: true
        },
        passwordConfirm: {
          required,
          sameAsPassword: sameAs(this.form.password),
          $lazy: true
        },
        newsletter: {},
        terms: {
          acceptTerms: acceptTerms(),
          required,
          $lazy: true
        },
      },
    }
  },
  emits: ['submitted'],
  mounted() {
    console.log('this.form.user', this.form)
  },
  filters: {
    uppercase: function (value) {
      if (!value) {
        return ''
      }
      return value.toString().toUpperCase()
    }
  },
  methods: {
    forceUpper(e, obj, prop) {
      const start = e.target.selectionStart
      e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
      obj[prop] = e.target.value
      e.target.setSelectionRange(start, start)
    },
    toggleConditions() {
      this.showConditions = !this.showConditions
    },
    togglePolitics() {
      this.showPolitics = !this.showPolitics
    },
    focusMobile() {
      this.checkMobile = true
    },
    updateMobile(data) {
      console.log('update mobile ', data)
      this.form.mobile = data.mobile
    },
    onSubmitted(data) {
      console.log('submit data ', data)
      this.$emit('submitted', data)
    },
    onError(error) {
      console.error('[ERROR]', error)
      // If the form is invalid
      //if (error.response.status === 412) {
      //}
    },
    uppercase(str) {
      return str.toUpperCase();
    }
  }
}
