import {EventBus} from '@/event-bus.js'
export default {
  name: 'AppHeader',
  props: {
    forceState: {
      type: String,
      default: '',
    },
  },
  data () {
    return {}
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ""
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  created: function () { },
  mounted: function () {
    EventBus.on('cardSelected', (card) => { // here you need to use the arrow function
      this.$forceUpdate()
    })
  },
  computed: {
    logged () {
      if (this.$store.state.token) {
        return (this.$store.state.user ? this.$store.state.user.email !== undefined : false)
      }
      return false
    },
    routes () {
      return this.$store.state.platform ? (
        this.$store.state.platform.menu?.filter(menuItem => (menuItem.progId && menuItem.progId === this.$store.state.progId) || !menuItem.progId) || []
      ) : []
    },
    token () {
      return this.$store.state.token
    },
    cards () {
      return this.$store.state.cards
    }
  },
  methods: {
    isActiveRoute (routeName) {
      let isActive = false
      switch (routeName) {
      case 'home':
        if (this.$route.name === 'home' || this.$route.name.indexOf('card') !== -1 || this.forceState === 'LOGGED') isActive = true
        break
      case 'messages':
        if (this.$route.name.indexOf('messages') !== -1) isActive = true
        break
      case 'help':
        if (this.$route.name.indexOf('help') !== -1) isActive = true
        break
      case 'account':
        if (this.$route.name.indexOf('account') !== -1) isActive = true
        break
      default:
        break
      }
      return isActive
    },
    destination (route) {
      try {
        const url = new URL(route)
        return { name: 'external', params: { urlencoded: encodeURIComponent(url.href) } }
      } catch (_) {
        return route
      }
    },
    capitalize(str){
      return str.charAt(0).toUpperCase()
      + str.slice(1)
    }
  }
}
