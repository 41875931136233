<template>
  <div class="card-item">
    <div class="scene">
      <div class="card" :class="[withDetails ? 'is-flipped' : '']">
        <div class="card__face card__face--front">
          <img
            v-if="card.graphics || card.preview"
            :src="
              card
                ? card.graphics
                  ? 'data:' +
                    this.card.graphics.typeMimeBackground +
                    ';base64,' +
                    card.graphics.background
                  : 'data:image/png;base64,' + card.preview
                : ''
            "
            class="card__bg"
          />
          <div class="card__infos">
            <div class="card__logo-container">
              <img
                v-if="card.graphics"
                class="card__logo"
                :src="
                  card
                    ? 'data:' +
                      card.graphics.typeMimeProgramLogo +
                      ';base64,' +
                      card.graphics.programLogo
                    : ''
                "
              />
            </div>
            <span v-if="cardName" :class="'card__name card__name-' + card.id">{{
              cardName
            }}</span>
            <!--img
              v-if="card.graphics"
              class="card__type"
              :src="
                card
                  ? 'data:' +
                    card.graphics.typeMimeCardTypeLogo +
                    ';base64,' +
                    card.graphics.cardTypeLogo
                  : ''
              "
            /-->
          </div>
        </div>
        <div class="card__face card__face--back">
          <img
            v-if="card.masked"
            :src="card ? 'data:image/png;base64,' + card.masked : ''"
          />
          <img
              v-if="card.graphics"
              class="card__type"
              :src="
                card
                  ? 'data:' +
                    card.graphics.typeMimeCardTypeLogo +
                    ';base64,' +
                    card.graphics.cardTypeLogo
                  : ''
              "
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./card-item.js"></script>
<style scoped lang="scss" src="./card-item.scss"></style>
