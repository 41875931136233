import App from '../main';
import VueGtag from "vue-gtag";
import { createStore } from 'vuex'
import { LS } from '@/const'
import router from '@/router'
import moment from "moment-timezone"

//import { Screeb } from '../helpers/screeb.js'



export default createStore({
  state: {
    displayErrorBanner: false,
    isLoaded: false,
    isOffline: false,
    platform: null,
    refreshToken: null,
    theme: null,
    token: null,
    user: null,
    progId: null,
    withPlatormId: true,
    withToken: true,
    cards: [],
    currentCard : null,
  },
  mutations: {
    displayErrorBanner (state, displayErrorBanner) {
      state.displayErrorBanner = displayErrorBanner
    },
    isLoaded (state, isLoaded) {
      state.isLoaded = isLoaded
    },
    isOffline (state, isOffline) {
      state.isOffline = isOffline
    },
    platform (state, platform) {
      if (platform) {
        localStorage.setItem(LS.PLATFORM, JSON.stringify(platform))
        if (platform.analyticKey) {
          App.use(VueGtag, {
            config : {
              id: platform.analyticKey,
            }

            //checkDuplicatedScript: true,
            //router,
          })
        }
      } else {
        localStorage.removeItem(LS.PLATFORM)
      }
      state.platform = platform
    },
    refreshToken (state, refreshToken) {
      if (refreshToken) {
        localStorage.setItem(LS.REFRESH_TOKEN, JSON.stringify(refreshToken))
      } else {
        localStorage.removeItem(LS.REFRESH_TOKEN)
      }
      state.refreshToken = refreshToken
    },
    theme (state, theme) {
      state.theme = theme
    },
    token (state, token) {
      if (token) {
        localStorage.setItem(LS.TOKEN, JSON.stringify(token))
      } else {
        localStorage.removeItem(LS.TOKEN)
      }
      state.token = token
    },
    user (state, user) {
      //if (user) {
      if (user && typeof user.birthdate === "number") {
        user.birthdate = new Date(user.birthdate)
      }
      const lang = user?.locale ?? navigator.language

      moment.locale(lang)

      if (user && user.zoneId) {
        moment.tz.setDefault(user.zoneId)
      } else {
        moment.tz.guess()
      }

      /*}
      else {
        Screeb.show(false)
      }*/
      console.log('STORE USER ', user)
      state.user = user
    },
    withPlatormId (state, withPlatormId) {
      state.withPlatormId = withPlatormId
    },
    withToken (state, withToken) {
      state.withToken = withToken
    },
    setCards (state, array) {
      state.cards = array
      // add screeb if user is logged and one card has consumptionStarted
    /*  if (state.user && state.cards.filter(card => card.consumptionStarted).length) {
        Screeb.show()
      } */
    },
    setProgId (state, progId) {
      if (progId) {
        localStorage.setItem(LS.PROGID, progId)
      } else {
        localStorage.removeItem(LS.PROGID)
      }
      state.progId = progId
    },
    setCurrentCard(state, card){
      console.log('STORE sET CURRENT CARD', card)
      state.currentCard = card;
    }
  },
  actions: {}
})
