import {
  LS
} from "@/const"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import HighlightedBloc from '@/components/highlighted-bloc/highlighted-bloc.vue'
import ActionText from "@/components/action-text/action-text.vue"
import {
  getCard,
} from '@/store/api'

export default {
  name: "Buy",
  components: {
    GetterWrapper,
    ViewDetails,
    ViewHeader,
    HighlightedBloc,
    ActionText
  },
  props: {},
  computed: {
    platform(){
      return this.$store.state.platform
    },
    isFLType(){
      return this.card.program.type.startsWith('FL')
    },
    isNaisType(){
      return this.card.program.type.startsWith('NAIS')
    },
    isScoType(){
      return this.card.program.type.startsWith('SCO')
    },
    //card vacances
    isCVType(){
      return this.card.program.type.startsWith('CV')
    },
    isCCType(){
      return this.card.program.type.startsWith('CC')
    },
    hasXpay () {
      return this.card.program.xp
    },
    hasCSE () {
      return this.card.program.type == "CE"
    },
    background () {
      let bg = ''
      switch (this.$route.name) {
      case 'card-item-donate':
        bg = 'pink'
        break
      /*case 'card-item-online':
        bg = 'orange'
        break*/
      case 'card-item-store':
        bg = 'orange'
        break
      }
      return bg
    },
    icon () {
      let icon = ''
      switch (this.$route.name) {
      case 'card-item-donate':
        icon = 'sofont-heart'
        break
        /* case 'card-item-online':
        icon = 'sofont-computer'
        break*/
      case 'card-item-store':
        icon = 'sofont-bag'
        break
      }
      return icon
    },
    text () {
      let text = ''
      switch (this.$route.name) {
      case 'card-item-donate':
        text = this.$t('card.actions.donate')
        break
      case 'card-item-online':
        text = this.$t('card.actions.online')
        break
      case 'card-item-store':
        text = this.$t('card.actions.store')
        break
      }
      return text
    },

  },
  data () {
    return {
      apiLoad: [{
        api: getCard,
        data: {
          id: this.$route.params.id
        }
      },
      ],
      card: null,
      currentHelp: -1,
    }
  },
  created: function () {
    console.log('this online helps', this.card)
  },

  mounted: function () {
  },
  beforeUnmount: function () {},
  methods: {
    formatText(textObj){
      if (textObj.text.includes('{maxTopup}') && this.card.program){
        textObj.text = textObj.text.replace('{maxTopup}', this.$n(this.card.program.maxTopup|'0', 'currency'))
      }
      return textObj
    },
    onData (data) {
      this.card = data[0]
      console.log('on data this card', this.card)
    },

    itemCSEvisible (index) {
      if (index === 2 || index === 3) {
        return this.card && this.card.program.type === "CE"
      }
      return true

    },
    itemXpayVisible (index) {
      if (index === 1) {
        return this.card && this.card.program.xp
      }
      return true

    },
    getBlocs(){
      console.log("content ,,,,,,,,,", this.$tm('card.store.blocs'))
      return this.$tm('card.store.blocs').filter(bloc=>bloc.title !=='')
    },
    getItemsNotEmpty(items){
      return items.filter(item=>item.trim().length>0)
    },
    goToBanners () {
      this.$router.push({ name: 'card-item-donate', params: { type: 'shop' } })
    },
    goToExternalUrl(){
      //https://www.buldairshopping.com/boutiques
      window.open(`${this.card && this.card.program.externalUrl?this.card.program.externalUrl:"#"}`, '_blank')
    },
    goToBalance () {
      console.log('go to balance', this.platform)
      this.$router.push({ name: 'card-item-balance' })
    },
    openHelp (index) {
      console.log('open help', index)
      console.log('open help', this.currentHelp)
      this.currentHelp = (this.currentHelp === index) ? -1 : index
      console.log('currentHelp', this.currentHelp)
    },
    openXpay () {
      this.$router.push({
        name: 'card-item-xpay',
        params: { id: this.card.id }
      }
      )
    }
  }
}
