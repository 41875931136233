<template>
  <div class="cgu row align-center">
    <ViewHeader :hasBack="true" :title="$t('cgu.title')" :icon="'sofont-documents'"/>
    <div class="columns small-12 medium-10 large-8">
      <ViewBlocs :blocs="$tm('cgu.blocs')" section="cgu" />
    </div>
  </div>
</template>

<script src="./cgu.js"></script>
