import { required } from "@vuelidate/validators"
import { useVuelidate } from '@vuelidate/core'
import FormWrapper from "@/components/form-wrapper/form-wrapper.vue"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import InputWrapper from "@/components/input-wrapper/input-wrapper.vue"
import Popup from "@/components/popup/popup.vue"
// import Stripe from "@/components/stripe/stripe.vue"
import StripeSca from "@/components/stripe-sca/stripe-sca.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewSteps from "@/components/view-steps/view-steps.vue"
import LogoVisa from "@/components/card-logo/visa/logo-visa.vue"
import LogoMastercard from "@/components/card-logo/mastercard/logo-mastercard.vue"
import { acceptTerms, digitOnly } from "@/helpers/vuelidate"
import { getCurrencySymbol } from "@/helpers/i18n"

import { getCard, postPaymentIntent, checkTopUpAllowed } from '@/store/api'

export default {
  name: "Balance",
  components: {
    FormWrapper,
    GetterWrapper,
    InputWrapper,
    Popup,
    StripeSca,
    ViewDetails,
    ViewHeader,
    ViewSteps,
    LogoVisa,
    LogoMastercard,
  },
  props: {},
  data () {
    return {
      v$: useVuelidate(),
      apiLoad: [
        {
          api: getCard,
          data: { id: this.$route.params.id }
        }
      ],
      getCurrencySymbol,
      card: {},
      secretStripe: "",
      currentStep: 0,
      finalAmount: 0,
      form: {
        amount: 0,
        terms: false
      },
      defaultMaxComplement: 50,
      showPopupOk: false,
      isTopupAllowed: true,
    }
  },
  computed: {

    canPay () {
      if (!this.form.amount) return false
      if (!this.card.program || !this.card.program.stripeKey) return false
      if (this.currentComplement < 1) return false
      if (this.currentComplement > this.maxComplement) return false
      return true
    },
    currentBalance () {
      if (this.card.amount && this.form.amount) {
        const balance = this.card.amount - parseFloat(this.form.amount)
        if (balance > 0) {
          return balance
        }
        return 0

      }
      return 0

    },
    currentComplement () {
      if (this.card.amount && this.form.amount) {
        const complement = parseFloat(this.form.amount) - this.card.amount
        if (complement > 0) {
          return +complement.toFixed(2)
        }
        return 0

      }

      return 0

    },
    maxComplement () {
      console.log('maxComplement ', this.card.program ? this.card.program.maxTopup : this.defaultMaxComplement)
      return this.card.program ? this.card.program.maxTopup : this.defaultMaxComplement
    }
  },
  emits: ['submitted', 'error'],
  validations: {
    form: {
      amount: {
        required,
        digitOnly: digitOnly()
      },
      terms: {
        acceptTerms: acceptTerms()
      },
    }
  },
  created: function () { },
  mounted: function () { /* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },

  methods: {
    onData ([data]) {
      this.card = data

      checkTopUpAllowed({ id: this.card.id })
        .then((response) => {
          console.log('check top up success ', response)

        })
        .catch(err => {
          console.log('check top up error', err)
          this.isTopupAllowed = false
        })

    },
    openPopupOk () {
      this.showPopupOk = true
    },
    closePopupOk () {
      this.showPopupOk = false
      this.$router.push({ name: 'card-item', params: { id: this.card.id } })
    },
    async onSubmitted () {
      console.log('on submitted')
      this.currentStep = 1
      try {
        this.secretStripe = (await postPaymentIntent({ id: this.card.id, amount: this.currentComplement })).data
        console.log("secret:", this.secretStripe)
        this.$emit('submitted')
      } catch (error) {
        this.$emit('error')
      } finally {
        this.loading = false
      }
    },
    async onSubmittedStripe () {
      try {
        const res = await getCard({ id: this.$route.params.id })
        console.debug("card after topup:", res.data)
        this.finalAmount = res.data.amount
      } catch (error) {
        console.error('[ERROR]', error)
      } finally {
        this.openPopupOk()
      }
    },
    onError (error) {
      console.error('[ERROR]', error)
    },
    // fix to hide 0 value when clicking on input
    onInputFocusin (evt) {
      if (evt.target.value === 0) {
        evt.target.value = ""
      }
      // evt.target.value = this.$n(10, 'currency')
    },
    onInputFocusout (evt) {
      if (evt.target.value === "") {
        evt.target.value = 0
      }
    },
    onChangeStep (newStep) {
      console.log('received change step ', newStep)
      this.currentStep = newStep
    }
  }
}
