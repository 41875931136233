export default {
  inheritAttrs: false,
  data() {
    return {
      type: this.$attrs.type
    }
  },
  props: {
    classes: {
      type: [Object, Array, String]
    },
    disableStrength: Boolean,
    disableToggle: Boolean,
    strength: {
      type: Number,
      default: 0
    },
    /**
     * Classes to apply for the various strength levels.
     */
    strengthClasses: {
      type: Array,
      default() {
        return [
          "InputPassword--very-weak",
          "InputPassword--weak",
          "InputPassword--medium",
          "InputPassword--good",
          "InputPassword--great"
        ]
      }
    },
    /**
     * Messages for the password strength values.
     */
    strengthMessages: {
      type: Array,
      default() {
        return [
          "Very Weak Password",
          "Weak Password",
          "Medium Password",
          "Strong Password",
          "Very Strong Password"
        ]
      }
    },
    password: String,
  },
  emits: ['update:password', 'strength-updated', "updatePassword"],
  computed: {
    inputElement() {
      if (this.$refs.input) {
        return this.$refs.input
      }
      if (this.$el) {
        return this.$el.querySelector(".InputPassword__Container input")
      }
      return null
    },
    listeners() {

      return Object.assign(
        {},
        //this.$listeners,
        this.$attrs,
        {'input': this.updatePassword}
      )
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        console.log('set password ', value)
        this.$emit("update:password", value)
      }
    },
    strengthClass() {
      return (
        Array.isArray(this.strengthClasses) &&
        this.strengthClasses[this.strength]
      )
    },
    strengthMessage() {
      if (this.disableStrength) {
        return null
      }
      return (
        Array.isArray(this.strengthMessages) &&
        this.strengthMessages[this.strength]
      )
    },
    toggleMessage() {
      console.log('toggle message', this.type === "password" ? "Show Password" : "Hide Password")
      return this.type === "password" ? "Show Password" : "Hide Password"
    }
  },
  watch: {
    strength(newValue, oldValue) {
      console.log('watch strength', newValue, oldValue)
      if (newValue !== oldValue) {
        this.$emit("strength-updated", newValue)
      }
    }
  },
  mounted() {
    if (!this.$attrs.type) {
      this.togglePassword(false)
    }
  },
  methods: {
    /**
     * Get the current strength class based on the current strength score.
     *
     * @param  {Number} strength
     * @return {String}
     */
    getStrengthClass(strength) {
      if (this.strength > strength) {
        return this.strengthClasses[strength]
      }
      return ""
    },
    /**
     * Toggle the visibilty of the password.
     */
    togglePassword(focus = true) {
      this.type = this.type === "password" ? "text" : "password"
      this.inputElement.setAttribute("type", this.type)
      if (focus) {
        this.inputElement.focus()
      }
    },
    /**
     * Update the password input.
     *
     * @param  {String} password
     */
    updatePassword(event) {
      this.model = event.target.value
      this.$emit("updatePassword", {password: event.target.value})
    }
  }
}
