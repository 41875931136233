import Popup from "@/components/popup/popup.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewSteps from "@/components/view-steps/view-steps.vue"

import FormEmail from './forms/form-email.vue'
import FormPassword from './forms/form-password.vue'
import FormSmsSecure from './forms/form-sms-secure.vue'

export default {
  name: 'ForgottenPassword',
  components: {
    FormEmail,
    FormPassword,
    FormSmsSecure,
    Popup,
    ViewHeader,
    ViewDetails,
    ViewSteps
  },
  props: {},
  computed: {
    subtitles () {
      return [
        this.$t('forgottenPassword.stepEmail.subtitle'),
        this.$t('forgottenPassword.stepSms.subtitle'),
        this.$t('forgottenPassword.stepNewPassword.subtitle'),
      ]
    },
  },
  data () {
    return {
      currentStep: 0,
      email: null,
      mobile: null,
      showPopupOk: false,
      notReceived: false,
    }
  },
  methods: {
    openPopupOk () {
      this.showPopupOk = true
    },
    closePopupOk () {
      this.showPopupOk = false
      this.$router.push({ name: 'login' })
    },
    onSubmittedEmail (data) {
      this.currentStep++
      this.email = data.email
      this.mobile = data.mobile
    },
    onSubmittedSms () {
      this.currentStep++
    },
    onSubmittedPassword () {
      this.openPopupOk()
    },
    onNotReceived () {
      this.openNotReceivedPopup()
    },
    openNotReceivedPopup () {
      this.notReceived = true
    },
    closePopupNotReceived () {
      this.notReceived = false
    },
  }
}
