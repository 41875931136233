export default {
  name: 'Popup',
  components: {},
  props: {
    hasClose: {
      type: Boolean,
      default: false
    },
    hasFooter: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {}
  },
  emits: ['close'],
  computed: {},
  mounted: function () { },
  methods: {}
}
