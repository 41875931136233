<template>
  <div class="getter-wrapper">
    <ListErrors v-if="loadError" :errors="loadError" />
    <Spinner v-else-if="loadLoading" />
    <slot v-else />
  </div>
</template>

<script src="./getter-wrapper.js"></script>
<style scoped lang="scss" src="./getter-wrapper.scss"></style>
