<template>
  <div class="card__wallester_content ">
    <img :src="bgUrl" class="card__bg" />
    <div class="card__infos">
      <div class="card__logo-container">
        <img class="logo" :src="programLogoUrl" />
      </div>
      <div class="card__number card-row">
        <span v-for="(n, index) in cardNumber" :key="'num-' + index">{{
          n
        }}</span>
      </div>

      <div v-if="type === 'back'" class="card__other card-row">
        <div>
          <span>{{ $t("card.expire") }}</span>
          <span>{{ displayedCard.expiration }}</span>
        </div>
        <div>
          <span>{{ $t("card.cvv") }}</span>
          <span>{{ displayedCard.cvv }}</span>
        </div>
      </div>
      <span v-if="cardName" :class="'card__name card__name-' + card.id">{{
        cardName
      }}</span>
      <img v-if="card.graphics" class="card__type" :src="cardTypeLogoUrl" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CardContentWallester",
  props: ["card", "type", "displayedCard"],
  computed: {
    bgUrl () {
      return this.card
        ? "data:" +
            this.card.graphics.typeMimeBackground +
            ";base64," +
            this.card.graphics.background
        : ""
    },
    programLogoUrl () {
      return (
        "data:" +
        this.card.graphics.typeMimeProgramLogo +
        ";base64," +
        this.card.graphics.programLogo
      )
    },
    cardTypeLogoUrl () {
      return (
        "data:" +
        this.card.graphics.typeMimeCardTypeLogo +
        ";base64," +
        this.card.graphics.cardTypeLogo
      )
    },
    cardName () {
      return (
        this.$store.state.user.firstname + " " + this.$store.state.user.lastname
      )
    },
    cardNumber () {
      const num = this.displayedCard.number
      return [
        num.substring(0, 4),
        num.substring(4, 8),
        num.substring(8, 12),
        num.substring(12, 16)
      ]
    }
  }
}
</script>

<style lang="scss">
@import "~@/styles/theme";

.card__wallester_content {
  .card__infos {
    justify-content: space-between;
  }
}
</style>
