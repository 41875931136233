<template>
  <div class="forgotten-password row align-center">
    <ViewHeader
      :hasBack="true"
      :title="`${$t('forgottenPassword.title')}`"
    />
    <div class="columns small-12 medium-10 large-8">

      <ViewSteps
        :steps="[
          { label: $t('forgottenPassword.stepEmail.step') },
          { label: $t('forgottenPassword.stepSms.step') },
          { label: $t('forgottenPassword.stepNewPassword.step') }
        ]"
        :activeStep="currentStep"
      ></ViewSteps>

      <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
        <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
        <p class="popup-valid-text text-center">{{ $t('forgottenPassword.sendOk') }}</p>
      </Popup>
      <Popup v-if="notReceived" hasClose @close="closePopupNotReceived()">
        <p class="text-left">
        <span  v-html="$t('forgottenPassword.stepSms.notReceivedPop')"></span>
        <router-link to="contact">{{$t('forgottenPassword.stepSms.notReceivedSupport')}}</router-link>
        </p>
      </Popup>
      <h2 class="text-center" v-html="subtitles[currentStep]"></h2>
      <p
        v-if="currentStep === 1"
        class="text-center"
        v-html="$t('forgottenPassword.stepSms.indic', { value: email })"
      ></p>
      <FormEmail v-if="currentStep === 0" @submitted="onSubmittedEmail" />
      <FormSmsSecure v-if="currentStep === 1" :email="email" @submitted="onSubmittedSms" @notreceived="onNotReceived"/>
      <FormPassword v-if="currentStep === 2" :email="email" @submitted="onSubmittedPassword" />
    </div>
  </div>
</template>

<script src="./forgotten-password.js"></script>
