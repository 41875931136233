const pjson = require('../package.json')

export const LS = Object.freeze({
  TOKEN: `${pjson.name}ApiToken`,
  PROGID: `${pjson.name}ApiProgid`,
  REFRESH_TOKEN: `${pjson.name}ApiRefreshToken`,
  PLATFORM: `${pjson.name}ApiPlatform`,
  CARD_SHOW_TIMOUT_SECS: 120,
  CARD_TRANSACTION_SIZE: 20,
  RESEND_SMS_SECS: 120,
})
