import ViewHeader from "@/components/view-header/view-header.vue"

export default {
  name: 'Message',
  components: { ViewHeader },
  props: {},
  computed: {},
  data () {
    return {
      currentMessages: 3,
      incrementMessages: 10,
      messages: [
        {
          isRead: false,
          sender: {
            name: "Cédric Lavédrine",
            image: "https://via.placeholder.com/256x256"
          },
          date: "09h24",
          object: "Objet du message",
          content: "Phasellus aliquet urna lectus, tincidunt aliquam orci malesuada in ut rutrum orci vitae augue cursus, id elementum nulla consectetur.",
        },
        {
          isRead: true,
          sender: {
            name: "Marc Aurèle",
            image: "https://via.placeholder.com/256x256"
          },
          date: "26/06/20",
          object: "Présentation des actions 2020",
          content: "Phasellus aliquet urna lectus, tincidunt aliquam orci malesuada in ut rutrum orci vitae augue cursus, id elementum nulla consectetur.",
        },
        {
          isRead: true,
          sender: {
            name: "Marc Aurèle",
            image: "https://via.placeholder.com/256x256"
          },
          date: "25/06/20",
          object: "Présentation des actions passées 2019",
          content: "Phasellus aliquet urna lectus, tincidunt aliquam orci malesuada in ut rutrum orci vitae augue cursus, id elementum nulla consectetur.",
        },
        {
          isRead: true,
          sender: {
            name: "Cédric Lavédrine",
            image: "https://via.placeholder.com/256x256"
          },
          date: "24/06/20",
          object: "Objet du message",
          content: "Phasellus aliquet urna lectus, tincidunt aliquam orci malesuada in ut rutrum orci vitae augue cursus, id elementum nulla consectetur",
        },
        {
          isRead: true,
          sender: {
            name: "Marc Aurèle",
            image: "https://via.placeholder.com/256x256"
          },
          date: "20/06/20",
          object: "Présentation des actions 2020",
          content: "Phasellus aliquet urna lectus, tincidunt aliquam orci malesuada in ut rutrum orci vitae augue cursus, id elementum nulla consectetur.",
        },
        {
          isRead: true,
          sender: {
            name: "Marc Aurèle",
            image: "https://via.placeholder.com/256x256"
          },
          date: "17/06/20",
          object: "Présentation des actions 2020",
          content: "Phasellus aliquet urna lectus, tincidunt aliquam orci malesuada in ut rutrum orci vitae augue cursus, id elementum nulla consectetur.",
        },
        {
          isRead: true,
          sender: {
            name: "Cédric Lavédrine",
            image: "https://via.placeholder.com/256x256"
          },
          date: "15/06/20",
          object: "Objet du message",
          content: "Phasellus aliquet urna lectus, tincidunt aliquam orci malesuada in ut rutrum orci vitae augue cursus, id elementum nulla consectetur.",
        },
        {
          isRead: true,
          sender: {
            name: "Marc Aurèle",
            image: "https://via.placeholder.com/256x256"
          },
          date: "15/06/20",
          object: "Bilan 2020",
          content: "Phasellus aliquet urna lectus, tincidunt aliquam orci malesuada in ut rutrum orci vitae augue cursus, id elementum nulla consectetur.",
        },
      ]
    }
  },
  created: function () { },
  mounted: function () { /* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {
    showMoreMessages () {
      this.currentMessages += this.incrementMessages
    }
  }
}
