import store from "@/store"

export default {
  name: 'ErrorBanner',
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  mounted: function () { },
  methods: {
    close () {
      store.commit('displayErrorBanner', false)
    }
  }
}
