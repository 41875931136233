/**
 * custom jwt decoder inspired by vue-jwt-decode
 * just changed window.atob with buffer method to decode base64
 */
window.Buffer = window.Buffer || require("buffer").Buffer;
export default {
  install (app) {
    app.config.globalProperties.$jwtDec = this
    Vue.jwtDec = this
  },
  decode: function (jwt) {
    if (typeof jwt !== 'string' & !jwt instanceof String) return null

    const splitted = jwt.split('.')
    if (splitted.length < 2) return null
    const obj1 = JSON.parse(Buffer.from(splitted[0], "base64").toString())
    const obj2 = JSON.parse(Buffer.from(splitted[1], "base64").toString())

    return Object.assign({}, obj1, obj2)
  }
}
