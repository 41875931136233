<template>
  <div class="cgu row align-center">
    <ViewHeader :hasBack="true" :title="$t('tcos.title')" :icon="'sofont-documents'"/>
    <div class="columns small-12 medium-10 large-8">
      <ViewBlocs :blocs="tos" section="cgu" />
      <!--div class="view-blocs" section="cgu" v-if="activeCardId">
        <div v-for="(bloc, index) in $t('tos.'+activeCardId)" :key="'view-bloc-'+index" class="view-bloc">
          <h2 class="title">{{bloc.title}}</h2>
          <p  v-html="$t('tos.' +activeCardId + '.'+index+'.text',  {
              entity : $t('app.name'),
              entityType : 'forme juridique',
              register :'registre du commerce et des sociétés',
              city: 'Nantes',
              siren : '877 691 402',
              assetAmount : '11 770',
              currency : 'Euros',
              address : '5 place René Bouhier 44100 Nantes'
              })">
            </p>
        </div>
      </div-->

    </div>
  </div>
</template>

<script src="./tcos.js"></script>
