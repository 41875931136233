
import {
  required,
  email
} from '@vuelidate/validators'

import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputPassword from '@/components/input-password/input-password.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import FormRecaptcha from '@/components/form-recaptcha/form-recaptcha.vue'
import { useVuelidate } from '@vuelidate/core'
import router from '@/router'
import store from '@/store'
import {
  clearStoreToken,
  clearStoreUser,
  getUserApi,
  postFormKeycloak,
  setStoreToken
} from '@/store/api'
import { loadLanguageAsync } from '@/helpers/i18n'

export default {
  name: 'Login',
  components: {
    FormWrapper,
    InputPassword,
    InputWrapper,
    FormRecaptcha
  },
  props: {},
  data () {
    return {
      v$: useVuelidate(),
      getUserApi,
      postFormKeycloak,
      form: {
        email: '',
        password: '',
      },
      hasCaptcha: false,
      platform: this.$store.state.platform,
      tentatives: 0,
      verifiedCaptcha: false
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  computed: {
    errors () {
      return [{
        response: {
          status: 400,
          data: {
            message: this.$t('errors.loginBlock'),
          }
        }
      },
      {
        response: {
          status: 401,
          data: {
            message: this.$t('errors.loginError'),
          }
        }
      },
      ]
    }
  },
  watch: {
    'v$': {
      handler(value){
        console.log('is password valid ', value.form.password.$invalid)
        console.log('errors ', value.$errors)
      }
    }
  },
  created: function () {
    // Free localStorage on page load
    clearStoreToken()
  },
  mounted: function () {
    console.log('v$', this.v$)
    /* DOM accessible via this.$el */ },
  beforeUnmount: function () {
    /* clean all non-Vue listeners/objects */ },
  methods: {
    async onSubmitted (data) {
      console.log('submit data ', data)
      // Store the received token
      await setStoreToken(data.access_token, data.refresh_token)

      // Get user infos
      try {
        const res = await this.getUserApi()
        console.log('store user ', res.data)
        store.commit('user', res.data)
        console.debug('user logged', res.data)
        await loadLanguageAsync(res.data.locale)

      } catch (error) {
        console.error('[ERROR]', error)
      } finally {
        router.push({
          name: 'home'
        })
      }
    },
    onError () {

      this.tentatives++
      console.log('more tentative',this.tentatives)
      clearStoreToken()
      clearStoreUser()
    },
    onRenderCaptcha (id) {
      if (id) this.hasCaptcha = true
    },
    onVerifyCaptcha (response) {
      if (response) this.verifiedCaptcha = true
    },
  }
}
