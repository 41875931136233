import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
// import {postFundStripe} from "@/store/api";
// import InputWrapper from "@/components/input-wrapper/input-wrapper.vue"
import Spinner from '@/components/spinner/spinner.vue'
import { postFundStripe } from '@/store/api'

export default {
  name: 'Stripe-sca',
  components: { FormWrapper, Spinner },
  props: {
    amount: {
      type: String,
      default: "",
      required: true
    },
    cardID: {
      type: Number,
      default: null,
      required: true
    },
    cardSo: {
      type: Object,
      required: true,
      defaut: () => {
      }
    },
    stripeAPIToken: {
      type: String,
      default: "",
      required: true
    },
    secretPaymentIntent: {
      type: String,
      default: "",
      required: true
    }
  },
  data () {
    return {
      elements: "",
      style: "",
      card: "",
      cardNumber: "",
      cardExpiry: "",
      cardCvc: "",
      elementClasses: {
        complete: 'valid',
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      },
      stripe: "",
      displayError: "",
      numberError: "",
      expiryError: "",
      cvcError: "",
      numberValid: false,
      expiryValid: false,
      cvcValid: false,
      store: this.$store.state,
      loading: true,
      viewCardElt: false
    }
  },
  emits: ['submitted', 'error'],
  computed: {
    validation () {
      return this.numberValid && this.expiryValid && this.cvcValid && this.displayError.length === 0
    }
  },
  mounted () {
    this.includeStripeSca(
      "js.stripe.com/v3/",
      function () {
        this.configureStripeSca()
      }.bind(this)
    )
  },
  methods: {
    /*
      Includes Stripe.js dynamically
    */
    includeStripeSca (URL, callback) {
      const documentTag = document
      const tag = "script"
      const object = documentTag.createElement(tag)
      const scriptTag = documentTag.getElementsByTagName(tag)[0]
      object.src = "//" + URL
      if (callback) {
        object.addEventListener(
          "load",
          function (e) {
            callback(null, e)
          },
          false
        )
      }
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },

    /*
      Configures Stripe by setting up the elements and
      creating the card element.
    */
    configureStripeSca () {
      this.stripe = window.Stripe(this.stripeAPIToken)
      this.elements = this.stripe.elements()

      this.style = {
        base: {
          iconColor: '#666ee8',
          color: '#31325f',
          fontWeight: 400,
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '15px',

        }
      }

      this.cardNumber = this.elements.create("cardNumber", { style: this.style, classes: this.elementClasses })
      this.cardExpiry = this.elements.create("cardExpiry", { style: this.style, classes: this.elementClasses })
      this.cardCvc = this.elements.create("cardCvc", { style: this.style, classes: this.elementClasses })

      this.cardNumber.mount('#card-number')
      this.cardExpiry.mount('#card-expiry')
      this.cardCvc.mount('#card-cvc')

      this.cardNumber.on('change', ({ error }) => {
        if (error) {
          this.numberError = error.message
          this.numberValid = false
        } else {
          this.numberError = ""
          this.numberValid = true
        }
      })
      this.cardExpiry.on('change', ({ error }) => {
        if (error) {
          this.expiryError = error.message
          this.expiryValid = false
        } else {
          this.expiryError = ""
          this.expiryValid = true
        }
      })
      this.cardCvc.on('change', ({ error }) => {
        if (error) {
          this.cvcError = error.message
          this.cvcValid = false
        } else {
          this.cvcError = ""
          this.cvcValid = true
        }
      })

      this.loading = false
      this.viewCardElt = true

    },
    async onSubmitted () {
      this.loading = true
      this.viewCardElt = false

      const resultStripe = await this.stripe.confirmCardPayment(this.secretPaymentIntent, {
        payment_method: {
          card: this.cardNumber,
          billing_details: {
            name: this.store.user.firstname + ' ' + this.store.user.lastname,
            address: {
              city: this.store.user.city,
              country: this.store.user.country,
              line1: this.store.user.address,
              postal_code: this.store.user.zipCode,
            }
          }
        }
      })

      if (resultStripe.error) {
        this.viewCardElt = true
        this.displayError = resultStripe.error.message
        this.loading = false
        // this.validation = false
        // Show error to your customer (e.g., insufficient funds)
        console.log(resultStripe.error.message)

      } else {
        // The payment has been processed!
        if (resultStripe.paymentIntent.status === 'succeeded') {
          this.loading = true
          // this.validation = false
          try {
            const maxLoop = 50
            const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
            for (let i = 0; i < maxLoop; i++) {
              await sleep(300)
              const result = await postFundStripe({
                id: this.cardID,
                pi: resultStripe.paymentIntent.id,
              })
              if (result.data === true) break
            }
            this.$emit('submitted')
          } catch (error) {
            console.log("error", error)
            this.loading = false
            this.$emit('error')
          } finally {
            this.loading = false
          }
        }
      }
    },
    onError (error) {
      console.error('[ERROR]', error)
    },
  }
}
