import { required, email } from '@vuelidate/validators'
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import Popup from '@/components/popup/popup.vue'
import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import { validName } from '@/helpers/vuelidate'
import { useVuelidate } from '@vuelidate/core'
import { sendFormContact } from '@/store/api'

export default {
  name: 'Contact',
  components: {
    FormWrapper,
    InputWrapper,
    Popup,
    ViewBlocs,
    ViewHeader,
    ViewDetails
  },
  props: {},
  computed: {},
  data () {
    return {
      v$: useVuelidate(),
      sendFormContact,
      form: {
        firstname: this.$store.state.user?.firstname,
        lastname: this.$store.state.user?.lastname,
        email: this.$store.state.user?.email,
        subject: '',
        message: ''
      },
      showPolitics: false,
      showPopupOk: false,
      subjects: Array.isArray(this.$tm('contact.form.subjects')) ? this.$tm('contact.form.subjects') : [],
    }
  },
  validations: {
    form: {
      firstname: {
        required,
        validName: validName(),
      },
      lastname: {
        required,
        validName: validName(),
      },
      email: { required, email },
      subject: { required },
      message: { required }
    },
  },
  created: function () { },
  mounted: function () { /* DOM accessible via this.$el */ },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {
    forceUpper (e, obj, prop) {
      const start = e.target.selectionStart
      e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
      this.$set(obj, prop, e.target.value)
      e.target.setSelectionRange(start, start)
    },
    togglePolitics () {
      this.showPolitics = !this.showPolitics
    },
    openPopupOk () {
      this.showPopupOk = true
    },
    closePopupOk () {
      this.showPopupOk = false
      this.form = {
        firstname: this.$store.state.user?.firstname,
        lastname: this.$store.state.user?.lastname,
        email: this.$store.state.user?.email,
        subject: '',
        message: ''
      }
      this.v$.$reset()
    },
    async onSubmitted () {
      this.openPopupOk()
    },
    onError (error) {
      console.error('[ERROR]', error)
    },
  }
}
