<template>
  <div class="signup row align-center">
    <ViewHeader
      :title="$t('signup.title', { value: store.user ? store.user.firstname : '' })"
    />

    <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
      <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
      <p class="popup-valid-text text-center">{{ $t('signup.sendOk') }}</p>
    </Popup>

    <div class="columns small-12 medium-10 large-8">

      <ViewSteps
        :steps="[{ label: $t('signup.step.signupStep1') }, { label: $t('signup.step.signupStep2') }]"
        :activeStep="currentStep"
      ></ViewSteps>
       <h2 class="subtitle text-center" v-html="currentStep === 0 ? $t('signup.description') : $t('signup.codeDescription', { value: formUser.mobile })">
      </h2>
      <FormUser v-if="currentStep === 0" @submitted="onSubmittedSignup" />
      <FormSmsSecure v-if="currentStep === 1" @back="onBackSms" @submitted="onSubmittedSms" />
    </div>
  </div>
</template>

<script src="./creation.js"></script>
