import Popup from '@/components/popup/popup.vue'
import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewSteps from "@/components/view-steps/view-steps.vue"

import store from '@/store'
import { getUserApi, postFormKeycloak, setStoreToken } from '@/store/api'
import router from "@/router"

import FormSmsSecure from './forms/form-sms-secure.vue'
import FormUser from './forms/form-user.vue'

export default {
  name: 'SignUpCreation',
  components: {
    FormSmsSecure,
    FormUser,
    Popup,
    ViewBlocs,
    ViewHeader,
    ViewSteps
  },
  props: {},
  data () {
    return {
      currentStep: 0,
      formUser: {},
      showPopupOk: false,
      store: this.$store.state,
    }
  },
  methods: {
    openPopupOk () {
      this.showPopupOk = true
    },
    async closePopupOk () {
      router.push({ name: 'login' })
      // FIXME: voir plus tards pour permettre l'auth automatique
      //  Login
      // try {
      //   const res = await postFormKeycloak(this.formUser)
      //   // Store the received token
      //   setStoreToken(res.data.access_token, res.data.refresh_token)
      // } catch (error) {
      //   console.error('[ERROR]', error)
      // }
      //
      // // Get user infos
      // try {
      //   const res = await getUserApi()
      //   store.commit('user', res.data)
      // } catch (error) {
      //   console.error('[ERROR]', error)
      // } finally {
      //   this.showPopupOk = false
      //   router.push({ name: 'home' })
      // }
    },
    onSubmittedSignup (data) {
      store.commit('user', data)
      this.formUser = data
      this.currentStep++
    },
    onBackSms () {
      this.currentStep--
    },
    onSubmittedSms () {
      this.openPopupOk()
    },
  }
}
