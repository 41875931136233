<template>
  <!-- Request cards data -->
  <GetterWrapper id="balance" :api-load="apiLoad" @data="onData">
    <div class="balance row align-center">
      <ViewHeader
        :hasBack="true"
        urlBack="card-item"
        :title="$t('card.actions.balance')"
        icon="sofont-add-card"
      />
      <div class="columns small-12 medium-10 large-8">
        <ViewDetails
          :subtitle="card.program ? $t('card.card',  {type:$t('card.type-'+card.program.type),value : card.program.cardName}): ''"
          :content="$t('card.amount') + '&nbsp;<span>' + $n(card.amount, 'currency') + '</span>'"
        />

        <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
          <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
          <p class="popup-valid-text text-center" v-html="$t('card.balance.sendOk')"></p>
          <p v-html="$t('card.balance.sendDetails', { value: $n(finalAmount, 'currency') })"></p>
        </Popup>

        <!-- STEPS INDICATOR -->
        <ViewSteps
          :steps="[{ label: $t('card.balance.step.balanceStep1') }, { label: $t('card.balance.step.balanceStep2') }]"
          :activeStep="currentStep"
          @clickstep="onChangeStep"
          :canGoBack="true"
        ></ViewSteps>

        <!-- FIRST STEP -->
        <FormWrapper
          v-if="currentStep === 0"
          :api="null"
          :btn-text="`${$t('form.validate')}`"
          :btnDisabled="v$.form.$invalid || !canPay"
          :validation="v$.form"
          @submitted="onSubmitted"
          @error="onError"
        >
          <fieldset>
            <div class="row align-center">
              <h2 v-if="isTopupAllowed" class="columns small-12 medium-7 balance-title">{{ $t('card.credit') }}</h2>
              <p v-else class="columns small-12 text-center balance-topup-alert"><strong v-html="$t('card.balance.topupError')"></strong></p>
              <InputWrapper class="columns small-12 medium-7"   :validation-path="'form.amount'">
                <div class="balance-currency balance-amount" v-if="getCurrencySymbol() !== '€'">{{ getCurrencySymbol() }}</div>
                <input
                  class="balance-input balance-amount"
                  type="number"
                  @focusin="onInputFocusin($event)"
                  @focusout="onInputFocusout($event)"
                  step="0.01"
                  min="0"
                  v-model="v$.form.amount.$model"
                  :disabled="!isTopupAllowed"
                />
                <div class="balance-euros balance-amount" v-if="getCurrencySymbol() === '€'">{{ getCurrencySymbol() }}</div>
              </InputWrapper>
              <div class="columns small-12 medium-7">
                <p class="balance-text" :class="{ 'disabled' : currentComplement === 0 }">
                  <span>{{ $t('card.balance.complement') }} :</span>
                  <span
                    class="balance-amount"
                    :class="{ 'alert' : currentComplement > maxComplement }"
                  >{{ $n(currentComplement, 'currency') }}</span>
                </p>
                <p
                  v-if="currentComplement > maxComplement"
                  class="balance-text alert"
                >{{ $t('card.balance.maxComplement',{ value: $n(maxComplement, 'currency') }) }}</p>
                <p
                  v-else-if="currentComplement > 0 && currentComplement < 1"
                  class="balance-text alert"
                >{{ $t('card.balance.minComplement',{ value: $n(1, 'currency') }) }}</p>
                <p class="balance-text" :class="{ 'disabled' : currentBalance === 0 }">
                  <span>{{ $t('card.balance.currentBalance') }} :</span>
                  <span class="balance-amount">{{ $n(currentBalance, 'currency') }}</span>
                </p>
                <p class="balance-text">{{ $t('card.balance.indic') }}</p>
              </div>
              <InputWrapper class="columns small-12 medium-7 input-checkbox"   :validation-path="'form.terms'">
                <input
                  id="balance-check-input"
                  name="newsletter"
                  type="checkbox"
                  v-model="v$.form.terms.$model"
                  :disabled="!canPay"
                />
                <label
                  for="balance-check-input"
                  class="balance-check"
                  :class="{ 'disabled' : !canPay }"
                >{{ $t('card.balance.check') }}</label>
              </InputWrapper>
            </div>
          </fieldset>
        </FormWrapper>

        <!-- SECOND STEP -->
        <div v-else class="columns">
          <div class="row align-center">
            <div class="columns small-12 medium-7">
              <div class="row flex-dir-row align-middle align-center">
                <LogoVisa class="column small-2"/>
                <LogoMastercard class="column small-2"/>
              </div>
              <h2
                class="balance-amount-title"
              >{{ $t('card.balance.amountTitle') + ' : ' + $n(currentComplement, 'currency') }}</h2>
            </div>
          </div>
          <div class="row align-center">
            <StripeSca
              :amount="currentComplement"
              :cardID="card.id"
              :cardSo="card"
              :secretPaymentIntent="secretStripe"
              :stripeAPIToken="card.program.stripeKey"
              @submitted="onSubmittedStripe"
              @error="onError"
            />
          </div>
        </div>
      </div>
    </div>
  </GetterWrapper>
</template>

<script src="./balance.js"></script>
